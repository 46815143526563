import moment from "moment";
import _ from "lodash";

export function checkIfTimeRangeOverlaps(start1, end1, start2, end2) {
    if (!_.isFinite(start1) || !_.isFinite(end1) || !_.isFinite(start2) || !_.isFinite(end2)) {
        throw new Error("checkIfTimeRangeOverlap ArgumentException expected timestamps")
    }
    return Math.max(start1, start2) < Math.min(end1, end2);
}

export function getTimeAsTimestamp(timeString) {
    let time = !!timeString ? moment(timeString, "HH:mm") : null;
    return time ? +moment(0).hours(time.hours()).minutes(time.minutes()) : null;
}

export function isTimestampInRange(startTimestamp, endTimestamp, timestamp, {inclusivity} = {inclusivity: "[]"}) {
    const start = moment(+startTimestamp).startOf("day");
    const end = moment(+endTimestamp).startOf("day");
    const time = moment(+timestamp).startOf("day");
    return time.isBetween(start, end, null, inclusivity);
}

export function timeFormatter(value) {
    let s = Math.floor(value / 1000) % 60;
    let min = Math.floor(value / (1000 * 60)) % 60;
    return `${min} min ${s} s`
}

export function getMinutesOfDay(timestamp) {
    const m = moment(timestamp);
    return m.minutes() + m.hours() * 60;
}
