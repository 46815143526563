import {cachedTablesParams, db} from "../database/lokiDB";

/**
 * Dodaje listę do kolekcji
 * @param list              lista obiektów
 * @param collection        nazwa kolekcji
 * @param idName            nazwa klucza, który identyfikuje obiekty, np. AnmID
 * @param additionalKey     dodatkowy klucz główny, np. AggTi
 */
export const insertInto = (list, collection, idName, additionalKey = undefined) => {
    console.log("INSERT", list, collection, idName, additionalKey);
    let coll = db.getCollection(collection);
    if (!coll) throw new Error("Brak kolekcji: " + collection);
    let objects = coll.find();
    let tmp = {};
    for (let item of objects) {
        let key = item[idName];
        if (additionalKey) key += item[additionalKey];
        tmp[key] = item;
    }
    let toInsert = [];
    for (let item of list) {
        let key = item[idName];
        if (additionalKey) key += item[additionalKey];
        let data = tmp[key];
        if (data) {
            let tmp2 = {
                $loki: data.$loki,
                ...item
            };
            coll.update(tmp2);
        } else {
            toInsert.push(item);
        }
    }
    if (toInsert.length > 0) {
        coll.insert(toInsert);
    }
};

/**
 * Funkcja pobierająca czas ostatniej edycji
 * @param collection            kolekcja
 * @param addtionalKey          dodatkowy klucz, np. FarmID dla kolekcji animals
 * @param additinalKeyValue     wartosc dodatkowego klucza
 * @param mainKeyName           nazwa głównego klucza, np. DtaModTime
 */
export const getModificationTime = (collection, addtionalKey = undefined, additinalKeyValue = undefined, mainKeyName = "DtaModTime") => {
    let findObject = {
        table: collection
    };
    if (addtionalKey) {
        findObject[addtionalKey] = additinalKeyValue;
    }
    return cachedTablesParams.findOne(findObject) || {[mainKeyName]: "0"};
};

/**
 * Funckja ustawia czas ostatniej edycji
 * @param collection            kolekcja
 * @param modificationTime      czas ostatniej edycji
 * @param addtionalKey          dodatkowy klucz, np. FarmID dla kolekcji animals
 * @param additinalKeyValue     wartosc dodatkowego klucza
 * @param mainKeyName           nazwa głównego klucza, np. DtaModTime
 */
export const setModificationTime = (collection, modificationTime, addtionalKey = undefined, additinalKeyValue = undefined, mainKeyName = "DtaModTime") => {
    let cached = getModificationTime(collection, addtionalKey, additinalKeyValue, mainKeyName);
    if (cached[mainKeyName] === "0") {
        cached[mainKeyName] = modificationTime;
        cached.table = collection;
        if (addtionalKey) {
            cached[addtionalKey] = additinalKeyValue;
        }
        if (cached.$loki) {
            cachedTablesParams.update(cached);
        } else {
            cachedTablesParams.insert(cached);
        }
    } else {
        cached[mainKeyName] = modificationTime;
        cachedTablesParams.update(cached);
    }
};
