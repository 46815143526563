import animalsDB from "../database/animalsDB";
import moment from "moment";
import {differenceBy, findIndex, isEmpty, isEqual, isNil, memoize, values, findLastIndex} from "lodash";
import React from "react";
import SelectEditor from "../components/basics/table-input/editors/SelectEditor";
import store from "../store/store";
import Select from "../components/basics/select/Select";
import InputFilter from "../components/basics/table-input/filters/InputFilter";
import formDataDB from "../database/formDataDB";
import {change, initialize} from "redux-form";
import * as AnimalTypes from "validators-schema/Api/constants/animalTypes";
import * as EventTypes from "validators-schema/Api/constants/eventTypes";
import InputEditor from "../components/basics/table-input/editors/InputEditor";
import technologyGroupsDB from "../database/technologyGroupsDB";
import i18n from "../i18n";
import {getPigBalance} from "./EventUtils";

export const ROW_HEIGHT = 40;

/*******************************
 GENERAL
 *******************************/

export function isEmptyRow(row, ignoreFields = []) {
    if (isEmpty(row)) return true;
    for (let key in row) {
        if (!ignoreFields.includes(key) && row[key] !== null && row[key] !== "") return false;
    }
    return true;
}

export function calculateGridRowsByHeight() {
    try {
        let grid = document.getElementsByClassName("fetura-table-input")[0];
        let header = document.getElementsByClassName("rdg-header-row")[0];
        let filter = document.getElementsByClassName("rdg-filter-row")[0];
        return Math.floor((grid.clientHeight - header.clientHeight - filter.clientHeight) / ROW_HEIGHT);
    } catch (e) {
        console.error(e);
        return 10;
    }
}

export function initializeValues(formName) {
    let savedData = formDataDB.getSavedData(formName);
    if (savedData) {
        store.dispatch(change(formName, "data", savedData.data.data));
    } else {
        store.dispatch(initialize(formName, {
            data: new Array(calculateGridRowsByHeight()).fill({})
        }))
    }
}

export function getInitialValues(formName) {
    let savedData = formDataDB.getSavedData(formName);
    if (savedData) {
        return savedData.data.data;
    } else {
        return new Array(calculateGridRowsByHeight()).fill({});
    }
}

export function getSows(farm, selectedAnimals = []) {
    let sows = animalsDB.getAllAnimals(farm, AnimalTypes.SOW, false, false);
    sows = differenceBy(sows, selectedAnimals, "AnmID");
    return sows;
}

export function getParturitionedSows(farm, selectedAnimals = []) {
    let sows = animalsDB.getAllAnimals(farm, AnimalTypes.SOW, false, true)
        .filter((sowie) => checkIfSowHasPigletsInCurrentCycle(sowie.events));
    sows = differenceBy(sows, selectedAnimals, "AnmID");
    return sows;
}

function checkIfSowHasPigletsInCurrentCycle(sowEvents = []) {
    const lastInseminationIndex = findLastIndex(sowEvents, (event) => event.EvCode === EventTypes.INSEMINATION);
    if (lastInseminationIndex > -1 ) {
        const lastEvents = sowEvents.slice(lastInseminationIndex);
        const balance = getPigBalance(lastEvents);
        if (!isNil(balance) && balance > 0) return true;
    }
    return false;
}

export function getAnimals(farm, selectedAnimals = []) {
    let animals = animalsDB.getAllAnimals(farm, undefined, false, false);
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return animals;
}

export function getPorkers(farm, selectedAnimals = []) {
    let porkers = animalsDB.getAllAnimals(farm, AnimalTypes.PORKER, false, false);
    porkers = differenceBy(porkers, selectedAnimals, "AnmID");
    return porkers;
}

export function getSowsAndRenovationSows(farm, selectedAnimals = []) {
    let animals = [...animalsDB.getAllAnimals(farm, AnimalTypes.SOW, false, false), ...animalsDB.getAllAnimals(farm, AnimalTypes.RENOVATION_SOW, false, false)];
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return animals;
}

export function getAnimalOptions(array) {
    array.sort((a, b) => (a.AnmNo1 || "").localeCompare(b.AnmNo1));
    return array.map(animal => ({
        name: animal.AnmNo1,
        value: animal
    }))
}

/*******************************
 FORMATTERS
 *******************************/

export const dateFormatter = ({column: {key}, row}) => {
    if (row[key]) {
        return moment(row[key]).format("DD.MM.YYYY");
    }
    return null;
}

export const animalFormatter = ({column: {key}, row}) => {
    if (row[key]) return row[key].AnmNo1;
    return null;
}

export const operatorFormatter = ({column: {key}, row}) => {
    const {user: {employees}} = store.getState();
    let employee = employees.find(item => item.LocalUserID === row[key]);
    if (employee) return employee.userName;
    return null;
}

/*******************************
 FIELDS
 *******************************/

export function getOperators() {
    const {user: {employees}} = store.getState();
    const emps = employees.filter(item => !item.userName.includes("erased"));
    return emps.map(employee => ({
        value: employee.LocalUserID,
        name: employee.userName
    }))
}

export const operatorFilterRenderer = ({value, onChange}) => {
    return <Select value={value} options={getOperators()} onChange={onChange} placeholder={i18n.t("operator")}/>
}

export const commentFilterRenderer = ({value, onChange}) => {
    return <InputFilter type={"text"} onChange={onChange} value={value} placeholder={i18n.t("comment")}/>
}

export const dateRenderer = ({value, onChange}) => {
    return <InputFilter value={value} onChange={onChange} type={"date"}/>
}

const technologyGroupSelect = ({value, onChange}) => {
    let groups = technologyGroupsDB.getTechnologyGroups(store.getState().location.farm)
        .sort((a, b) => b.StartTime - a.StartTime)
        .filter(item => {
            let animals = item.AnmList.filter(AnmID => !item.Rmvd.includes(AnmID))
                .map(AnmID => animalsDB.getAnimalById(AnmID, {joinEvents: false}))
                .filter(item => item && !item.DtaDthTime);
            return animals.length > 0;
        })
        .map(group => ({
            value: group,
            name: moment(group.StartTime).format("DD.MM.YYYY")
        }));
    return <Select value={value} options={groups} onChange={onChange} placeholder={i18n.t("technologyGroup")}/>
}

export const operatorField = {
    name: i18n.t("operator"),
    key: "operator",
    editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={getOperators()} ref={ref}/>),
    formatter: operatorFormatter,
    filterRenderer: operatorFilterRenderer
}

export const commentField = {
    name: i18n.t("comment"),
    key: "comment",
    filterRenderer: commentFilterRenderer,
    editor: React.forwardRef((props, ref) => <InputEditor type={"text"} ref={ref} {...props}/>)
}

export const dateField = {
    name: i18n.t("date"),
    key: "date",
    editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
    formatter: dateFormatter,
    filterRenderer: dateRenderer
}

export function animalField(sows, onChange) {
    return {
        name: i18n.t("animalNumber"),
        key: "animal",
        editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={sows} ref={ref}/>),
        formatter: animalFormatter,
        filterRenderer: technologyGroupSelect,
        disableAutoInsert: true,
        disableDragAndDrop: true,
        clearRowOnRemove: true,
        onChange
    }
}

/*******************************
 INSERTS
 *******************************/
export function technologyGroupInsert(group, value, {
    field = "animal",
    onRowChange = () => {
    }
} = {}) {
    if (group) {
        let animals = group.AnmList
            .filter(AnmID => !group.Rmvd.includes(AnmID))
            .map(AnmID => animalsDB.getAnimalById(AnmID, {joinEvents: false}))
            .filter(item => item && !item.DtaDthTime);
        animals.sort((a, b) => a.AnmNo1 && a.AnmNo1.localeCompare(b.AnmNo1));
        value = value.map(() => {
            let animal = animals.shift();
            return {
                [field]: animal || null,
                ...onRowChange(animal)
            };
        });
        for (let animal of animals) {
            value.push({
                [field]: animal,
                ...onRowChange(animal)
            });
        }
    } else {
        value = value.map(row => {
            return {};
        });
    }
    return value;
}

export function insertData(data, field, value, oldFilter, predicate = () => true) {
    if (!isNil(data)) {
        value = value.map(row => {
            if (!values(row).every(isEmpty)) {
                return {
                    ...row,
                    [field]: !isNil(row[field]) && !isEqual(row[field], oldFilter) ? row[field] : predicate(row) ? data : null
                }
            }
            return {};
        })
    } else {
        value = value.map(row => {
            return {
                ...row,
                [field]: null
            };
        })
    }
    return value;
}

export function insertOperator(filters, value, oldFilter, predicate = () => true) {
    if (filters.hasOwnProperty("operator")) {
        const {operator} = filters;
        return insertData(operator, "operator", value, oldFilter, predicate);
    }
    return value;
}

export function insertComment(filters, value, oldFilter, predicate = () => true) {
    if (filters.hasOwnProperty("comment")) {
        const {comment} = filters;
        return insertData(comment, "comment", value, oldFilter, predicate);
    }
    return value;
}

export function insertFilters(filters, value, oldFilters) {
    if (filters.hasOwnProperty("animal")) {
        const {animal: group} = filters;
        const {animal: oldGroup} = oldFilters;
        if (!isEqual(group, oldGroup)) {
            value = technologyGroupInsert(group, value);
        }
    }
    if (filters.hasOwnProperty("date")) {
        const {date} = filters;
        value = insertData(date, "date", value, oldFilters.date);
    }
    value = insertOperator(filters, value, oldFilters.operator);
    value = insertComment(filters, value, oldFilters.comment);
    return value;
}

/*******************************
 VALIDATE
 *******************************/

export function validateGrid(data, customValidation, ignoreFields = [], allowDuplicatedAnimals = false) {
    let hasAnyErrors = false;
    let errors = data.map(row => {
        let rowErrors = {};
        if (!isEmptyRow(row, ignoreFields)) {
            if (!row.animal) {
                rowErrors.animal = i18n.t("required");
            } else if (!allowDuplicatedAnimals) {
                if (data.filter(item => item.animal && item.animal.AnmID === row.animal.AnmID).length > 1) {
                    rowErrors.animal = i18n.t("errors.duplicate");
                }
            }
            if (!row.operator) {
                rowErrors.operator = i18n.t("required");
            }
            if (!row.date) {
                rowErrors.date = i18n.t("required");
            }
            rowErrors = {
                ...rowErrors,
                ...customValidation(row)
            }
        }
        if (!isEmpty(rowErrors)) {
            hasAnyErrors = true;
        }
        return rowErrors;
    })
    if (hasAnyErrors) return errors;
    return undefined;
}

export const shouldRemoveFilter = memoize((animals, values) => {
    for (let row of values) {
        if (row.animal) {
            let hasAnimal = findIndex(animals, o => o.AnmID === row.animal.AnmID);
            if (hasAnimal >= 0) {
                animals.splice(hasAnimal, 1);
            } else {
                return false;
            }
        }
    }
    return animals.length <= 0;
}, (animals, values) => JSON.stringify(animals) + JSON.stringify(values));

export const checkIfHaveOnlyTechnologyGroup = memoize((technologyGroup, values) => {
    let animals = technologyGroup.AnmList
        .filter(AnmID => !technologyGroup.Rmvd.includes(AnmID))
        .map(AnmID => animalsDB.getAnimalById(AnmID, {joinEvents: false}))
        .filter(item => item && !item.DtaDthTime);
    return shouldRemoveFilter(animals, values);
}, (technologyGroup, values) => technologyGroup.TGID + JSON.stringify(values));
