import _ from "lodash";

export function colorHexToRGB(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToHex(r, g, b) {
    return '#' + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex
    }).join('')
}

export function getColorForPercentage(percentage = 0) {
    let fillColor = "error";
    if (percentage >= 20) {
        fillColor = "warning";
    }
    if (percentage >= 40) {
        fillColor = "info";
    }
    if (percentage >= 75) {
        fillColor = "success";
    }
    return fillColor;
}

const colors = {
    success: "#33cc33",
    primary: "#33cc33",
    secondary: "#313131",
    error: "#fd3b60",
    info: "#00afff",
    warning: "#ffc007",
    white: "#ffffff",
    colorLightText: "#ffffff",
    colorDarkText: "#000000",
    red: "#fd3b60",
};

const colorsNonInvasive = {

    teal: "#53cba5",
    pink: "#ff57a9",
    blue: "#00afff",
    orange: "#ffc007",
    cyan: "#00cbda",
    yellow: "#f1db0e",
    green: "#33cc33",
}
export function getColorByName(color = "primary") {
    return color.startsWith("#") ? color : {...colors, ...colorsNonInvasive}[color] || "#000000";

}

export function getColorByIndex(index=Math.floor(Math.random()*1000)) {
    return colorsNonInvasive[Object.keys(colorsNonInvasive)[index%Object.keys(colorsNonInvasive).length]]
}

function pickHex(color1, color2, weight) {
    let p = weight;
    let w = p * 2 - 1;
    let w1 = (w / 1 + 1) / 2;
    let w2 = 1 - w1;
    let rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
}

export function getColorInGradient(percentage = 0, gradientDefinition = [
    {percent: 0, color: getColorByName("error")},
    {percent: 40, color: getColorByName("warning")},
    {percent: 75, color: getColorByName("info")},
    {percent: 100, color: getColorByName("success")}
]) {
    let gradient;
    percentage = Math.round(percentage);
    percentage = Math.min(percentage, 100);
    percentage = Math.max(percentage, 0);
    if (gradientDefinition.length < 2) throw new Error("Gradient must be made of 2 or more colors");
    gradient = gradientDefinition.map(point => {
        let percent = point.percent;
        let rgb = colorHexToRGB(point.color);
        rgb = rgb ? [rgb.r, rgb.g, rgb.b] : [0, 0, 0];
        return [
            percent,
            rgb
        ]
    });
    let colorRange = [0, 1];
    if (percentage !== 0) {
        for (let index = 1; index < gradient.length; index++) {
            if (percentage <= gradient[index][0]) {
                colorRange = [index - 1, index];
                break;
            }
        }
    }
    //console.log("colorRange", colorRange);
    let firstcolor = gradient[colorRange[0]][1];
    let secondcolor = gradient[colorRange[1]][1];
    //console.log(firstcolor, "SECOND", secondcolor);

    let firstcolor_x = 100 * (gradient[colorRange[0]][0] / 100);
    let secondcolor_x = 100 * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
    let slider_x = 100 * (percentage / 100) - firstcolor_x;
    let ratio = slider_x / secondcolor_x;

    let result = pickHex(secondcolor, firstcolor, ratio);

    return 'rgb(' + result.join() + ')';

}

export function getColorByStringToIndex(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF)
    return getColorByIndex(c);
}

export function getColorByString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
}

function brightness(color) {
    if (_.isString(color)) {
        if (color.startsWith("rgb")) {
            color = color.split("(")[1].split(")")[0].split(",").map(o => +o || 0);
        } else {
            color = colorHexToRGB(color);
            color = color ? [color.r, color.g, color.b] : [0, 0, 0];
        }
    } else {
        color = color ? [color.r, color.g, color.b] : [0, 0, 0];
    }
    return Math.round((color[0] * 299) + (color[1] * 587) + (color[2] * 114) / 1000);
}

export function textContrast(color, light = getColorByName("colorLightText"), dark = getColorByName("colorDarkText")) {
    if (_.isNil(color)) {
        return color;
    } else {
        let colorBrightness = brightness(color);
        let lightTextBrightness = brightness(light);
        // let darkTextBrightness = brightness(dark);
        return Math.abs(colorBrightness - 30000) < (lightTextBrightness / 2) ? light : dark;
    }
}

export function setBackgroundColorInMeta() {
    setTimeout(() => {
        let root = document.getElementById("root");
        let css = getComputedStyle(root).getPropertyValue("background-color");
        let rgbArray = css.split("(")[1].split(")")[0].split(",").map(item => +item);
        let hex = rgbToHex(...rgbArray);
        document.querySelector('meta[name="theme-color"]').setAttribute("content", hex);
    });
}