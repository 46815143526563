import store from "../store/store";
import {get} from "lodash";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import * as UserTypes from "validators-schema/Api/constants/userTypes";
import i18next from "i18next";
import {LicPackageLevel} from "../constans/roles";

export function hasRole(roles, roleName, farmID) {
    let role = roles.find(r => r.Role === roleName);
    if (role) {
        role = role.LocalRights.find(r => r.FarmID === farmID)
    }
    return role;
}

export function getRolesForCurrentUser(userRoles, farmID = store.getState().location.farm) {
    const userType = get(store.getState(), "user.user.UserType");
    const isOwner = userType === UserTypes.OWNER;
    const isService = userType === UserTypes.SERVICE;
    let result = [];
    for (let property in RoleTypes) {
        const roleObject = {hasRole: !!hasRole(userRoles, RoleTypes[property], farmID)};
        switch (RoleTypes[property]) {
            case RoleTypes.GENERAL_READ:
                roleObject.text = i18next.t("userRolePicker.general");
                break;
            case RoleTypes.BREEDING:
                roleObject.text = i18next.t("breeding");
                break;
            case RoleTypes.DEVICE_CAGE:
                roleObject.text = i18next.t("userRolePicker.cages");
                break;
            case RoleTypes.DEVICE_SILO:
                roleObject.text = i18next.t("weights");
                break;
            case RoleTypes.DEVICE_CONFIG:
                roleObject.text = i18next.t("userRolePicker.configuration");
                break;
            case RoleTypes.REPORT_READ:
                roleObject.text = i18next.t("userRolePicker.reportsRead");
                break;
            case RoleTypes.REPORT_WRITE:
                roleObject.text = i18next.t("userRolePicker.reportsWrite");
                break;
            case RoleTypes.DEVICE_FEEDING:
                roleObject.text = i18next.t("feeding");
                break;
            case RoleTypes.DEVICE_CLIMATE:
                roleObject.text = i18next.t("climate");
                break;
            case RoleTypes.DEVICE_CHAIN:
                roleObject.text = i18next.t("userRolePicker.chains");
                break;
            case RoleTypes.BILLING: {
                if (isOwner || isService) roleObject.text = i18next.t("billing"); // tylko owner/serwis moze widziec czy ktos ma dostep do platnosci
                break;
            }
            case RoleTypes.BUILDING:
                roleObject.text = i18next.t("userRolePicker.buildings");
                break;
            case RoleTypes.USER_MANAGE:
                roleObject.text = i18next.t("userRolePicker.userManage");
                break;
            case RoleTypes.DEVICE_PIGLET_CAGE:
                roleObject.text = i18next.t("userRolePicker.smallCages");
                break;
            case RoleTypes.DEVICE_COUNTER:
                roleObject.text = i18next.t("userRolePicker.counters");
                break;
            case RoleTypes.REPORTS:
                break;
            default:
                roleObject.text = RoleTypes[property];
                break;
        }
        result.push(roleObject);
    }
    return result.filter(r => r.text);
}


/**
 * funkcja sprawdza czy user może konfigurować urządzenia na danej fermie
 * @returns {boolean}
 */
export function checkIfUserCanManage() {
    const {user: {user: {Roles: userRoles, UserType}}, location: {farm: FarmID}} = store.getState();
    const deviceRoles = (userRoles || []).filter((r) => get(r, "Role", "").startsWith("_DEVICE"));
    if ([UserType.OWNER, UserTypes.SERVICE].includes(UserType)) return true;
    else {
        const deviceConfigRole = deviceRoles.find((dr) => dr.Role === RoleTypes.DEVICE_CONFIG);
        if (deviceConfigRole) {
            const hasRightsToFarm = deviceConfigRole.LocalRights.find((item) => item.FarmID === FarmID);
            if (hasRightsToFarm) return true;
        }
    }
    return false;
}

export function checkIfUserHasRole(role, farmID, userRoles = null) {
    if (!userRoles) {
        userRoles = store.getState().user.user.Roles;
    }
    if (!farmID) {
        farmID = store.getState().location.farm;
    }
    if (role === RoleTypes.SERVICE) {
        let serviceRole = userRoles.find(item => item.Role === RoleTypes.SERVICE);
        if (serviceRole) {
            return !!serviceRole.LocalRights.find(item => item.FarmID === farmID);
        }
        return false;
    }
    for (let uRole of userRoles) {
        if (uRole.Role === RoleTypes.SERVICE || uRole.Role === role) {
            let hasRightsToFarm = uRole.LocalRights.find((item) => item.FarmID === farmID);
            if (hasRightsToFarm) return true;
        }
    }
    return false;
}

export function checkIfUserIsService(userRoles) {
    if (!userRoles) {
        const usr = store.getState().user.user;
        userRoles = usr && usr.Roles ? usr.Roles : [];
    }
    return userRoles.filter(item => item.Role === RoleTypes.SERVICE).length > 0;
}

export function checkIfUserIsOwner() {
    let userRoles = store.getState().user.user.Roles;
    let userType = store.getState().user.user.UserType;
    return userRoles.filter(item => userType === UserTypes.OWNER || item.Role === RoleTypes.BILLING).length > 0;
}

export function checkIfUserHasMinManagement(user = store.getState().user.user) {
    return get(user, "Roles", []).filter((item) => item.Role === RoleTypes.BILLING || item.Role === RoleTypes.USER_MANAGE).length > 0;
}

export function checkIfUserHasUsersManagement(user = store.getState().user.user) {
    return get(user, "Roles", []).filter(item => item.Role === RoleTypes.USER_MANAGE).length > 0;
}

export function checkIfUserHasToSubscribe(user = store.getState().user.user) {
    if (get(user, "Roles", []).filter((item) => item.Role === RoleTypes.SERVICE).length > 0) return false;
    return !get(user, "AdminSubscription", false);
}

export function checkIfUserIsFarmAdmin(user = store.getState().user.user) {
    return user.UserType === UserTypes.MANAGER
}

export function hasMinPackageLevel(licPackages = [], packageKeys, packageLevels) {
    const toNumber = (level) => {
        switch (level) {
            case LicPackageLevel.EXTENDED:
                return 2;
            case LicPackageLevel.BASIC:
                return 1;
            default:
                return 0;
        }
    };
    for (let i = 0; i < packageKeys.length; i++) {
        const packageKey = packageKeys[i];
        const packageLevel = packageLevels[i];
        const level = licPackages[packageKey] || LicPackageLevel.NO_ACCESS;
        if (toNumber(level) >= toNumber(packageLevel)) return true;
    }
    return false;
}

/**
 * funkcja sprawdzajaca czy user jest kontem serwisowym, ownerem lub menadżerem
 * @param user
 * @returns {boolean}
 */
export function checkIfUserHasPrivilegedAccess(user = store.getState().user.user) {
    return [UserTypes.SERVICE, UserTypes.OWNER, UserTypes.MANAGER].includes(user.UserType);
}
