import i18n from "../i18n";

const t = (val) => i18n.t(val);

export const AWSLoginMessageMap = (message) => {
    console.log(message);
    // moglem wszystkiego nie przetlumaczyc bo nigdzie nie ma listy jakie bledy sa zwracane
    switch (message) {
        case "Username cannot be empty": // wyrzuca jak nie ma wpisanego hasła
            return t("errors.noEmptyLogin");
        case "Password cannot be empty": // hasło jest puste
        case "Custom auth lambda trigger is not configured for the user pool.": // wyrzuca jak wpiszemy tylko login
            return t("errors.noEmptyPassword");
        case "User is disabled.": //uzytkownik jest wylaczony
            return t("errors.UserDisabled");
        case "Attempt limit exceeded, please try after some time.": // przekroczona ilosc prob
            return t("errors.LimitExceededException");
        case "Confirmation code cannot be empty": // kod weryfikacyjny jest pusty
            return t("errors.noEmptyVerificationCode");
        case "Password did not conform with policy: Password not long enough":
        case "Password does not conform to policy: Password not long enough": //za krótkie hasło
        case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
            return t("errors.passLognerThan");
        case "Invalid verification code provided, please try again.": //zły kod weryfikacyjny
            return t("errors.CodeMismatchException");
        case "Password did not conform with policy: Password must have uppercase characters":
        case "Password does not conform to policy: Password must have uppercase characters": // brak wielkiej litery w hasle
            return t("errors.passMinOneBig");
        case "Password did not conform with policy: Password must have numeric characters":
        case "Password does not conform to policy: Password must have numeric characters": // brak cyfry w hasle
            return t("errors.passMinOneNumber");
        case "Password did not conform with policy: Password must have symbol characters":
        case "Password does not conform to policy: Password must have symbol characters": // brak specjalnego znaku w hasle
            return t("errors.passMinOneSpecial");
        case "User does not exist.": // nie odnaleziono uzytkownika na cognito
        case "Incorrect username or password.": // bledny login lub haslo
            return t("errors.BadLoginPassword");
        case "No connection to server":
            return t("errors.noConnection");
        case "Username/client id combination not found.":
            return t("errors.UserNotFound");
        case "User is not confirmed.":
            return t("errors.UserNotConfirmed");
        case "The Auth category has been configured successfully":
            return t("errors.RegistrationSuccess");
        case "Session expired.":
            return t("errors.sessionExpired");
        case "Cannot reset password for the user as there is no registered/verified email or phone_number":
            return t("errors.noRegisteredMail");
        default:
            return message;
    }
};