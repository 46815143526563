import moment from "moment";
import "moment-timezone";
import geoloc from "tz-lookup";
import store from "../store/store";
import {getFarm} from "../selectors/farmSelector";

export function getFarmZoneType(FarmID) {
    let farmTimezone = moment.tz.guess(true);
    if (FarmID) {
        try {
            let farm = getFarm(store.getState(), FarmID);
            let cords = farm.FarmCord.split(",");
            let x = 0;
            let y = 0;
            if (farm.FarmCord.includes("'")) {
                x = +cords[0].split("'").join(".") || 0;
                y = +cords[1].split("'").join(".") || 0;
            } else {
                x = +cords[0] || 0;
                y = +cords[1] || 0;
            }
            farmTimezone = geoloc(x, y);
            return farmTimezone
        } catch (e) {
            farmTimezone = moment.tz.guess(true);
        }
    }
    return farmTimezone;
}

export function getLocalFarmTime(FarmID) {
    let farmID = FarmID ? FarmID : store.getState().location.farm;
    const farmZoneTypeType = getFarmZoneType(farmID);
    const localZoneType = moment.tz.guess(true);
    const farmOffset = moment.tz.zone(farmZoneTypeType).utcOffset(moment());
    const localOffset = moment.tz.zone(localZoneType).utcOffset(moment());
    const diffrence = (localOffset - farmOffset) / 60;
    let correctTime = +moment();
    if (diffrence !== 0) {
        correctTime = diffrence > 0 ? +moment().add(diffrence, "hours") : +moment().subtract(diffrence, "hours");
    }
    return +correctTime;
}
