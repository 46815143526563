import moment from "moment";
import Event from "../beans/Event"
import Transfer from "../beans/events/Transfer"
import Separation from "../beans/events/Separation";
import NoPregnancy from "../beans/events/NoPregnancy";
import USG from "../beans/events/USG";
import Parturition from "../beans/events/Parturition";
import Insemination from "../beans/events/Insemination";
import Mommy from "../beans/events/Mommy";
import SeparationToMommy from "../beans/events/SeparationToMommy";
import FallPiglets from "../beans/events/FallPiglets";
import Sell from "../beans/events/Sell";
import Grafting from "../beans/events/Grafting";
import Selection from "../beans/events/Selection";
import Condition from "../beans/events/Condition";
import Fall from "../beans/events/Fall";
import Treatment from "../beans/events/Treatment";
import store from "../store/store"
import _ from "lodash"
import Weighting from "../beans/events/Weighting";
import ActiveNipples from "../beans/events/ActiveNipples";
import insertion from "../resources/images/events/insertion.svg";
import {AnimalTypes} from "../constans/animalTypes";
import insemination from "../resources/images/events/insemination.svg";
import usg from "../resources/images/events/usg.svg";
import noPregnancy from "../resources/images/events/no-pregnancy.svg";
import parturition from "../resources/images/events/parturition.svg";
import separationPorkhouse from "../resources/images/events/separation-porkhouse.svg";
import separationMommy from "../resources/images/events/separation-mommy.svg";
import grafting from "../resources/images/events/grafting.svg";
import treatment from "../resources/images/events/treatment.svg";
import fallPiglets from "../resources/images/events/fall-piglets.svg";
import transfer from "../resources/images/events/transfer.svg";
import fall from "../resources/images/events/fall.svg";
import sell from "../resources/images/events/sell.svg";
import selection from "../resources/images/events/selection.svg";
import weighting from "../resources/images/events/weighting.svg";
import active_nipples from "../resources/images/events/active_nipples.svg";
import reclassify from "../resources/images/events/reclassify.svg";
import heat from "../resources/images/events/heat.svg"
import piglets_treatment from "../resources/images/events/piglets_treatment.svg";
import PigletsTreatment from "../beans/events/PigletsTreatment";
import Heat from "../beans/events/Heat";
import SowCycles from "../beans/events/SowCycles";
import animalsDB from "../database/animalsDB";
import groupsDB from "../database/groupsDB";
import technologyGroupsDB from "../database/technologyGroupsDB";
import {getDaysForRepeatedInsemination, getTimeFromInseminationToPartuition} from "./SettingsUtils";
import formDataDB from "../database/formDataDB";
import {eventChooserFormNames} from "../constans/formNames";
import {EventCategory} from "../constans/eventTypes";
import * as EventTypes from "validators-schema/Api/constants/eventTypes";
import {DICTIONARY_TYPE} from "../constans/general";
import {checkIfUserIsService} from "./NewRolesUtils";

export const KILL_TIME = {
    ONE_DAY: {time: 1, type: "days"},
    TWO_WEEKS: {time: 2, type: "weeks"},
    HALF_OF_YEAR: {time: 6, type: "months"},
    THREE_YEARS: {time: 3, type: "years"}
};

export function getKillTime(killTime) {
    if (!killTime) killTime = KILL_TIME.HALF_OF_YEAR;
    let time = moment.tz("Europe/Warsaw").add(killTime.time, killTime.type);
    return parseInt(time.toDate().getTime() / 1000);
}

export function getClass(event) {
    try {
        switch (event.EvCode) {
            case EventTypes.TRANSFER:
                return new Transfer(event);
            case EventTypes.SEPARATION:
                return new Separation(event);
            case EventTypes.NO_PREGNANCY:
                return new NoPregnancy(event);
            case EventTypes.USG:
                return new USG(event);
            case EventTypes.PARTURITION:
                return new Parturition(event);
            case EventTypes.INSEMINATION:
                return new Insemination(event);
            case EventTypes.MOMMY:
                return new Mommy(event);
            case EventTypes.SEPARATION_TO_MOMMY:
                return new SeparationToMommy(event);
            case EventTypes.FALL_PIGLETS:
                return new FallPiglets(event);
            case EventTypes.SELL:
                return new Sell(event);
            case EventTypes.SELECTION:
                return new Selection(event);
            case EventTypes.GRAFTING:
                return new Grafting(event);
            case EventTypes.CONDITION:
                return new Condition(event);
            case EventTypes.FALL:
                return new Fall(event);
            case EventTypes.TREATMENT:
                return new Treatment(event);
            case EventTypes.WEIGHTING:
                return new Weighting(event);
            case EventTypes.ACTIVE_NIPPLES:
                return new ActiveNipples(event);
            case EventTypes.PIGLETS_TREATMENT:
                return new PigletsTreatment(event);
            case EventTypes.HEAT:
                return new Heat(event);
            case EventTypes.SOW_CYCLES:
                return new SowCycles(event);
            default:
                return new Event(event);
        }
    } catch (e) {
        console.error(e);
        return event;
    }
}

export function getAllEventsForAnimalFromState(AnmID, eventType, showDeleted = false) {
    let state = store.getState();
    const {events: {eventsForAnimals}} = state;
    let cp = _.cloneDeep(eventsForAnimals.get(AnmID));
    if (cp) {
        return cp.filter(item => (!showDeleted ? !item.DtaDelTime : item) && item.EvCode === eventType).map(event => getClass(event));
    }
    return [];
}

export function getLastEventForAnimalFromState(AnmID, eventType) {
    let events = getAllEventsForAnimalFromState(AnmID, eventType);
    events.sort((a, b) => b.EvTime - a.EvTime);
    return events[0];
}

export function getPigBalance(events, resetOnInsemination = true) {
    let pigBalance = 0;
    events.sort((a, b) => +a.EvTime - +b.EvTime);
    for (let ev of events) {
        switch (ev.EvCode) {
            case EventTypes.PARTURITION:
                try {
                    pigBalance += +ev.EvData.HealthyCnt;
                } catch (e) {

                    console.error(e);
                }
                break;
            case EventTypes.FALL_PIGLETS:
                try {
                    pigBalance -= +ev.EvData.Piglets;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.SEPARATION_TO_MOMMY:
            case EventTypes.SEPARATION:
                try {

                    pigBalance -= +ev.EvData.PiCnt;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.MOMMY:
                try {
                    pigBalance += +ev.EvData.PiCnt;
                } catch (e) {
                    console.error(e);
                }
                break;

            case EventTypes.INSEMINATION:
                if (resetOnInsemination) {
                    pigBalance = 0;
                }
                break;
            default:
                break;
        }
    }
    return pigBalance;
}

export function getAllEventsWithEvTimeGreaterThanOrEqualsForAnimalFromState(AnmID, EvTime) {
    try {
        let state = store.getState();
        const {events: {eventsForAnimals}} = state;
        let cp = _.cloneDeep(eventsForAnimals.get(AnmID));
        cp = cp.filter(item => item.EvTime >= EvTime && !item.DtaDelTime);
        return cp.sort((a, b) => a.EvTime - b.EvTime).map(event => getClass(event));
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getPigBalanceForSowFromState(animal, selectedDate) {
    if (!animal) return 0;
    try {
        let lastBirth = getLastEventForAnimalFromState(animal.AnmID, EventTypes.PARTURITION);
        if (!lastBirth) return 0;
        let evtz = getAllEventsWithEvTimeGreaterThanOrEqualsForAnimalFromState(animal.AnmID, lastBirth.EvTime);
        // console.log("e1", evtz);
        selectedDate && (evtz = evtz.filter(ev => moment(+ev.EvTime).startOf('day').isSameOrBefore(selectedDate)));
        return getPigBalance(evtz);
    } catch (e) {
        console.error(e);
        return 0;
    }
}

export function getEventsList(t) {
    return [
        {
            label: t("insertion"),
            value: "animalKind/insertion",
            svgPath: insertion,
            eligibleAnimal: [AnimalTypes.SOW, AnimalTypes.BOAR, AnimalTypes.PIGLET, AnimalTypes.PORKER, AnimalTypes.RENOVATION_SOW],
            category: EventCategory.OVERALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.PORKER_AND_PIGLET_GROUP_INSERTION, eventChooserFormNames.PORKER_AND_PIGLET_RFID_INSERTION, eventChooserFormNames.RENOVATION_SOW_AND_BOAR_INSERTION, eventChooserFormNames.SOW_INSERTION])
        },
        {
            label: t("insemination"),
            value: "insemination",
            svgPath: insemination,
            eligibleAnimal: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW],
            category: EventCategory.PROCREATION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.INSEMINATION])
        },
        {
            label: t("usg"),
            value: "usg",
            svgPath: usg,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PROCREATION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.USG])
        },
        {
            label: t("noPregnancy"),
            value: "noPregnancy",
            svgPath: noPregnancy,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PROCREATION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.NO_PREGNANCY])
        },
        {
            label: t("parturition"),
            value: "parturition",
            svgPath: parturition,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PARTURITION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.PARTURITION])
        },
        {
            label: t("separationToPigletHouse"),
            value: "locationChooser/separationToPigletHouse",
            svgPath: separationPorkhouse,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PARTURITION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.SEPARATION_TO_PORKHOUSE])
        },
        {
            label: t("separationToMommy"),
            value: "separationToMommy",
            svgPath: separationMommy,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PARTURITION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.SEPARATION_TO_MOMMY])
        },
        {
            label: t("grafting"),
            value: "grafting",
            svgPath: grafting,
            eligibleAnimal: [AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER, AnimalTypes.RENOVATION_SOW],
            category: EventCategory.GRAFTING,
            drafts: formDataDB.getDrafts([eventChooserFormNames.GRAFTING])
        },
        {
            label: t("scheduleOfTreatment"),
            value: "scheduleOfTreatment",
            svgPath: treatment,
            eligibleAnimal: [AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER, AnimalTypes.RENOVATION_SOW],
            category: EventCategory.GRAFTING,
            drafts: formDataDB.getDrafts([eventChooserFormNames.TREATMENT])
        },
        {
            label: t("fallPiglets"),
            value: "fallPiglets",
            svgPath: fallPiglets,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.FALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.FALL_PIGLETS])
        },
        {
            label: t("transfer"),
            value: "locationChooser/transfer",
            svgPath: transfer,
            eligibleAnimal: [AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER, AnimalTypes.RENOVATION_SOW],
            category: EventCategory.OVERALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.TRANSFER_STAND, eventChooserFormNames.TRANSFER_CHAMBER])
        },
        {
            label: t("fall"),
            value: "fall",
            svgPath: fall,
            eligibleAnimal: [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER],
            category: EventCategory.FALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.FALL])
        },
        {
            label: t("sale"),
            value: "sale",
            svgPath: sell,
            eligibleAnimal: [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER],
            category: EventCategory.SELL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.SELL])
        },
        {
            label: t("selection"),
            value: "selection",
            svgPath: selection,
            eligibleAnimal: [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.BOAR],
            category: EventCategory.SELL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.SELECTION])
        },
        {
            label: t("weighting"),
            value: "weighting",
            svgPath: weighting,
            eligibleAnimal: [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.PIGLET, AnimalTypes.BOAR, AnimalTypes.PORKER],
            category: EventCategory.OVERALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.WEIGHTING])
        },
        {
            label: t("activeNipples"),
            value: "activeNipples",
            svgPath: active_nipples,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.PARTURITION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.ACTIVE_NIPPLES])
        },
        {
            label: t("reclassify"),
            value: "reclassify",
            svgPath: reclassify,
            eligibleAnimal: [AnimalTypes.PORKER],
            category: EventCategory.OVERALL,
            drafts: formDataDB.getDrafts([eventChooserFormNames.RECLASSIFY])
        },
        {
            label: t("pigletsTreatment"),
            value: "pigletsTreatment",
            svgPath: piglets_treatment,
            eligibleAnimal: [AnimalTypes.SOW],
            category: EventCategory.GRAFTING,
            drafts: formDataDB.getDrafts([eventChooserFormNames.PIGLETS_TREATMENT])
        },
        {
            label: t("heat"),
            value: "heat",
            svgPath: heat,
            eligibleAnimal: [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW],
            category: EventCategory.PROCREATION,
            drafts: formDataDB.getDrafts([eventChooserFormNames.HEAT])
        }
    ]
}

/**
 * Metoda pobiera GrID dla podanego ID zwierzecia
 * @param AnmID {string}    ID zwierzęcia
 * @param EvTime {number}   Czas wykonania eventu
 * @return {string}         noGrID | ID grupy | ID grupy technologicznej
 */
export function getGrIDByAnmID(AnmID, EvTime) {
    let animal = animalsDB.getAnimalById(AnmID);
    if (!animal) throw new Error("Nie odnaleziono zwierzęcia");
    return getGrIDByAnimal(animal, EvTime);
}

/**
 * Zwraca GrID dla podanego zwierzęcia
 * @param animal {Animal}   obiekt zwierzecia
 * @param EvTime {number}   Czas wykonania eventu
 * @param state {boolean}   Czy ma brać ze state aplikacji czy lokiego
 * @return {string}         noGrID | ID grupy | ID grupy technologicznej
 */
export function getGrIDByAnimal(animal, EvTime, state = true) {
    try {
        // w przypadku tucznika lub warchlaka zwroc ID grupy
        if (animal.AnimalKind === AnimalTypes.PORKER || animal.AnimalKind === AnimalTypes.PIGLET) {
            let animalGroup = groupsDB.getGroupWithAnimal(animal.AnmID, animal.FarmID)[0];
            if (animalGroup) {
                return animalGroup.AnmGrp;
            }
        }
        // w przypadku lochy lub maciory zwroc id grupy technologicznej
        if (animal.AnimalKind === AnimalTypes.SOW || animal.AnimalKind === AnimalTypes.RENOVATION_SOW) {
            let techologyGroup = technologyGroupsDB.getTechnologyGroupsWithAnimal(animal.FarmID, animal.AnmID, true).filter(item => item.StartTime < EvTime).sort((a, b) => b.StartTime - a.StartTime)[0];
            console.log(techologyGroup);
            if (techologyGroup && techologyGroup.includesDate(EvTime)) {
                // TODO wymagane dokladniejsze zabezpieczenie w przypadku
                // let events = state ? store.getState().events.eventsForAnimals.get(animal.AnmID) || [] : eventsDB.getAllEvents4Animal(animal.AnmID);
                // events = events.filter(item => item.EvTime >= techologyGroup.StartTime);
                // let firstInsemination = events.sort((a, b) => a.EvTime - b.EvTime).find(item => item.EvCode === EventTypes.INSEMINATION);
                // let dateOfFirstInsemination = moment(firstInsemination.EvTime);
                // // sprawdza czy po ostatniej grupie technologicznej nie ma wiecej porodow niz 1
                // let hasMoreParturitions = events.filter(item => item.EvCode === EventTypes.PARTURITION).length > 0;
                // // sprawdza czy po ostatniej grupie technologicznej nie ma wiecej niz 1 braku ciazy
                // let hasMoreNoPregnancies = events.filter(item => item.EvCode === EventTypes.NO_PREGNANCY).length > 0;
                // let timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
                // // sprawdza czy po inseminacji + czas do porodu nie ma negatywnych badan usg
                // let hasUSGAfterPlannedBirth = events.filter(item => item.EvCode === EventTypes.USG && item.EvData.Pregnant === false && moment(item.EvTime).diff(dateOfFirstInsemination, "days") > timeFromInseminationToParturition).length > 0;
                // // sprawdzenie czy nie ma wiecej niz 1 odsadzen
                // let hasMoreSeparations = events.filter(item => item.EvCode === EventTypes.SEPARATION).length > 0;
                // // TODO sprawdzic czy nie ma innych warunkow
                // console.log(hasMoreParturitions, hasMoreNoPregnancies, hasUSGAfterPlannedBirth, hasMoreSeparations);
                // if (!hasMoreParturitions && !hasMoreNoPregnancies && !hasUSGAfterPlannedBirth && !hasMoreSeparations) {
                //     return techologyGroup.TGID;
                // }
                return techologyGroup.TGID;
            }
        }
        return "noGrID";
    } catch (e) {
        console.error(e);
        return "noGrID"
    }
}

/**
 * Zwraca true lub false w zależności od daty jaka jest wprowadzona
 * @return {boolean}
 * @param value
 * @param farmID
 */
export function isEventDateInvalid(value, farmID) {
    if (checkIfUserIsService()) return false;
    return moment(value).toDate().getTime() > moment().toDate().getTime();
}

export function getFirstInseminationInCycle(events) {
    events.sort((a, b) => +a.EvTime - +b.EvTime);
    let inseminations = events.filter(ev => ev.EvCode === EventTypes.INSEMINATION);
    let firstInsemination = inseminations[0];
    let daysForRepeatedInsemination = getDaysForRepeatedInsemination();
    for (let insemination of inseminations) {
        if (moment(insemination.EvTime).diff(firstInsemination.EvTime, "days") > daysForRepeatedInsemination) {
            return insemination;
        }
    }
    return firstInsemination;
}

export function getPlannedParturition(events) {
    let firstInsemination = getFirstInseminationInCycle(events);
    //jesli jest inseminacja wyliczamy date planowanego porodu
    if (firstInsemination) {
        const timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
        return +moment(+firstInsemination.EvTime).add(timeFromInseminationToParturition, "days");
    }
    return null;
}

/**
 * Funkcja zwracająca date planowanego porodu liczoną od dnia pierwszej inseminacji
 * @param events
 * @returns {string}
 */
export function getPlannedParturitionForASow(events) {
    let plannedParturition = getPlannedParturition(events);
    if (plannedParturition) {
        return moment(plannedParturition).format("DD.MM.YYYY");
    }
    return "-";
}

export const getTranslationPath = (type) => {
    const types = {
        [DICTIONARY_TYPE.clients]: "newSettings.dictionary.manage.item.clients",
        [DICTIONARY_TYPE.fallReasons]: "newSettings.dictionary.manage.item.fallReasons",
        [DICTIONARY_TYPE.feedingCurves]: "newSettings.dictionary.manage.item.feedingCurves",
        [DICTIONARY_TYPE.forageType]: "newSettings.dictionary.manage.item.forageType",
        [DICTIONARY_TYPE.graftingReason]: "newSettings.dictionary.manage.item.graftingReason",
        [DICTIONARY_TYPE.medicine]: "medicines",
        [DICTIONARY_TYPE.noPreganancy]: "newSettings.dictionary.manage.item.noPreganancy",
        [DICTIONARY_TYPE.race]: "newSettings.dictionary.manage.item.race",
        [DICTIONARY_TYPE.selectionReason]: "newSettings.dictionary.manage.item.selectionReason",
        [DICTIONARY_TYPE.suppliers]: "newSettings.dictionary.manage.item.suppliers",
        [DICTIONARY_TYPE.weighting]: "weighting",
        [DICTIONARY_TYPE.controlLists]: "controlLists.mainView.header",
        [DICTIONARY_TYPE.taskCategory]: "newSettings.dictionary.manage.item.taskCategory"
    }
    return types[type] || null
}
