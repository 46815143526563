/**
 * Funckja normalizująca input NIPu
 * @param value
 * @returns {*}
 */
export function normalizeNIP(value) {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length > 3 && onlyNums.length <= 5) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}`
    }
    if (onlyNums.length > 5 && onlyNums.length <= 7) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 7)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 7)}-${onlyNums.slice(7, 10)}`
}

export function checkNip(value) {
    let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    value = value.replace(/-/g, "");
    let splitted = value.split("");
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += weights[i] * +splitted[i];
    }
    return sum % 11 === +splitted[9];
}

export function normalizeRFID(value) {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length > 16) {
        return onlyNums.slice(0, 16);
    }
    return onlyNums;
}