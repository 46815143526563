import {detect} from "detect-browser";

export function isMobile() {
    try {
        const browser = detect();
        if (browser.os.startsWith("Android")) return true;
        if (browser.os.startsWith("iPad")) return true;
        if (browser.os.startsWith("iPhone")) return true;
        if (browser.os.startsWith("iOS")) return true;
    } catch (e) {
        console.error("isMobile error", e.name, e.message);
    }
    return false;

}