import _ from "lodash";

export const ScrollLock = {
    NON_BLOCKING: 0,
    MENU: 1,
    LEFT_BAR: 2,
    MODAL: 3,
    DRAWER: 4,
    RIGHT_DRAWER: 5
};

export function scrollToID(id, {block = 'start'} = {}) {
    try {
        document.getElementById(id).scrollIntoView({block: block, behavior: 'smooth'});
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }

}

export function scrollToTop(props = {}) {
    document.body.scrollIntoView(({block: 'start', behavior: 'smooth', ...props}));
}

export function scrollVirtualizedFixedHeight(_listContainer, _indexToScroll = -1) {
    try {
        if (_indexToScroll === -1) {
            return;
        }
        console.log(_listContainer)
        const rect = _listContainer.getBoundingClientRect();
        const rectItem = _listContainer.getElementsByClassName("fetura-list-item")[0].getBoundingClientRect();
        const offsetScroll = window.scrollY;
        const offsetInContainer = _indexToScroll * (rectItem.height);
        const scrollY = rect.top + offsetInContainer + offsetScroll;
        const scrollMax = window.document.body.offsetHeight - window.outerHeight;
        console.table({scrollMax, offsetInContainer, scrollY, offsetScroll, rect, rectItem});
        window.scrollTo({
            top: Math.min(scrollY - getRemToPx(10), scrollMax),
            left: window.innerWidth / 2
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function getStyleValue(style) {
    let re = new RegExp(/\((.*?)\)/);
    return _.get(style.match(re), "[1]", null);
}

export function isOverflown({clientWidth, scrollWidth} = {}) {
    return scrollWidth > clientWidth;
}


const lockClassName = (number) => number && _.isFinite(number) ? `lock-scroll-${number}` : "lock-scroll";

export function bodyLockScroll(uniqueNumber = 0) {
    document.body.classList.add(lockClassName(uniqueNumber));

}

export function bodyUnlockScroll(uniqueNumber = 0) {
    document.body.classList.remove(lockClassName(uniqueNumber));
}

export function getRemToPx(rem = 1) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function blinkElement(_id, {timeoutBefore = 300, timeoutAfter = 1000} = {}) {
    try {
        setTimeout(() => {
            try {
                const element = document.getElementById(_id);
                element.classList.add("notice-me");
                element.classList.add("noticable");
                setTimeout(() => {
                    try {
                        element.classList.remove("notice-me")
                    } catch (e) {
                    }
                }, timeoutAfter)
            } catch (e) {

            }
        }, timeoutBefore);
    } catch (e) {
        console.error(e);
    }
}

export function getHeaderTabsHeight() {
    let height;
    try {
        height = document.getElementById("app-header").getElementsByClassName("rmc-tabs-tab-bar")[0].getBoundingClientRect().height || 0;
    } catch (e) {
        height = 0;
    }
    if (height === 0) {
        try {
            height = document.getElementById("app-header").getElementsByClassName("tabs")[0].getBoundingClientRect().height || 0;
        } catch (e) {
            height = 0;
        }
    }
    return height;

}

/**
 * Nie wiem czemu ale jak usune na developie lokiego i reloadne strone to nigdy sie nie zaladuje stronki az nie wywolam tego
 */
export function unregisterServiceWorkers() {
    try {
        console.log("Force unregister service worker")
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        if (registration.scope.includes("fetura")) {
                            registration.unregister().then(o => console.log(registration.scope, " out!"));
                        }
                    }
                });
        }
    } catch (e) {
        console.error(e)
    }

}

export function onElementPainted(callback) {
    setTimeout( () => {
        requestAnimationFrame(callback)
    })
}
