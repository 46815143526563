import {myID} from "../libs/generateID";
import store from "../store/store";
import {addNotification, updateNotification} from "reapop";

export function loadingNotification(title, message) {
    let notification = {
        id: myID(),
        title: title,
        message: message,
        status: 'loading',
        dismissible: false,
        dismissAfter: 15000,
    };
    store.dispatch(addNotification(notification));
    return notification;

}

export function onSuccessNotification(title, notif) {
    let notification = {
        ...notif,
        title: title,
        status: 'success',
        dismissible: true,
        dismissAfter: 3000,
    };
    store.dispatch(updateNotification(notification));

}

export function onErrorNotification(title, notif) {
    let notification = {
        ...notif,
        title: title,
        status: 'error',
        dismissible: true,
        dismissAfter: 0,
    };
    store.dispatch(updateNotification(notification));
}