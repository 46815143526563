import store from "../store/store";

export function getLinkToDocument(doc) {
    if (!doc) {
        return undefined;
    } else {
        let locale = store.getState().language.locale;
        if (Array.isArray(doc.Link)) {
            let index = (Array.isArray(doc.Lang) ? doc.Lang : []).findIndex(d => d === locale);
            index = index > -1 ? index : 0;
            return doc.Link[index];
        } else {
            return doc.Link;
        }
    }
}

export function getDocumentName(doc) {
    if (!doc) {
        return undefined;
    } else {
        let locale = store.getState().language.locale;
        if (Array.isArray(doc.Name)) {
            let index = (Array.isArray(doc.Lang) ? doc.Lang : []).findIndex(d => d === locale);
            index = index > -1 ? index : 0;
            return doc.Name[index];
        } else {
            return doc.Name;
        }
    }
}