import moment from "moment"
import eventsDB from "../database/eventsDB";
import {EventTypes} from "../constans/eventTypes";
import {getMaxDelayForBirth, getTimeFromInseminationToPartuition} from "./SettingsUtils";

export function addZerosToRfid(RFID) {
    try {
        for (let i = RFID.length; i < 16; i++) {
            RFID = "0" + RFID;
        }
        return RFID;
    } catch (err) {
        console.error(err)
    }
}

export function checkIfSowHadEventInPregnancy(AnmID, startTime, type = EventTypes.INSEMINATION) {
    let events = eventsDB.getAllEvents4AnimalWithType(AnmID, type)
        .filter(item => item.EvTime <= startTime && moment(startTime).startOf("day")
            .diff(moment(item.EvTime).startOf("day"), "days") <= getTimeFromInseminationToPartuition() + getMaxDelayForBirth()) // sprawdza w przedziale od daty nowego zdarzenia do czasu z ustawien czas do porodu + maks opoznienie (np. 112 + 5)
        .sort((a, b) => b.EvTime - a.EvTime);
    return events[0];
}