import {UnitTypes} from "../constans/unitTypes";
import convert from "convert-units";
import _ from "lodash";
import {getUnitSystem} from "./SettingsUtils";

const defaults = {
    weight: "g",
    temperature: "C",
    length: "cm",
    pressure: "bar",
    volume: "ml",
    energy: "Wh",
    power: "W",
    current: "mA",
    voltage: "mV",
};

const units = {
    metric: {
        weight: ["g", "kg", "mt"],
        temperature: ["C"],
        length: ["mm", "cm", "m"],
        pressure: ["bar"],
        volume: ["ml", "l", "m3"],
        energy: ["Wh", "kWh", "MWh"],
        power: ["W", "kW", "MW"],
        current: ["mA", "A", "kA"],
        voltage: ["mV", "V", "kV"]
    },
    imperial: {
        weight: ["oz", "lb", "t"],
        temperature: ["F"],
        length: ["in", "ft", "mi"],
        pressure: ["psi"],
        volume: ["fl-oz", "cup", "gal"],
        energy: ["Wh", "kWh", "MWh"],
        power: ["W", "kW", "MW"],
        current: ["mA", "A", "kA"],
        voltage: ["mV", "V", "kV"]
    }
};

export function getUnit(type, unit = UnitTypes.SMALL, {overrideUnitSystem} = {}) {
    let prefix = "";
    if (type === "temperature") {
        prefix = "°";
    }
    let value = _.get(units, `[${overrideUnitSystem || getUnitSystem()}][${type}][${unit}]`);
    switch (value) {
        case "mt":
            value = "t";
            break;
        case "m3":
            value = "m³";
            break;
        default:
            break;
    }
    return `${prefix}${value}`;
}

/**
 *
 * @param weight - przyjmuje wartosc w gramach?
 * @param unit
 * @param showUnit
 * @param fixed
 * @param defaultValue
 * @param rawValue
 * @param acceptNil
 * @returns {string/number}
 */
export function convertWeightUnitTo(weight, {unit = UnitTypes.SMALL, showUnit = false, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("weight", weight, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function findProperUnitType(value) {
    if (value >= 1000 && value < Math.pow(10, 6)) {
        return UnitTypes.MEDIUM;
    }
    if (value >= Math.pow(10, 6)) {
        return UnitTypes.BIG;
    } else {
        return UnitTypes.SMALL
    }
}

export function convertWeightToBaseUnit(weight, {fromUnit = UnitTypes.SMALL} = {}) {
    let convertFrom = units[getUnitSystem()].weight[fromUnit];
    if (_.isFinite(weight)) {
        return convert(weight).from(convertFrom).to(defaults.weight);
    } else {
        throw new Error(`convertWeightToBaseUnit failed expected number got ${weight}`)
    }
}

export function convertTemperatureUnitTo(temperature, {showUnit = false, unit = UnitTypes.SMALL, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("temperature", temperature, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}


export function convertLengthUnitTo(length, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("length", length, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertVolumeUnitTo(volume, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("volume", volume, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertEnergyUnitTo(energy, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("energy", energy, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertPressureUnitTo(pressure, {showUnit = false, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("pressure", pressure, {showUnit, fixed, defaultValue, rawValue, unit: UnitTypes.SMALL, acceptNil})
}

export function convertPowerUnitTo(power, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("power", power, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertCurrentUnitTo(current, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("current", current, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertVoltageUnitTo(voltage, {showUnit = false, unit = UnitTypes.MEDIUM, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false} = {}) {
    return convertUnitTo("voltage", voltage, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertUnitTo(unitType, value, {showUnit = false, fixed = 2, defaultValue = "-", rawValue = false, acceptNil = false, unit = UnitTypes.SMALL} = {}) {
    let convertTo = units[getUnitSystem()][unitType][unit] || units[getUnitSystem()][unitType][0];
    if (!unitType) {
        throw new Error("convertUnitTo unitType not specified")
    }
    if (rawValue) {
        if (_.isFinite(value)) {
            return +(convert(value).from(defaults[unitType]).to(convertTo).toFixed(fixed));
        } else {
            if (acceptNil) {
                return undefined;
            } else {
                throw new Error(`convert${unitType[0].toUpperCase()}${unitType.substr(1)}UnitTo failed expected number got ${value}`)
            }
        }
    } else {
        return `${_.isFinite(+value) ? convert(+value).from(defaults[unitType]).to(convertTo).toFixed(fixed) : defaultValue}${showUnit ? `${getUnit(unitType, unit)}` : ""}`;
    }
}

export function findCorrectUnitKey(unit) {
    let unitType;
    Object.entries(units).forEach(([key, value]) => Object.entries(value).forEach(([key, value]) => value.forEach(x => {
        if (x === unit) unitType = `${key}`
    })));
    return unitType;
}

