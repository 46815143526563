import {memoize} from "lodash";

export const parseFarmMapFromSVG = memoize((svg) => {
    try {
        let domparser = new window.DOMParser();
        let doc = domparser.parseFromString(svg, "image/svg+xml");
        console.log({x: doc.documentElement});
        return doc && (doc.documentElement instanceof SVGSVGElement) ? doc.documentElement : null;
    } catch (e) {
        return null;
    }

}, (...args) => JSON.stringify(args))
