/********CLIMATE*******/
import store from "../store/store";
import {TreeColors, TreeTypes} from "../constans/deviceTree";
import {DevType, DispenserTypes, FrameTypes, Interfaces} from "../constans/devices";
import moment from "moment";
import {addNotification} from "reapop";
import devicesDB from "../../src/database/devicesDB";
import settingsDB from "../../src/database/settingsDB";
import {get, memoize, isArray, isObject, cloneDeep, isString, isEmpty, isNil} from "lodash"
import {DispenserErrorType} from "../constans/dispenserViewLocalErrors";
import {SettingTypes} from "../constans/settingTypes";
import Gateway from "../beans/devices/Gateway";
import Bridge from "../beans/devices/Bridge";
import Cage from "../beans/devices/Cage";
import Climate from "../beans/devices/Climate";
import DispenserWST from "../beans/devices/DispenserWST";
import DispenserNRF from "../beans/devices/DispenserNRF";
import Scale from "../beans/devices/Scale";
import Device from "../beans/Device";
import BridgeBroadcast from "../beans/devices/BridgeBroadcast";
import BridgeWork from "../beans/devices/BridgeWork";
import BridgeConf from "../beans/devices/BridgeConf";
import DispenserTime from "../beans/devices/DispenserTime";
import DispenserNRFMulti from "../beans/devices/DispenserNRFMulti";
import ThermoEye from "../beans/devices/ThermoEye";
import AntennaRFID from "../beans/devices/AntennaRFID";
import {setShadowLoading} from "../actions/shadowsActions";
import WaterFlowMeter from "../beans/devices/WaterFlowMeter";
import PigletScale from "../beans/devices/PigletScale";
import i18n from "../i18n";
import ElectricityFlowMeter from './../beans/devices/ElectricityFlowMeter';
import ChainFeeding from './../beans/devices/ChainFeeding';
import i18next from "i18next";
import ClimateSK3 from "../beans/devices/ClimateSK3";

const t = (val) => i18n.t(val);

function calculateVent(ventilation) {
    let calculated = 0;
    if (ventilation <= 50) {
        calculated = ventilation * 2;
    }
    if (ventilation > 50) {
        calculated = (ventilation * 3) - 50;
    }
    if (ventilation > 80) {
        calculated = (ventilation * 4) - 130;
    }
    if (ventilation > 105) {
        calculated = (ventilation * 6) - 340;
    }
    if (ventilation > 150) {
        calculated = (ventilation * 10) - 940;
    }
    if (ventilation > 180) {
        calculated = (ventilation * 15) - 1840;
    }
    return calculated;
}

/**
 * Metoda obliczająca procent wentylacji
 * @param ventilation - wartość zwracana ze sterownika
 * @returns {number} - procent wentylacji zgodnie z ustawieniami na sterowniku
 */
export function calculateVentilation(ventilation) {
    let calculated = calculateVent(ventilation);
    if (calculated > 1000) {
        calculated = 1000;
    }
    return calculated / 10;
}

/**
 * Metoda obliczająca ilość masterów
 * @param ventilation - wartość zwracana ze sterownika
 * @returns {number} - ilość masterów zgodnie z ustawieniami na sterowniku
 */
export function calculateMasters(ventilation) {
    let calculated = calculateVent(ventilation);
    return calculated > 1000 ? Math.floor((calculated - 1000) / 200) : 0;
}

/*******DISPENSER**********/

/**
 * Funkcja zwracajaca dane z shadowa na temat danego dozownika
 * @param device - obiekt urzadzenia
 * @param dispenserIndex - numer dozownika [0 - 19]
 * @param shadows - możliwość przekazania aktualnych danych z urządzeń
 * @param curves - aktualne krzywe
 * @returns {*} - obiekt z danymi o numerze krzywej na sterowniku, przesunieciu krzywej, przesunieciu dawki, starcie krzywej. Zawiera obiekt krzywej jezeli zostal znaleziony.
 */
export function getDispenserSettings(device, dispenserIndex, shadows = undefined) {
    if (!shadows) {
        let state = store.getState();
        shadows = state.shadows.shadows;
    }
    if (device) {
        let FarmID = device.FarmID;
        let shadow = shadows.get(device.DevID);
        if (shadow && shadow.dispensers && shadow.dispensers[dispenserIndex]) {
            let curveNumber = shadow.dispensers[dispenserIndex].curveNumber;
            let curveShift = shadow.dispensers[dispenserIndex].curveShift;
            let doseCorrection = shadow.dispensers[dispenserIndex].doseCorrection;
            let curveStart = shadow.dispensers[dispenserIndex].curveStart;
            let efficiency1 = shadow.dispensers[dispenserIndex].foodType1Yield;
            let efficiency2 = shadow.dispensers[dispenserIndex].foodType2Yield;
            let curve = settingsDB.getSettingByIndexAndType(curveNumber - 1, SettingTypes.FEEDING_CURVE, FarmID);
            if (curve) {
                return {
                    curveNumber: curveNumber,
                    curveShift: curveShift,
                    curveStart: curveStart,
                    doseCorrection: doseCorrection,
                    curve: curve,
                    efficiencies: [efficiency1, efficiency2]
                }
            }
            return {
                curveNumber: curveNumber,
                curveShift: curveShift,
                curveStart: curveStart,
                doseCorrection: doseCorrection
            }
        }
    }
}

/**
 * Funkcja zwracajaca dane z shadowa na temat danego dozownika
 * @param device - obiekt urzadzenia
 * @param shadows - możliwość przekazania aktualnych danych z urządzeń
 * @param curves - aktualne krzywe
 * @returns {*} - obiekt z danymi o numerze krzywej na sterowniku, przesunieciu krzywej, przesunieciu dawki, starcie krzywej. Zawiera obiekt krzywej jezeli zostal znaleziony.
 */
export function getDispenserNRFSettings(device, shadows = undefined) {
    if (!shadows) {
        let state = store.getState();
        shadows = state.shadows.shadows;
    }
    if (device) {
        let shadow = shadows.get(device.DevID);
        let FarmID = device.FarmID;
        if (shadow && shadow.standardConfig) {
            let curveNumber = shadow.standardConfig.curveNr;
            let curveShift = shadow.standardConfig.offset;
            let doseCorrection = shadow.standardConfig.percentCorrection;
            let curveStart = shadow.standardConfig.startTime;
            let punishment = shadow.standardConfig.punishment;
            let curve = settingsDB.getSettingByIndexAndType(curveNumber - 1, SettingTypes.FEEDING_CURVE, FarmID);
            let activeStage = shadow.standardConfig.stage;
            if (curve) {
                return {
                    curveNumber: curveNumber,
                    curveShift: curveShift,
                    curveStart: curveStart,
                    doseCorrection: doseCorrection,
                    punishment: punishment,
                    curve: curve,
                    activeStage: activeStage || 0
                }
            }
            return {
                curveNumber: curveNumber,
                curveShift: curveShift,
                curveStart: curveStart,
                punishment: punishment,
                doseCorrection: doseCorrection,
                activeStage: activeStage || 0
            }
        }
    }
}

export function decodeMotorError(error) {
    switch (error) {
        case 0:
            return i18next.t("deviceUtils.noBreakdown");
        case 1:
            return i18next.t("deviceUtils.motorBlockage");
        case 2:
            return i18next.t("deviceUtils.motorCircuitBreak");
        default:
            return i18next.t("deviceUtils.bugNotRecognized", {error: error});
    }
}

export function decodeConfigError(errorIndex) {
    return t(`deviceUtils.dispenserConfigError.${errorIndex}`) ? t(`deviceUtils.dispenserConfigError.${errorIndex}`) : `${t("deviceUtils.bugNotRecognized")} ${errorIndex}`;
}

export function decodeFeedingError(error) {
    switch (error) {
        case 0:
            return i18next.t("deviceUtils.previosFeedingProblem");
        case 1:
            return i18next.t("deviceUtils.motorOverload");
        case 2:
            return i18next.t("deviceUtils.motorCircuitBreak");
        case 3:
            return i18next.t("deviceUtils.noPower");
        case 4:
            return i18next.t("deviceUtils.skipDoseOrder");
        case 5:
            return i18next.t("deviceUtils.skipDoseLock");
        case 6:
            return i18next.t("deviceUtils.skipDoseLockClick");
        case 7:
            return i18next.t("deviceUtils.skipDoseHold");
        default:
            return i18next.t("deviceUtils.bugNotRecognized", {error: error});
    }
}

export function decodeViewError(error) {
    return i18next.t(`deviceUtils.viewErrors.${error}`, {returnObjects: true}) ? i18next.t(`deviceUtils.viewErrors.${error}`) : i18next.t("deviceUtils.bugNotRecognized", {error: error});
}

function filterID(dev) {
    if (dev && dev.DevType === "DI" && dev.Protocol === FrameTypes.NRF) {
        try {
            if (dev.DevID.split("_")[2].length !== 6) {
                return false;
            } else {
                if (dev.DevID.split("_")[2].endsWith('0')) {
                    return false;
                }
            }
        } catch (e) {
            return false;
        }
    }

    if (dev && dev.DevType === "BR") {
        return !dev.DevID.includes("CONF") && !dev.DevID.includes("WORK");
    }
    return true;
}

export function generateDeviceTree(devices, gateway, gwDevices = []) {
    let devicesFiltered = devices.filter(dev => dev.FarmID === gateway.FarmID);
    let gatewayDevices = gwDevices.filter(gwDev => (gwDev.FarmID === gateway.FarmID) && (!devicesFiltered.filter(dev => (dev.DevID === gwDev.DevID) && (gwDev.FarmID === dev.FarmID))[0]) && [DevType.DISPENSER, DevType.SCALE, DevType.CLIMATE, DevType.CAGE, DevType.BRIDGE].includes(gwDev.DevType) && filterID(gwDev));
    //merge both devices
    let allDevices = [...devicesFiltered, ...gatewayDevices];
    if (gateway && gateway.DevID) {
        let tmp = {
            name: gateway.Name,
            nodeID: gateway.DevID,
            device: gateway,
            type: TreeTypes.RASPBERRY,
            children: getChildrenForDevice(allDevices, gateway),
            nodeSvgShape: {
                shapeProps: {
                    fill: TreeColors.GATEWAY,
                    r: 20
                },
            },
        };
        return tmp;
    }
}

function getChildrenForBridge(devices, parent, iface) {
    let tmpDevs = [];
    if (devices) {
        devices.filter(dev => (parent.DevID === dev.ParentID) && (dev.Interface === iface)).map(dev => {
            let tmp = {
                name: !dev._id ? dev.Name : `${dev.DevType} ${dev.DevAdr}`,
                nodeID: dev.DevID,
                type: dev._id ? (dev.DevType === DevType.BRIDGE ? TreeTypes.BRIDGE_GW : TreeTypes.DEVICE_GW) : (dev.DevType === DevType.BRIDGE ? TreeTypes.BRIDGE : TreeTypes.DEVICE),
                children: getChildrenForDevice(devices, dev),
                device: dev,
                nodeSvgShape: {
                    shapeProps: {
                        fill: getTreeType(dev),
                        r: 10
                    },
                },
            };
            tmpDevs.push(tmp);
        });
    }
    return tmpDevs;
}

function getTreeType(device) {
    if (device._id) {
        return TreeColors.NOT_IMPORTED;
    } else {
        let devType = device.DevType;
        switch (devType) {
            case DevType.GATEWAY:
                return TreeColors.GATEWAY;
            case DevType.BRIDGE:
                return TreeColors.BRIDGE;
            default: {
                if (!device.Address || device.Interface < 0) {
                    return TreeColors.ERROR;
                }
                switch (devType) {
                    case DevType.DISPENSER_NRF:
                        return TreeColors.DISPENSER_NRF;
                    case DevType.DISPENSER:
                        return TreeColors.DISPENSER;
                    case DevType.CLIMATE:
                        return TreeColors.CLIMATE;
                    case DevType.CAGE:
                        return TreeColors.CAGE;
                    case DevType.SCALE:
                        return TreeColors.SCALE;
                    default:
                        return TreeColors.ERROR;
                }
            }
        }
    }
}

function getChildrenForDevice(devices, parent) {
    let tmpDevs = [];
    if (devices) {
        if (parent.DevType === DevType.BRIDGE) {
            Object.keys(Interfaces).filter(key => (
                    (key.startsWith("RS485") || key.startsWith("NRF_")) || (!!getChildrenForBridge(devices, parent, Interfaces[key]).length)
                )
            ).map(key => {
                let tmp = {
                    name: key,
                    nodeID: `${parent.DevID}_${key}`,
                    type: TreeTypes.INTERFACE,
                    nodeSvgShape: {
                        shapeProps: {
                            fill: parent.Interfaces[Interfaces[key]] ? TreeColors.INTERFACE : TreeColors.ERROR,
                            r: 10
                        },
                    },
                    iface: Interfaces[key],
                    bridge: parent,
                    children: getChildrenForBridge(devices, parent, Interfaces[key])
                };
                return tmpDevs.push(tmp);
            })
        } else {
            devices.filter(dev => (parent.DevID === dev.ParentID)).map(dev => {
                let tmp = {
                    name: !dev._id ? dev.Name : `${dev.DevType} ${dev.DevAdr}`,
                    nodeID: dev.DevID,
                    nodeSvgShape: {
                        shapeProps: {
                            fill: getTreeType(dev),
                            r: 10
                        },
                    },
                    type: dev._id ? (dev.DevType === DevType.BRIDGE ? TreeTypes.BRIDGE_GW : TreeTypes.DEVICE_GW) : (dev.DevType === DevType.BRIDGE ? TreeTypes.BRIDGE : TreeTypes.DEVICE),
                    children: getChildrenForDevice(devices, dev),
                    device: dev,
                };
                tmpDevs.push(tmp);
            });
        }

    }
    return tmpDevs;
}


export function prettifyDeviceName(device) {
    if (device) {
        return `${Object.entries(DevType).filter(entry => entry[1] === device.DevType)[0][1]} ${device.Name} ${device.Address ? "0x" + device.Address.toString(16).toUpperCase() : ""} ${!!device.ParentID ? '✔' : '✖'}`
    }
    return "Unknown";
}

export function getDispenserTypeForNode(node) {
    if (!node) return;
    const {device: {device}} = node;
    if (device) {
        switch (device.DevType) {
            case DevType.DISPENSER:
                return DispenserTypes.DISPENSER_DTM;
            case DevType.DISPENSER_NRF:
                return DispenserTypes.DISPENSER_NRF;
            default:
                break;
        }
    }
}

export function getDispenserNodeType(nodes = []) {
    if (nodes) {
        let type = getDispenserTypeForNode(nodes[0]);
        nodes.map(node => {
            if (type !== DispenserTypes.ALL) {
                if (type !== getDispenserTypeForNode(node)) {
                    type = DispenserTypes.ALL;
                }
            }
        });
        return type;
    }
}

export function checkIfBroadcast(DevID) {
    try {
        return DevID.split("_")[2].endsWith('0')
    } catch (e) {
        return true;
    }
}


export function getDispenserWSTBeforeDays(offsetBefore, dispenserIndex, data = {}) {
    const result = {got: 0, forageAmount: 0, percentage: 0};
    try {
        let historyDay = data[Object.keys(data)[offsetBefore - 1]];
        if (historyDay) {
            result.got = +get(historyDay, `Food0Consumption[${dispenserIndex}]`, 0) + +get(historyDay, `Food1Consumption[${dispenserIndex}]`, 0);
            result.forageAmount = +get(historyDay, `PlannedConsumption[${dispenserIndex}]`, 0);
            result.percentage = result.forageAmount ? ((result.got * 100) / result.forageAmount) : 0;
            return result;
        }
    } catch (e) {
        return result;
    }
    return result;
}

export function getDispenserNRFBeforeDays(offsetBefore, history = []) {
    let result = {got: 0, forageAmount: 0, percentage: 0};
    let day = moment().startOf("day").subtract(offsetBefore, "days");
    let historyDay = history.filter(item => moment(item.date).format("DD.MM") === day.format("DD.MM"))[0];
    if (historyDay) {
        result.got = historyDay.consumption.reduce((a, b) => a + b, 0); // / 1000;
        result.forageAmount = get(historyDay, "plannedConsumption"); // / 1000;
        result.percentage = result.forageAmount ? ((result.got * 100) / result.forageAmount) : 0;
    }
    return result;
}


export function onGetFullDevStateSuccess(message) {
    if (message) {
        let failed = message.CData.DeviceIDs;
        for (let key in message.CAnsw) {
            failed = failed.filter(DevID => DevID !== key);
        }
        generateFullDevNotif(failed);
        store.dispatch(setShadowLoading({DevID: message.CData.DeviceIDs, status: false}));
    }
}

/**
 * Grupuje urządzenia po gatewayID oraz po ich typie
 * @param elements
 * @returns {*}
 */
export function groupDevicesByGatewayID(elements) {
    if (!elements || !isArray(elements)) {
        return undefined;
    } else {
        try {
            let map = new Map();
            for (const item of elements) {
                let device;
                if (isObject(item)) {
                    device = cloneDeep(item);
                } else {
                    device = devicesDB.getDeviceByID(item);
                }
                if (device && device.GatewayID) {
                    let devices = map.get(device.GatewayID) || {
                        [DevType.CAGE]: [],
                        [DevType.CLIMATE]: [],
                        [DevType.SCALE]: [],
                        [DevType.DISPENSER_NRF]: [],
                        [DevType.DISPENSER]: [],
                        [DevType.DISPENSER_NRF_MULTI]: [],
                        [DevType.BROADCAST]: [],
                        [DevType.WATER_FLOW_METER]: [],
                        [DevType.ELECTRICITY_FLOW_METER]: [],
                        [DevType.CHAIN_FEEDING]: [],
                        [DevType.CLIMATE_SK3]: []

                    };
                    if (devices[device.DevType] && !devices[device.DevType].filter(dev => dev.DevID === device.DevID)[0]) {
                        devices[device.DevType].push(device);
                    }
                    map.set(device.GatewayID, devices);
                }
            }
            return !isEmpty(map) ? map : undefined;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

function generateFullDevNotif(failedIDs = []) {
    if (failedIDs && failedIDs.length) {
        let devicesFailed = failedIDs.map(DevID => {
            let device = devicesDB.getDeviceByID(DevID);
            return device ? `${device.Name}-${device.Address}` : DevID;
        });
        let notifi = {
            title: i18next.t("error"),
            message: i18next.t("popNotifications.connectDeviceFailure", {error: devicesFailed.length > 3 ? devicesFailed[0] + "...+" + (devicesFailed.length - 1) : devicesFailed.join(", ")}),
            status: 'error',
            dismissible: true,
            dismissAfter: 5000,
        };
        store.dispatch(addNotification(notifi));
    }
}

export function onGetFullDevStateFailure(error, message) {
    if (message && message.CData) {
        let sendTo = message.CData.DeviceIDs;
        generateFullDevNotif(sendTo);
        store.dispatch(setShadowLoading({DevID: sendTo, status: false}));
    }
}

export function getInsertion(cage) {
    try {
        return cage.Settings.Insertions.filter(item => !item.Stop).sort((a, b) => b.Start - a.Start)[0]
    } catch (e) {
        console.error(e);
    }
}

/**
 * Function search for device passed as object of a PLACEMENT.Devices list
 * @param DevID
 * @param Adr
 * @returns structure {
 *     device: device Object from lokiJS
 *     type: device type DTM/NRF
 *     dispenser: dispenser object only from DTM device
 *     dispenserIndex: index only for DTM device
 * }
 */
export function getDispenserByPlcmntDevice({DevID, Adr}) {
    let dev = devicesDB.getDeviceByID(DevID);
    if (dev) {
        if (dev.DevType === DevType.DISPENSER) {
            let dispenser = dev.Dispensers && dev.Dispensers.filter((disp, index) => index === Adr);
            return {
                device: dev,
                dispenser: dispenser,
                dispenserIndex: Adr,
                type: DevType.DISPENSER
            }
        }
        if (dev.DevType === DevType.DISPENSER_NRF) {
            return {
                device: dev,
                type: DevType.DISPENSER_NRF
            }
        }
    }
}

export function addDispenserError(errors, code, type = DispenserErrorType.VIEW) {
    let tmp = errors || [];
    if (code === undefined) {
        return tmp;
    }
    !tmp.includes(type + code) && tmp.push(type + code);
    return tmp;
}

export function getAllDispenserErrors(errors, join = true) {
    let er = errors || [];
    let errorsParsed = [];
    er.map(errorCode => {
        let parsed = isObject(errorCode) ? errorCode.code : errorCode;
        if (DispenserErrorType.VIEW + 1000 > parsed && parsed >= DispenserErrorType.VIEW) {
            parsed = decodeViewError(parsed - DispenserErrorType.VIEW);
        } else if (DispenserErrorType.CONFIG + 1000 > parsed && parsed >= DispenserErrorType.CONFIG) {
            parsed = decodeConfigError(parsed - DispenserErrorType.CONFIG);
        } else if (DispenserErrorType.FEEDING + 1000 > parsed && parsed >= DispenserErrorType.FEEDING) {
            parsed = decodeFeedingError(parsed - DispenserErrorType.FEEDING);
        } else if (DispenserErrorType.MOTOR + 1000 > parsed && parsed >= DispenserErrorType.MOTOR) {
            parsed = decodeMotorError(parsed - DispenserErrorType.MOTOR);
        }
        return errorsParsed.push({
            time: isObject(errorCode) ? errorCode.time : null,
            message: parsed
        })
    });
    return join ? errorsParsed.map(error => (`${error.time ? moment(error.time).format() + ": " : ""}${error.message}`)).join(", ") : errorsParsed;
}

export function getDevicesClass(devicesArr) {
    const devsWithClass = [];
    for (let device of devicesArr) {
        devsWithClass.push(getDeviceClass(device));
    }
    return devsWithClass;
}

export function getDeviceClass(device) {
    try {
        switch (device.DevType) {
            case DevType.BRIDGE:
                return new Bridge(device);
            case DevType.CAGE:
                return new Cage(device);
            case DevType.CLIMATE:
                return new Climate(device);
            case DevType.DISPENSER:
                return new DispenserWST(device);
            case DevType.DISPENSER_NRF:
                return new DispenserNRF(device);
            case DevType.GATEWAY:
                return new Gateway(device);
            case DevType.SCALE:
                return new Scale(device);
            case DevType.BROADCAST:
                return new BridgeBroadcast(device);
            case DevType.BRIDGE_WORK:
                return new BridgeWork(device);
            case DevType.BRIDGE_CONF:
                return new BridgeConf(device);
            case DevType.DI_TIME:
                return new DispenserTime(device);
            case DevType.DISPENSER_NRF_MULTI:
                return new DispenserNRFMulti(device);
            case DevType.THERMOEYE:
                return new ThermoEye(device);
            case DevType.ANTENNA_RFID:
                return new AntennaRFID(device);
            case DevType.WATER_FLOW_METER:
                return new WaterFlowMeter(device);
            case DevType.ELECTRICITY_FLOW_METER:
                return new ElectricityFlowMeter(device);
            case DevType.CHAIN_FEEDING:
                return new ChainFeeding(device);
            case DevType.SMALL_CAGE:
                return new PigletScale(device);
            case DevType.CLIMATE_SK3:
                return new ClimateSK3(device);
            default:
                return new Device(device);
        }
    } catch (e) {
        return device;
    }
}

export function cutUselessPrefixes(values, trashPrefixes = ["$loki", "meta", "location", "GatewayID"]) {
    let vals = cloneDeep(values);
    if (values) {
        if (Array.isArray(vals)) {
            vals.map(value => {
                for (let prefix of trashPrefixes) {
                    delete value[prefix];
                }
                return value;
            });
        } else {
            for (let prefix of trashPrefixes) {
                delete vals[prefix];
            }
        }
    }
    return vals;
}

export function getFlatDevices(devices = [], showBasicDevices = true) {
    let devs = [];
    devices.forEach(device => {
        let tmp = {device: device};
        if (DevType.SCALE === device.DevType) {
            let siloses = get(device, "Siloses", []);
            siloses.forEach(silo => {
                if (silo.Active) {
                    devs.push({...tmp, index: +silo.Adr})
                }
            })
        } else if (DevType.DISPENSER === device.DevType) {
            let dispensers = get(device, "Dispensers", []);
            dispensers.forEach(dispenser => {
                if (dispenser.Connected) {
                    devs.push({...tmp, index: +dispenser.Adr})
                }
            })
        } else {
            if (showBasicDevices && [DevType.CAGE, DevType.CLIMATE, DevType.DISPENSER_NRF, DevType.ANTENNA_RFID, DevType.WATER_FLOW_METER, DevType.ELECTRICITY_FLOW_METER, DevType.SMALL_CAGE, DevType.CHAIN_FEEDING].includes(device.DevType)) {
                devs.push(tmp);
            } else if (!showBasicDevices) {
                devs.push(tmp);
            }
        }
    });
    console.log(devs, "getFlatDevices");
    return devs;
}

/**
 * Funkcja sprawdza czy objekt w formie {DevID, Adr} ma juz jakas lokalizacje
 * @param DevID
 * @param Adr
 * @param currentLocationID - aktualna lokalicacja
 * @returns {T[]}
 */
export function checkIfDeviceHasLocation({DevID, Adr}, currentLocationID) {
    let device = devicesDB.getDeviceByID(DevID);
    if (!device) throw new Error(`No device found with given ID ${DevID}`);
    let locations = cloneDeep(device.getLocation()) || [];
    locations = locations.filter(loc => (!currentLocationID || currentLocationID && ((loc.CID || loc.SID || loc.BID || loc.BgID || loc.FarmID) !== currentLocationID)) && (!Adr || (Adr && !!loc.Devices.filter(dev => dev.DevID === DevID && dev.Adr === Adr).length)));
    return locations;
}


export function formatDeviceForPairingSelect(deviceWithAdr, {showDEC = false, showInterface = true, showBridgeName = true} = {}) {
    if (!deviceWithAdr) return undefined;
    const {DevID, Adr} = deviceWithAdr;
    let device = devicesDB.getDeviceByID(DevID);
    if (!device) return undefined;
    let name = device.DevType === DevType.DISPENSER_NRF ? `0x${device.Address.toString(16)}` : device.getSelectName(Adr);
    if (showInterface) {
        try {
            name += ` ${getInterfName(device.Interface)}`;
        } catch (e) {
        }
    }
    if (showBridgeName) {
        try {
            let bridge = devicesDB.getDeviceByID(device.ParentID);
            name += bridge ? ` Br: ${bridge.Name} Adr: ${bridge.Address}` : '';
        } catch (e) {
        }
    }
    return name;
}

export function extractDevIDs(possibleDevices = []) {
    return (isArray(possibleDevices) ? possibleDevices : [possibleDevices]).map(dev => dev.DevID).filter(i => !!i);
}

export function extractDevPlcmnts(possibleDevices = []) {
    const possiblePlcmnts = [];
    (isArray(possibleDevices) ? possibleDevices : [possibleDevices]).forEach(dev => {
        if (isArray(dev.PlcmntID)) {
            dev.PlcmntID.forEach((obj) => possiblePlcmnts.push(obj.PlcmntID));
        } else {
            possiblePlcmnts.push(dev.PlcmntID);
        }
    });
    return possiblePlcmnts.filter((p) => !!p);
}

export function getNumberOfPairsInLocation(devices, placement) {
    let keys = ["Sectors", "Chambers", "Boxes"];
    let amount = 0;
    for (let dev of devices) {
        if (dev.PlcmntID) {
            let id = placement.BgID || placement.SID || placement.CID || placement.BID;
            amount += dev.PlcmntID.filter(item => item.PlcmntID === id).length;
        }
        for (let key of keys) {
            if (placement[key]) {
                for (let plcmnt of placement[key]) {
                    amount += getNumberOfPairsInLocation(devices, plcmnt);
                }
            }
        }
    }
    return amount;
}


export function getInterfaceName(infc) {
    switch (infc) {
        case 5:
            return "L";
        case 6:
            return "R";
        case 7:
            return "C";
        default:
            return get(Object.entries(Interfaces).filter(([k, v]) => v === infc), '[0][0]') || '?';
    }
}

export function formatAsHex(decimal, {padStartZeros = 4} = {}) {
    return `0x${(+decimal).toString(16).toUpperCase().padStart(padStartZeros, '0')}`;
}

export function getDeviceNameByID(devieId) {
    return get(devicesDB.getDeviceByID(devieId), "Name", "?");
}

export const getDeviceLocation = (value) => {
    return value.getLocation().map(l => l.CName || l.BName || l.SName || l.FarmName || l.BoxesName).join(", ");
}

export function manageDeviceHeaders(t) {
    return [
        {
            name: t("location"),
            valueFormatter: getDeviceLocation
        },
        {
            name: t("designation"),
            field: "Name",
            _mobileHeader: true
        },
        {
            name: t("adres"),
            field: "Address",
            valueFormatter: (value) => formatAsHex(value)
        },
        {
            name: t('newSettings.cage.manage.interface'),
            field: "Interface",
            valueFormatter: getInterfaceName
        },
        {
            name: t("newSettings.cage.manage.bridge"),
            field: "ParentID",
            valueFormatter: (value) => getDeviceNameByID(value)
        },
        {
            name: t("newSettings.cage.manage.gateway"),
            field: "GatewayID",
            valueFormatter: (value) => getDeviceNameByID(value)
        }
    ];
}

export function getInterfName(interf) {
    for (let key in Interfaces) {
        if (Interfaces[key] === interf) return key;
    }
};


export function placementDevicesMap(optionalFarmID) {
    let farmId = optionalFarmID || store.getState().location.farm
    let farmDevices = devicesDB.getDevices(farmId);
    let devicesMap = new Map();
    for (let device of farmDevices) {
        if (Array.isArray(device.PlcmntID)) {
            for (let plcmntObj of device.PlcmntID) {
                let devices = devicesMap.get(plcmntObj.PlcmntID) || [];
                if (devices.findIndex(o => o.DevID === device.DevID) === -1) {
                    devices.push(device);
                    devicesMap.set(plcmntObj.PlcmntID, devices);
                }
            }
        }
    }
    return devicesMap;
}

export function createDeviceAddressList(adrString, devType) {
    if (typeof adrString !== "string") adrString = adrString + "";
    adrString = adrString.replace(/ /g, '');
    let address = [];
    for (let adr of adrString.split(",").filter(o => o)) {
        console.log(adr);
        if (adr.includes("-")) {
            let numbers = [Number(adr.split("-")[0]), Number(adr.split("-")[1])];
            address = [...address, ...generateAdrByMinMax(Math.min(...numbers), Math.max(...numbers), devType)];
        } else {
            address = [...address, Number(adr)];
        }
    }
    return [...new Set(address)];
}

function generateAdrByMinMax(min = 0, max = 128, devType = "") {
    let adr = [];
    switch (devType) {
        case DevType.DISPENSER_NRF: {
            let currentAdr = min;
            let group = currentAdr >> 8;
            let sub = currentAdr & 0x00ff;
            do {
                if (sub > 15) {
                    sub = 1;
                    group += 2;
                }
                currentAdr = group << 8 | sub;
                adr.push(currentAdr);
                sub++;

            } while (currentAdr < max && group <= 255);
            break;
        }
        case DevType.CHAIN_FEEDING:
        case DevType.DISPENSER_NRF_MULTI: {
            let minAddress = +min.toString(16).substring(0, 2);
            let maxAddress = +max.toString(16).substring(0, 2);
            for (let i = minAddress; i <= maxAddress; i += 2) {
                if (i % 7 === 0) {
                    i += 2;
                    if (i > maxAddress) break;
                }
                let address = `0x${i}00`;
                adr.push(+address);
            }
            break;
        }
        default: {
            for (let i = min; i <= max; i++) {
                adr.push(i);
            }
        }
    }
    return adr;
}


export function getMultiForDispenser(deviceId) {
    try {
        if (!deviceId) {
            throw new Error("Device ID not provided");
        }
        const dispenserNRF = devicesDB.getDeviceByID(deviceId);
        if (!dispenserNRF || dispenserNRF.DevType !== DevType.DISPENSER_NRF) {
            throw new Error("Device not found or device is not Dispenser NRF");
        }
        const addr = dispenserNRF.Address & 0xFF00;
        const farm = store.getState().location.farm;
        const attr = {
            Address: addr,
            DevType: DevType.DISPENSER_NRF_MULTI,
            ParentID: dispenserNRF.ParentID,
            FarmID: farm
        };
        let devicesFound = devicesDB.getDevicesByAttributes(attr);
        if (devicesFound.length === 1) {
            return devicesFound[0];
        } else {
            throw new Error("Too many devices found for given attributes");
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

/**
 * Zmemoizowana funkcja do sprawdzania czy lista urzadzen posiada dany tyb urzadzenia
 * @param.devices - lista urzadzen
 * @param.deviceType - wyszukiwany rodzaj zwierzęcia
 * @type {function}
 * @returns {boolean}
 */
export const hasDeviceType = memoize((devices, deviceType) => {
    return !!devices.find(device => device.DevType === deviceType);
}, (...args) => JSON.stringify(args));

export const getLatestMetadata = (metadata) => {
    if (isObject(metadata)) {
        const max = Math.max(...Object.values(metadata));
        return isFinite(max) ? max : undefined;
    } else if (isFinite(metadata)) {
        return metadata;
    }
    return undefined;
};

export const getDeviceChamberMap = memoize((buildings = [], devices = []) => {
    const buildingsMap = new Map();
    const devicesMap = new Map();
    buildings.forEach(building => {
        buildingsMap.set(building.BgID, {name: building.BName});
        (building.Sectors || []).forEach(sector => {
            (sector.Chambers || []).forEach(chamber => {
                buildingsMap.set(chamber.CID, {sectorType: sector.SType, name: chamber.CName});
            })
        })
    });
    devices.forEach(device => {
        if (isString(device.PlcmntID)) {
            if (buildingsMap.has(device.PlcmntID)) {
                const [CID, {sectorType, name}] = buildingsMap.entries().find(([chamberId]) => chamberId === device.PlcmntID);
                devicesMap.set(device.DevID, {CID, sectorType, name});
            }
        } else if (isArray(device.PlcmntID)) {
            for (let placement of device.PlcmntID) {
                if (buildingsMap.has(placement.PlcmntID)) {
                    const [CID, {sectorType, name}] = [...buildingsMap.entries()].find(([chamberId]) => chamberId === placement.PlcmntID);
                    devicesMap.set(device.DevID, {CID, sectorType, name});
                    break;
                }
            }
        }
    });
    return devicesMap;
}, (...args) => JSON.stringify(args));


export const aggregatedDataID = ({DevID, index=null}={}) => isNil(index) ? DevID : `${DevID}_${index}`;
