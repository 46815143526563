import moment from "moment";
import {get, isArray, isNumber, memoize} from "lodash";
import settingsDB from "../database/settingsDB";
import i18n from "../i18n";

const _prepareHistoryData = ({lastWeekHistory}) => {
    const t = (val) => i18n.t(val);
    const labels = [];
    const values = [];
    const translate = {
        0: t("today"),
        "-1": t("yesterday")
    };
    const now = moment().startOf("day");
    for (let i = 0; i < 6; i++) {
        let day = lastWeekHistory.find(o => +moment(o.date).startOf("day") === +now.clone().subtract(i, "day"));
        labels.push(translate[`${-i}`] || -i);
        if (day) {
            values.push(day.plannedUsage ? Math.round(((isNumber(day.usage)) ? day.usage * 100 : (isArray(day.usage) ? day.usage : []).reduce((a, b) => a + b, 0) * 100) / day.plannedUsage) : 0);
        } else {
            values.push(0);
        }
    }
    return {labels, values};
};

export const getScheduleDoses = ({scheduleMap = new Map(), scheduleId}) => {
    const schedule = scheduleId ? scheduleMap.get(scheduleId) || settingsDB.getSettingByID(scheduleId, {showDeleted: true}) : null;
    return {
        doses: get(schedule, "SetData.Doses", []).map(dose => ({
        start: moment(dose.Start).format("HH:mm"),
        stop: moment(dose.Stop).format("HH:mm"),
        percentage: `${dose.Percent}%`
        })), isDeleted: !!get(schedule, "DtaDelTime")
    }
};


export const checkCondition = ({curveMap = new Map(), curveId, day = 1, animal}) => {
    if (!animal) {
        return false;
    }
    const curve = curveId ? curveMap.get(curveId) || settingsDB.getSettingByID(curveId) : null;
    const days = get(curve, "SetData.Days", []);
    let counter = 0;
    const curveDayIndex = day - 1;
    for (let i = curveDayIndex; i >= Math.max(curveDayIndex - 14, 0); i--) {
        if (days[i] && days[i].Notify) {
            const now = +moment().endOf("day");
            const notifyDayTime = +(moment(now).startOf("day").subtract(counter, "days"));
            return !animal.ConditionTime || !((now >= animal.ConditionTime) && (animal.ConditionTime >= notifyDayTime));
        }
        counter++;
    }
    return false;
};

export function _getStageName({curveMap = new Map(), curveId, day = 0}) {
    if (!day || !curveId) {
        return null;
    }
    const curve = curveMap.get(curveId) || settingsDB.getSettingByID(curveId);
    const stages = get(curve, "SetData.Stages", []);
    for (let i = stages.length - 1; i >= 0; i--) {
        if (day >= stages[i].StartDay) {
            return stages[i].Name;
        }
    }
    return null;
}


export const getHistoryData = memoize(_prepareHistoryData, (...args) => JSON.stringify(args));
export const getStageName = memoize(_getStageName, (...args) => JSON.stringify(args));
