import {LogTableTypes, LogTableTypesShort} from "../constans/logCodeTypes";

export function getIconClassName(type) {
    let value;
    switch (type) {
        case "codes":
            value = "fa-text";
            break;
        case "date":
            value = "fa-calendar-day";
            break;
        case "user":
            value = "fa-user";
            break;
        case "anls":
        case LogTableTypes.ANIMALS:
        case LogTableTypesShort.ANIMALS:
            value = "fa-pig";
            break;
        case LogTableTypes.DEVICES:
        case LogTableTypesShort.DEVICES:
            value = "fa-microchip";
            break;
        case LogTableTypes.SETTINGS:
        case LogTableTypesShort.SETTINGS:
        case LogTableTypes.GENERAL:
        case LogTableTypesShort.GENERAL:
            value = "fa-cog";
            break;
        case LogTableTypesShort.DICTIONARY:
        case LogTableTypes.DICTIONARY:
            value = "fa-book";
            break;
        case "bugs":
        case LogTableTypes.BUILDINGS:
        case LogTableTypesShort.BUILDINGS:
            value = "fa-farm";
            break;
        case "Medicine":
            value = "fa-pills";
            break;
        case "ControlList":
            value = "fa-list-alt";
            break;
        case "GraftingProgram":
            value = "fa-syringe";
            break;
        case "Ingredient":
            value = "fa-wheat";
            break;
        default:
            value = "";
            break;
    }
    return `fas pr-1 fa-fw ${value}`;
}
