import {isNil, isNumber, startCase, toLower} from "lodash";

/**
 * Funkcja do wrzucania parametrów do stringów, wyszukuje ${numer_argumentu} i zamiania na argumenty[numer_argumentu}
 * Przydatne do tlumaczen np. stringBuildter("Mam {1} lat i na imie {2}", 12, "Paweł") zwroci nam Mam 12 lat i na imie Pawel
 * @param string
 * @returns string
 */
export function stringBuilder(string = "") {
    let result = `${string}`;
    for (let i = 1; i < arguments.length; i++) {
        let itemToBeReplaced = new RegExp(`\\{${i}\\}`, 'g');
        result = result.replace(itemToBeReplaced, arguments[i]);
    }
    return result;
}

/**
 * Funkcja kopiująca przekazany tekst do schowka
 * @param text
 */
export function copyToClipboard(text) {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummy_id");
    document.getElementById("dummy_id").value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}


/**
 * Funkcja sprawdzająca, czy podany tekst jest adresem email
 * @param string
 * @returns boolean
 */

export function validateEmail(string = "") {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(string);
}

export const indexToChar = (index = 0, result = "") => {
    const minCharCode = 65;
    const maxCharCode = 90;
    const len = maxCharCode - minCharCode + 1;
    const charIdx = index % len;
    const divider = Math.floor(index / len);
    result += String.fromCharCode(minCharCode + charIdx);
    if (divider) {
        return indexToChar(divider, result);
    }
    return result;

};

/**
 * Funkcja do sorotowania - localeCompare filho de puta pendecho!11
 * @param o1 {string|null|undefined|number}
 * @param o2 {string|null|undefined|number}
 * @param numeric - trakrować jako string czy jako numer
 * @param ascending
 * @param nonValuesAtEnd - czy nulle undefiny i inne smiecie spadaja na koniec
 */
export const enhancedComparer = (o1, o2, {numeric = false, ascending = true, nonValuesAtEnd = true} = {}) => {
    if (isNil(o1) !== isNil(o2)) return ((nonValuesAtEnd ? isNil(o1) : isNil(o2)) ? 1 : -1);
    if (isNil(o1) && isNil(o2)) return 0;
    return `${ascending ? o1 : o2 || ""}`.trim().localeCompare(`${ascending ? o2 : o1 || ""}`.trim(), undefined, {
        sensitivity: 'base',
        numeric
    });
};


export const toPrettyHex = (number) => {
    return isNumber(number) ? number.toString(16).toUpperCase() : "0x?";
}

export const upperCase = (str = "") => startCase(toLower(str))
