import {ReportErrorType} from "../constans/reportErrorTypes";
import {getBugsnagClient} from "../views/fallback-error-view/FallbackErrorView";
import {hashCRC} from "./CRCUtils";

export function sendReport(errorType = ReportErrorType.NOT_SPECIFIED, err) {
    let client = getBugsnagClient();
    const causedBy = (err && err.toString) ? err.toString() : 'No info available';
    const errCode = `${errorType}-${hashCRC(causedBy)}`;
    client.notify(`Controlled ER CODE: ${errCode}`, {
        metaData: {
            'feedback': {
                causedBy: causedBy,
                stack: (err && err.stack) ? err.stack : 'No info available'
            }
        }
    });
    return errCode;
}