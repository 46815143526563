import animalsDB from "../database/animalsDB";
import {AnimalTypes} from "../constans/animalTypes";
import {EventTypes} from "../constans/eventTypes";
import moment from "moment";
import _ from "lodash";
import {
    getDaysForRepeatedInsemination,
    getTimeFromInseminationToPartuition,
    getTimeOnBirthRoom,
    getTimeOnBirthRoomMommy,
    getTimeOnMatingRoom,
    getTimeOnSowsRoom
} from "./SettingsUtils";
import buildingsDB from "../database/buildingsDB";
import {SectorType} from "../constans/sectorTypes";
import store from "../store/store";
import {convertWeightUnitTo} from "./UnitUtils";
import {UnitTypes} from "../constans/unitTypes";
import {getPigBalance} from "./EventUtils";
import eventsDB from "../database/eventsDB";
import {medicineTypes} from "../constans/medicine";
import {sortAsString, sortDateStrings} from "./SortUtils";
import i18n from "../i18n";
import {controlLists} from "../constans/controlLists";
import i18next from "i18next";

/***************************************************************
 * Metody liczące dane od list kontrolnych
 ***************************************************************/

export function calculateInseminationsControlLists(list, farm) {
    let data = [];
    const {WData: {InseminationTime}} = list;
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    // pobranie zakresu dat dla listy kontrolnej
    let dates = list.getDatesForList();
    for (let animal of animals) {
        // znalezienie inseminacji zwierzecia, dla ktorego sa inseminacje w okresie
        let inseminationBetweenTime = [];
        if (Array.isArray(InseminationTime)) {
            inseminationBetweenTime = animal.events.filter(item => {
                if (item.EvCode !== EventTypes.INSEMINATION) return false;
                let inseminationStart = moment(item.EvTime).add(InseminationTime[0], "days").toDate().getTime();
                let inseminationEnd = moment(item.EvTime).add(InseminationTime[1], "days").toDate().getTime();
                return inseminationStart < dates.end.toDate().getTime() && inseminationEnd > dates.start.toDate().getTime();
            });
        } else {
            inseminationBetweenTime = animal.events.filter(item => {
                if (item.EvCode !== EventTypes.INSEMINATION) return false;
                let eventTime = moment(item.EvTime).add(InseminationTime, "days").toDate().getTime();
                return eventTime > dates.start.toDate().getTime() && eventTime < dates.end.toDate().getTime();
            });
        }

        // zapisanie danych
        for (let insemination of inseminationBetweenTime) {
            data.push({
                animal,
                insemination,
                date: Array.isArray(InseminationTime) ? {
                    start: moment(insemination.EvTime).add(InseminationTime[0], "days"),
                    end: moment(insemination.EvTime).add(InseminationTime[1], "days")
                } : moment(insemination.EvTime).add(InseminationTime, "days")
            })
        }
    }
    // posortowanie danych i zwrócenie bez duplikatow to samo zwierze i data
    data.sort((a, b) => {
        if (Array.isArray(InseminationTime)) return a.date.start - b.date.start;
        return a.date - b.date
    });
    return _.uniqBy(data, elem => [elem.animal.AnmID, moment(elem.insemination.EvTime).format("DD.MM.YYYY")].join());
}

function getDataForanimal(animal, dates, day, eventTypeToCheck) {
    let timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
    let inseminations = animal.events.filter(item => {
        if (item.EvCode !== EventTypes.INSEMINATION) return false;
        let eventTime = moment(item.EvTime).add(day, "days").toDate().getTime();
        return eventTime > dates.start.toDate().getTime() && eventTime < dates.end.toDate().getTime();
    });
    // zapisanie danych
    let insemination = inseminations[0];
    let daysForRepeatedInsemination = getDaysForRepeatedInsemination();
    let repeats = 0;
    for (let ins of inseminations) {
        if (moment(ins.EvTime).diff(insemination.EvTime, "days") > daysForRepeatedInsemination) {
            insemination = ins;
            repeats++;
        }
    }
    if (insemination) {
        let inseminationTime = moment(insemination.EvTime);
        let hadRequiredEvent = !!animal.events.find(event => event.EvCode === eventTypeToCheck && event.EvTime > insemination.EvTime && event.EvTime < new Date().getTime());
        if (!hadRequiredEvent) {
            return {
                animal,
                insemination,
                date: inseminationTime.clone().add(day, "days"),
                parturitionDate: inseminationTime.clone().add(timeFromInseminationToParturition, "days"),
                pregnancyDay: moment().startOf("day").diff(inseminationTime, "days"),
                repeatedInsemination: repeats
            }
        }
    }
}

export function calculateConditionControlLists(list, farm) {
    let data = [];
    const {WData: {ConditionDays}} = list;
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    // pobranie zakresu dat dla listy kontrolnej
    let dates = list.getDatesForList();
    for (let animal of animals) {
        // zznalezienie inseminacji w okresie dla wybranych dni
        for (let conditionDay of ConditionDays) {
            let tmp = getDataForanimal(animal, dates, conditionDay, EventTypes.CONDITION);
            if (tmp) {
                data.push(tmp);
            }
        }
    }
    // posortowanie danych i zwrócenie bez duplikatow to samo zwierze i data
    data.sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());
    return _.uniqBy(data, elem => [elem.animal.AnmID, moment(elem.insemination.EvTime).format("DD.MM.YYYY"), elem.date.format("DD.MM.YYYY")].join());
}

export function calculateUSGControlLists(list, farm) {
    let data = [];
    const {WData: {USGDays}} = list;
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    let dates = list.getDatesForList();
    for (let animal of animals) {
        for (let usgDay of USGDays) {
            let tmp = getDataForanimal(animal, dates, usgDay, EventTypes.USG);
            if (tmp) {
                data.push(tmp);
            }
        }
    }
    // posortowanie danych i zwrócenie bez duplikatow to samo zwierze i data
    data.sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime());
    return _.uniqBy(data, elem => [elem.animal.AnmID, moment(elem.insemination.EvTime).format("DD.MM.YYYY"), elem.date.format("DD.MM.YYYY")].join());
}

export function calculateSowsToInseminationControlLists(list, farm) {
    let data = [];
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    let dates = list.getDatesForList();
    animals = animals.filter(animal => {
        let tree = buildingsDB.getTreeByLocationID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID);
        return tree && tree.sector && tree.sector.SType === SectorType.MATING;
    });
    let dayDiff = dates.end.diff(dates.start, "days");
    for (let i = 0; i < dayDiff + 1; i++) {
        let date = dates.start.clone().add(i, "days").subtract(2, "weeks");
        let animalsWithoutInsemination = animals.filter(animal => {
            let inseminations = animal.events.filter(event => event.EvCode === EventTypes.INSEMINATION);
            inseminations.sort((a, b) => b.EvTime - a.EvTime);
            let lastInsemination = inseminations[0];
            if (!lastInsemination) return true;
            return lastInsemination.EvTime < date.toDate().getTime();
        });
        data = [...data, ...animalsWithoutInsemination.map(animal => {
            let sterilleDays = null;
            let separations = animal.events.filter(event => event.EvCode === EventTypes.SEPARATION);
            separations.sort((a, b) => b.EvTime - a.EvTime);
            let lastSeparation = separations[0];
            if (lastSeparation) {
                sterilleDays = moment().startOf("day").diff(moment(lastSeparation.EvTime).startOf("day"), "days");
            }
            return {
                animal,
                sterilleDays
            }
        })];
    }
    return _.uniqBy(data, elem => elem.animal.AnmID);
}

export function calculateSowsToTransferControlLists(list, farm) {
    let data = [];
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    animals = animals.filter(animal => {
        let tree = buildingsDB.getTreeByLocationID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID);
        return tree && tree.sector && (tree.sector.SType === SectorType.MATING || tree.sector.SType === SectorType.DELIVERY || tree.sector.SType === SectorType.SOWS);
    });
    let dates = list.getDatesForList();
    let timeOnBirthAsMommy = getTimeOnBirthRoomMommy();
    let timeOnBirthRoom = getTimeOnBirthRoom();
    let timeOnMatingRoom = getTimeOnMatingRoom();
    let timeOnSowsRoom = getTimeOnSowsRoom();
    for (let animal of animals) {
        let transfers = animal.events.filter(event => event.EvCode === EventTypes.TRANSFER);
        transfers.sort((a, b) => b.EvTime - a.EvTime);
        let transfer = transfers[0];
        if (transfer || animal.DtaInTime) {
            // czas wprowadzenia lub ostatniego przeniesienia
            let time = transfer ? transfer.EvTime : animal.DtaInTime;
            let tree = buildingsDB.getTreeByLocationID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID);
            // maksymalna liczba dni na sektorze
            let maxDaysOnSector = tree.sector.SType === SectorType.MATING ? timeOnMatingRoom : tree.sector.SType === SectorType.SOWS ? timeOnSowsRoom : (() => {
                // sprawdzenie czy byla mamka w okresie miedzy przeniesieniem a maksymalnym czasem jako mamka na porodowce
                let wasMommy = animal.events.filter(event => event.EvCode === EventTypes.MOMMY && event.EvTime > time && event.EvTime < moment(time).add(timeOnBirthAsMommy, "days").toDate().getTime()).length > 0;
                return wasMommy ? timeOnBirthAsMommy : timeOnBirthRoom;
            })();
            let transferDate = moment(time).add(maxDaysOnSector, "days").startOf("day").toDate().getTime();
            if (transferDate > dates.start.toDate().getTime() && transferDate < dates.end.toDate().getTime()) {
                data.push({
                    animal,
                    transferDate: moment(transferDate),
                    currentSector: tree.sector.SType,
                    nextSector: tree.sector.SType === SectorType.MATING ? SectorType.SOWS : tree.sector.SType === SectorType.SOWS ? SectorType.DELIVERY : SectorType.MATING
                })
            }
        }
    }
    data.sort((a, b) => +a.transferDate - +b.transferDate);
    return data;
}

export function calculatePlannedParturitionsControlLists(list, farm) {
    let data = [];
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    let dates = list.getDatesForList();
    let timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
    for (let animal of animals) {
        let tmp = getDataForanimal(animal, dates, timeFromInseminationToParturition, EventTypes.PARTURITION);
        if (tmp && tmp.pregnancyDay >= 0) {
            data.push(tmp);
        }
    }
    // posortowanie danych i zwrócenie bez duplikatow to samo zwierze i data
    data.sort((a, b) => a.date - b.date);
    return _.uniqBy(data, elem => [elem.animal.AnmID, moment(elem.insemination.EvTime).format("DD.MM.YYYY")].join());
}

function animalHadGrafting(animal, date, medicine, medicineType) {
    return !!animal.events.find(event => {
        let day = moment(date).startOf("day");
        if (medicineType === medicineTypes.VACCINE || medicineType === medicineTypes.STIMULATOR) {
            return event.EvCode === EventTypes.GRAFTING && event.EvData.Medicine === medicine && day.isSame(event.EvTime, "day");
        } else {
            return event.EvCode === EventTypes.GRAFTING && event.EvData.Medicine === medicine && day.isBetween(event.EvData.StartTime, event.EvData.EndTime, null, "[]");
        }
    });
}

function getGraftingProgramForAnimal(graftingPrograms, min, max, medicines, medicineType, dates, animal, weight) {
    let array = [];
    for (let graftingProgram of graftingPrograms) {
        for (let medicineRow of graftingProgram.WData.MedicineList) {
            if (medicineRow.Age > min && medicineRow.Age < max) {
                let diff = medicineRow.Age - min;
                let med = medicines.find(m => m.WordID === medicineRow.Medicine);
                if (med && med.WData.Type === medicineType) {
                    let date = dates.start.clone().startOf("day").add(diff, "days");
                    let hadGrafting = animalHadGrafting(animal, date, med.WordID, medicineType);
                    if (!hadGrafting) {
                        array.push({
                            date,
                            animal,
                            medicine: med,
                            weight
                        })
                    }
                }
            }
        }
    }
    return array;
}

export function calculateTreatmentControlLists(list, farm, animalType, medicineType) {
    let data = [];
    let animals = animalsDB.getAllAnimals(farm, animalType);
    let dates = list.getDatesForList();
    let state = store.getState();
    let medicines = state.dictionary.medicine;
    let graftingPrograms = state.dictionary.graftingPrograms.filter(item => item.WData.AnimalKind === animalType);
    if (animalType !== AnimalTypes.SOW) {
        let animalsWithBirth = animals.filter(item => item.DtaBrthTime);
        for (let animal of animalsWithBirth) {
            let weightings = animal.events.filter(event => event.EvCode === EventTypes.WEIGHTING);
            let weight = weightings.length > 0 ? weightings[0].EvData.Weight : animal.Weight;
            let ageMin = dates.start.clone().utc(false).startOf("day").diff(animal.DtaBrthTime, "days");
            let ageMax = dates.end.clone().utc(false).startOf("day").diff(animal.DtaBrthTime, "days");
            let tmp = getGraftingProgramForAnimal(graftingPrograms, ageMin, ageMax, medicines, medicineType, dates, animal, weight);
            data = [...data, ...tmp];
        }
    }
    for (let animal of animals) {
        let inseminations = animal.events.filter(event => event.EvCode === EventTypes.INSEMINATION);
        let weightings = animal.events.filter(event => event.EvCode === EventTypes.WEIGHTING);
        let weight = weightings.length > 0 ? weightings[0].EvData.Weight : animal.Weight;
        for (let insemination of inseminations) {
            let minDaysAfterInsemination = dates.start.clone().utc(false).startOf("day").diff(insemination.EvTime, "days");
            let maxDaysAfterInsemination = dates.end.clone().utc(false).startOf("day").diff(insemination.EvTime, "days");
            let tmp = getGraftingProgramForAnimal(graftingPrograms, minDaysAfterInsemination, maxDaysAfterInsemination, medicines, medicineType, dates, animal, weight);
            data = [...data, ...tmp];
        }
        let treatments = animal.events.filter(event => event.EvCode === EventTypes.TREATMENT);
        weightings.sort((a, b) => b.EvTime - a.EvTime);
        if (treatments.length > 0) {
            for (let event of treatments) {
                for (let [index, treatment] of _.entries(event.EvData.Treatment)) {
                    let datesInRange = treatment.Dates.filter(date => date > dates.start.toDate().getTime() && date < dates.end.toDate().getTime());
                    for (let date of datesInRange) {
                        for (let medicine of treatment.Medicine) {
                            let med = medicines.find(m => m.WordID === medicine);
                            if (med && med.WData.Type === medicineType) {
                                let hadGrafting = animalHadGrafting(animal, date, medicine, medicineType);
                                if (!hadGrafting) {
                                    data.push({
                                        animal,
                                        date: moment(date),
                                        medicine: med,
                                        weight: weightings.length > 0 ? weightings[0].EvData.Weight : animal.Weight,
                                        lastWeighting: weightings[0],
                                        event,
                                        treatmentIndex: index
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    data.sort((a, b) => a.date - b.date);
    return _.uniqBy(data, elem => [elem.animal.AnmID, moment(elem.date).format("DD.MM.YYYY"), elem.medicine ? JSON.stringify(elem.medicine) : "noMed"].join());
}

export function calculateRemanentControlList(list, farm) {
    let data = [];
    let animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW);
    let dates = list.getDatesForList();
    const {WData: {DefaultActiveNipples}} = list;
    for (let animal of animals) {
        let events = animal.events.filter(event => event.EvTime > dates.start.toDate().getTime() && event.EvTime < dates.end.toDate().getTime());
        let parturition = events.find(event => event.EvCode === EventTypes.PARTURITION);
        if (parturition) {
            let alive = getPigBalance(events);
            let activeNipples = eventsDB.getActiveNipples(animal.AnmID);
            if (alive > (activeNipples || DefaultActiveNipples)) {
                data.push({
                    animal,
                    parturition,
                    alive,
                    nipples: activeNipples || DefaultActiveNipples
                })
            }
        }
    }
    console.log(data);
    data.sort((a, b) => a.parturition.EvTime - b.parturition.EvTime);
    return _.uniqBy(data, elem => elem.animal.AnmID);
}

/***************************************************************
 * Formatery danych
 ***************************************************************/

export const animalLocationValueFormatter = value => {
    let location = animalsDB.getAnimalLocationsByPlcmntID(value);
    if (location.length > 0) return location[0].name;
};

const formatDate = value => {
    if (!value) return null;
    if (value.start) {
        return `${moment(value.start).format("DD.MM.YYYY")} - ${moment(value.end).format("DD.MM.YYYY")}`;
    }
    return moment(value).format("DD.MM.YYYY");
};

export const formatInseminationDate = object => {
    const {insemination, repeatedInsemination} = object;
    if (insemination) {
        let text = moment(insemination.EvTime).format("DD.MM.YYYY");
        if (repeatedInsemination) {
            text += ` [${i18n.t("inseminationRepeat", {repeat: repeatedInsemination})}]`;
        }
        return text;
    }
}

export const formatSectorType = value => {
    return i18n.t("SType", {returnObjects: true})[value - 1];
};

export const formatMedicine = value => {
    if (value) {
        return value.WData.Name;
    }
};

export const formatDose = value => {
    if (value) {
        return `${value.WData.Dose} ${value.WData.Unit}`;
    }
};

export const formatWeight = value => {
    if (value) return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true});
};

/***************************************************************
 * Metody pobierające headery
 ***************************************************************/

export const emptyHeader = {
    name: "",
    headerClassName: "d-none empty",
    itemClassName: "d-none empty",
    field: "null"
}

export const checkHeader = {
    name: "\u2713",
    headerClassName: "d-none",
    itemClassName: "d-none",
    field: "null"
};

export function getInseminationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("inseminationDate"),
            field: "insemination.EvTime",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("examinationDate"),
            field: "date",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getConditionHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("inseminationDate"),
            field: "insemination.EvTime",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay"
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("examinationDate"),
            field: "date",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getUSGHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("inseminationDate"),
            field: "insemination.EvTime",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay"
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("usgDate"),
            field: "date",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getSowsToInseminationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("idle"),
            field: "sterilleDays"
        },
    ]
}

export function getSowsToTransferHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("currentSector"),
            field: "currentSector",
            valueFormatter: formatSectorType
        },
        {
            name: i18n.t("nextSector"),
            field: "nextSector",
            valueFormatter: formatSectorType
        },
        {
            name: i18n.t("transferDate"),
            field: "transferDate",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getPlannedParturitionHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("inseminationDate"),
            valueFormatter: formatInseminationDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay"
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getTreatmentHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("weight"),
            field: "weight",
            valueFormatter: formatWeight
        },
        {
            name: i18n.t("lastWeighting"),
            field: "lastWeighting.EvTime",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("medicine"),
            field: "medicine",
            valueFormatter: formatMedicine
        },
        {
            name: i18n.t("dosage"),
            field: "medicine",
            valueFormatter: formatDose
        },
        {
            name: i18n.t("date"),
            field: "date",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        }
    ];
}

export function getRemanentHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "animal.AnmNo1",
            customSort: sortAsString
        },
        {
            name: i18n.t("location"),
            field: "animal.PlcmntID",
            valueFormatter: animalLocationValueFormatter
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturition.EvTime",
            valueFormatter: formatDate,
            customSort: sortDateStrings
        },
        {
            name: i18n.t("alivePiglets"),
            field: "alive"
        },
        {
            name: i18n.t("activeNipples"),
            field: "nipples"
        }
    ];
}

export const getControlListName = (value) => {
    switch (value){
        case controlLists.INSEMINATIONS:
            return i18next.t("controlLists.mainView.sowsForRepeatedInsemination");
        case controlLists.CONDITION:
            return i18next.t("condition");
        case controlLists.USG:
            return i18next.t("usgTest");
        case controlLists.SOWS_TO_INSEMINATION:
            return i18next.t("controlLists.mainView.sowsToInseminationTitle");
        case controlLists.SOWS_TO_TRANSFER:
            return i18next.t("controlLists.mainView.sowsToTransferTitle");
        case controlLists.PLANNED_PARTURITIONS:
            return i18next.t("controlLists.mainView.plannedParturitionsTitle");
        case controlLists.SOWS_TO_GRAFTING:
            return i18next.t("controlLists.mainView.sowsToGraftingTitle");
        case controlLists.BOARS_TO_GRAFRING:
            return i18next.t("controlLists.mainView.boarsToGraftingTitle");
        case controlLists.PORKER_TO_GRAFTING:
            return i18next.t("controlLists.mainView.porkerToGraftingTitle");
        case controlLists.PIGLET_TO_GRAFTING:
            return i18next.t("controlLists.mainView.pigletToGraftingTitle");
        case controlLists.SOWS_FORAGE:
            return i18next.t("controlLists.mainView.sowsForageTitle");
        case controlLists.BOARS_FORAGE:
            return i18next.t("controlLists.mainView.boarsForageTitle");
        case controlLists.PORKER_FORAGE:
            return i18next.t("controlLists.mainView.porkerForageTitle");
        case controlLists.PIGLET_FORAGE:
            return i18next.t("controlLists.mainView.pigletForageTitle");
        case controlLists.SOWS_DOSATRON:
            return i18next.t("controlLists.mainView.sowsDosatronTitle");
        case controlLists.BOARS_DOSATRON:
            return i18next.t("controlLists.mainView.boarsDosatronTitle");
        case controlLists.PORKER_DOSATRON:
            return i18next.t("controlLists.mainView.porkerDosatronTitle");
        case controlLists.PIGLET_DOSATRON:
            return i18next.t("controlLists.mainView.pigletDosatronTitle");
        case controlLists.SOWS_STIMULATOR:
            return i18next.t("controlLists.mainView.sowsStimulatorTitle");
        case controlLists.REMANENT:
            return i18next.t("controlLists.mainView.remanentTitle");
        default:
            return "";
    }
}
