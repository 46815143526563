import {EventTypes} from "../constans/eventTypes";
import store from "../store/store";
import _ from "lodash";
import animalsDB from "../database/animalsDB";
import moment from "moment";
import {isTimestampInRange} from "./DateTimeUtils";
import {getTimeFromInseminationToPartuition} from "./SettingsUtils";
import {convertRowsToCycles, preEvents} from "./AnimalDocumentsUtils";
import i18next from "i18next";

export function checkIsInsemination(events, i) {
    events = events.filter(item => [EventTypes.FALL, EventTypes.NO_PREGNANCY, EventTypes.SELL, EventTypes.USG, EventTypes.INSEMINATION].includes(item.EvCode));
    if (i < events.length && (
        events[i].EvCode === EventTypes.FALL ||
        events[i].EvCode === EventTypes.NO_PREGNANCY ||
        events[i].EvCode === EventTypes.SELL ||
        (events[i].EvCode === EventTypes.USG && events[i].EvData.Pregnant === false)
    )) {
        return null;
    }
    if (i < events.length && events[i].EvCode !== EventTypes.INSEMINATION) {
        const x = i + 1;
        return checkIsInsemination(events, x);
    } else {
        events = events.filter(item => item.EvCode === EventTypes.INSEMINATION).sort((a, b) => a.EvTime - b.EvTime);
        return events[0] || null;
    }
}

export function sortStringArray(inseminators) {
    return inseminators.sort((a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    });
}

function getInseminatorsList(inseminations) {
    let ins = [];
    for (let insemination of inseminations) {
        let inseminatorID = _.get(insemination, "EvData.OperID");
        if (inseminatorID) ins.push(inseminatorID);
    }
    return ins;
}

/**
 * Funkcja dziala na obiekcie data i zwieksza odpowiednie liczniki
 * @param allEvents - wszystkie eventy dla zwierzecia
 * @param startTimestamp
 * @param endTimestamp
 * @param data
 * @returns {*}
 */
export function passedUSG(allEvents, startTimestamp, endTimestamp, data) {
    //funkcja iterujaca po kazdym zwierzaku
    let R = preEvents(allEvents);
    let resultTable = _.get(R, "resultTable", []);

    if (!_.isEmpty(resultTable)) {
        for (let [idx, row] of resultTable.entries()) {
            if (!_.isEmpty(row[EventTypes.INSEMINATION])) {
                let lastAnmInseminator = _.findIndex(data, (dtaRow) => !_.isArray(dtaRow.inseminator) && _.isEqual(_.last(row[EventTypes.INSEMINATION]).EvData.OperID, dtaRow.inseminator));
                let lastIndex = idx === resultTable.length - 1;
                if (idx === 1 && lastIndex) {
                    if (row[EventTypes.INSEMINATION].length === 1) {
                        data[lastAnmInseminator].pendingCnt++;
                    } else {
                        let inseminations = row[EventTypes.INSEMINATION];
                        let inseminatorsList = getInseminatorsList(inseminations);
                        let inseminators = [...(new Set(inseminatorsList))];
                        let foundIndex = _.findIndex(data, (item) => _.isEqual((_.isArray(item.inseminator) ? item.inseminator : [item.inseminator]), inseminators));
                        if (foundIndex === -1) {
                            foundIndex = data.push({
                                inseminator: inseminators,
                                inseminationsCnt: 0,
                                pendingCnt: 0,
                                wellInseminatedCnt: 0,
                                wrongInseminatedCnt: 0
                            }) - 1;
                        }
                        data[foundIndex].inseminationsCnt = _.sumBy(data, (item) => (_.isFinite(item.inseminationsCnt) && !_.isArray(item.inseminator) && inseminators.includes(item.inseminator)) ? +item.inseminationsCnt : 0);
                        data[foundIndex].pendingCnt++;
                    }
                } else {
                    let nextCycle = resultTable[idx + 1];
                    if (!!nextCycle) {
                        if (
                            _.isEqual(nextCycle.cycle, row.cycle) && !_.isEmpty(row[EventTypes.INSEMINATION]) &&
                            _.isEmpty(row[EventTypes.USG]) && _.isEmpty(row[EventTypes.PARTURITION]) &&
                            _.isEmpty(row[EventTypes.SEPARATION_TO_MOMMY]) && _.isEmpty(row[EventTypes.SEPARATION]) &&
                            _.isEmpty(row[EventTypes.SEPARATION])) {
                            if (row[EventTypes.INSEMINATION].length === 1) {
                                data[lastAnmInseminator].wrongInseminatedCnt++;
                                data[lastAnmInseminator].pendingCnt -= data[lastAnmInseminator].pendingCnt ? 1 : 0;
                            } else {
                                let inseminations = row[EventTypes.INSEMINATION];
                                let inseminatorsList = getInseminatorsList(inseminations);
                                let inseminators = [...(new Set(inseminatorsList))];
                                let foundIndex = _.findIndex(data, (item) => _.isEqual((_.isArray(item.inseminator) ? item.inseminator : [item.inseminator]), inseminators));
                                if (foundIndex === -1) {
                                    foundIndex = data.push({
                                        inseminator: inseminators,
                                        inseminationsCnt: 0,
                                        pendingCnt: 0,
                                        wellInseminatedCnt: 0,
                                        wrongInseminatedCnt: 0
                                    }) - 1;
                                }
                                data[foundIndex].inseminationsCnt = _.sumBy(data, (item) => (foundIndex && _.isFinite(item.inseminationsCnt) && !_.isArray(item.inseminator) && inseminators.includes(item.inseminator)) ? +item.inseminationsCnt : 0);
                                data[foundIndex].wrongInseminatedCnt++;
                                data[foundIndex].pendingCnt -= data[foundIndex].pendingCnt ? 1 : 0;
                            }
                        }
                    } else {
                        if (row[EventTypes.INSEMINATION].length === 1) {
                            data[lastAnmInseminator].pendingCnt++;
                        } else {
                            let inseminations = row[EventTypes.INSEMINATION];
                            let inseminatorsList = getInseminatorsList(inseminations);
                            let inseminators = [...(new Set(inseminatorsList))];
                            let foundIndex = _.findIndex(data, (item) => _.isEqual((_.isArray(item.inseminator) ? item.inseminator : [item.inseminator]), inseminators));
                            if (foundIndex === -1) {
                                foundIndex = data.push({
                                    inseminator: inseminators,
                                    inseminationsCnt: 0,
                                    pendingCnt: 0,
                                    wellInseminatedCnt: 0,
                                    wrongInseminatedCnt: 0
                                }) - 1;
                            }
                            data[foundIndex].inseminationsCnt = _.sumBy(data, (item) => (_.isFinite(item.inseminationsCnt) && !_.isArray(item.inseminator) && inseminators.includes(item.inseminator)) ? +item.inseminationsCnt : 0);
                            data[foundIndex].pendingCnt++;
                        }
                    }
                }

                let inseminator = lastAnmInseminator;
                if (row[EventTypes.INSEMINATION].length > 1) {
                    let inseminations = row[EventTypes.INSEMINATION];
                    let inseminatorsList = getInseminatorsList(inseminations);
                    let inseminators = [...(new Set(inseminatorsList))];
                    inseminator = _.findIndex(data, (item) => _.isEqual((_.isArray(item.inseminator) ? item.inseminator : [item.inseminator]), inseminators));
                }

                if (!_.isEmpty(row[EventTypes.USG])) {
                    let lastUSG = _.last(row[EventTypes.USG]);
                    if (lastUSG.EvData.Pregnant) {
                        data[inseminator].wellInseminatedCnt++;
                        data[inseminator].pendingCnt -= data[inseminator].pendingCnt ? 1 : 0;
                    } else {
                        data[inseminator].wrongInseminatedCnt++;
                        data[inseminator].pendingCnt -= data[inseminator].pendingCnt ? 1 : 0;
                    }
                } else if (
                    !_.isEmpty(row[EventTypes.PARTURITION]) ||
                    !_.isEmpty(row[EventTypes.FALL_PIGLETS]) ||
                    !_.isEmpty(row[EventTypes.SEPARATION_TO_MOMMY]) ||
                    !_.isEmpty(row[EventTypes.SEPARATION])) {
                    data[inseminator].wellInseminatedCnt++;
                    data[inseminator].pendingCnt -= data[inseminator].pendingCnt ? 1 : 0;
                } else if (!_.isEmpty(row[EventTypes.NO_PREGNANCY])) {
                    data[inseminator].wrongInseminatedCnt++;
                    data[inseminator].pendingCnt -= data[inseminator].pendingCnt ? 1 : 0;
                }
            }
        }
    }
    return data;
}

/**
 *
 * @param allEvents
 * @param i
 * @param daysBetweenInseminations
 * @param startDate
 * @param endDate
 * @param data
 * @returns {*}
 */
export function calculateInseminatedAnimals(allEvents, i, daysBetweenInseminations, startDate, endDate, data) {
    let events = allEvents.filter(item => isTimestampInRange(startDate, endDate, item.EvTime));
    if (i < events.length && [EventTypes.FALL, EventTypes.NO_PREGNANCY, EventTypes.SELECTION].includes(events[i].EvCode)) {
        return data;
    }
    if (i < events.length && events[i].EvCode !== EventTypes.INSEMINATION) {
        return calculateInseminatedAnimals(allEvents, i + 1, daysBetweenInseminations, startDate, endDate, data);
    } else {
        //obsluga kejsa jesli pomiedzy inseminacje wkradnie sie inny event.. pytanie - czy taka sytuacja jest realna
        let max = null;
        for (let j = i; j < (i + 3); j++) {
            let event = events[j];
            let nextEvent = events[j + 1];
            if (event && event.EvCode === EventTypes.INSEMINATION) {
                max = j;
            } else {
                break;
            }
            if (!nextEvent || (moment(event.EvTime).startOf("day").diff(moment(nextEvent.EvTime).startOf("day"), "days") > daysBetweenInseminations)) {
                break
            }
        }
        return max !== null ? passedUSG(allEvents, events[max].EvTime, endDate, data) : data;
    }
}

/**
 * Dni jałowe - czas liczony od daty ostatniej separacji do inseminacji ktora wystapila po niej.
 * Przypadki:
 * 1. Jesli po separacji wystapi inseminacja wyliczamy ile minelo dni
 * 2. Jesli po separacji nie wystapila inseminacja liczymy do dnia dzisiejszego
 * 3. Jesli nie wystapila separacja (przypadek starych swin bo teraz obowiazkowo wpisujemy date ostatniej sepracji) to 0
 * @param events - array
 * @returns {{wasMommy: number, repetition: number, idleDays: number}}
 */
export function countIdleDays(events) {
    const cycleTable = preEvents(events).cycleTable || [];
    const converted = convertRowsToCycles(cycleTable);
    let repetitions = 0;
    let wasMommy = 0;
    let idleDays = 0;
    let lastSeparationTime = 0;
    let lastInseminationTime = 0;
    for (let rowRepetition of Object.values(_.countBy(cycleTable.filter(row => !_.isEmpty(row[EventTypes.INSEMINATION])), 'cycle'))) {
        if (rowRepetition > 1) repetitions += (rowRepetition - 1);
    }
    for (let cycle of converted) {
        wasMommy += cycle[EventTypes.MOMMY].length;
    }
    events = events.filter(event => [EventTypes.INSEMINATION, EventTypes.SEPARATION, EventTypes.SOW_CYCLES].includes(event.EvCode));
    if (!_.isEmpty(events)) {
        let separations = events.filter(e => [EventTypes.SEPARATION, EventTypes.SOW_CYCLES].includes(e.EvCode));
        let inseminations = events.filter(e => e.EvCode === EventTypes.INSEMINATION);

        lastSeparationTime = !_.isEmpty(separations) ? Math.max.apply(Math, separations.map((e) =>
            e.EvCode === EventTypes.SOW_CYCLES ? +e.EvData.LastSeparation : e.EvTime ? +e.EvTime : 0)) : 0;

        lastInseminationTime = !_.isEmpty(inseminations) ? Math.max.apply(Math, inseminations.map((e) =>
            e.EvTime ? +e.EvTime : 0)) : 0;
    }

    //jesli nie ma inseminacji ale jest separacja
    if (!lastInseminationTime && lastSeparationTime) {
        idleDays = moment().startOf('day').diff(moment(lastSeparationTime), 'days');
    } else if (lastInseminationTime && lastSeparationTime) {
        //jesli wystapila inseminacja po separacji
        if (lastSeparationTime <= lastInseminationTime) {
            idleDays = moment(lastInseminationTime).startOf('day').diff(moment(lastSeparationTime), 'days');
        }
        //jesli wystapila separacja po inseminacji ale nie ma inseminacji to licze do dzisiejszej daty
        if (lastSeparationTime > lastInseminationTime) {
            idleDays = moment().startOf('day').diff(moment(lastSeparationTime), 'days');
        }
    }

    return {
        wasMommy: wasMommy,
        repetition: repetitions,
        idleDays: idleDays
    }
}

export function getTimeToPartuition() {
    let time;
    try {
        time = store.getState().settings.general.SetData.Settings.Cycle.TimeFromInseminationToPartuition
    } catch (e) {
        console.error(e);
    }
    //console.log("TIME", time);
    return time ? time : getTimeFromInseminationToPartuition()
}


export function getAnimalNo1ByAnmID(anmID) {
    return _.get(animalsDB.getAnimalById(anmID), "AnmNo1", "?")
}

export function getAnimalTypeTranslation(animalType) {
    const animalKind = i18next.t("animalKind", {returnObjects: true});
    let kind = (animalKind[animalType] || "?");
    return kind[0].toUpperCase() + kind.slice(1);
}

export function getDateString(timestamp, {format = undefined} = {}) {
    return moment(timestamp).format(format)
}

export function getBuyerNameByID(buyerID) {
    const {dictionary: {clients}, language: {locale}} = store.getState();
    return _.get(_.get(clients, `WData[${locale}]`, []).filter((emp) => emp.ID === buyerID), "[0].Value", "?")
}

export function getSelectionReasonByID(selectionID) {
    const {dictionary: {selectionReason}, language: {locale}} = store.getState();
    return _.get(_.get(selectionReason, `WData[${locale}]`, []).filter((emp) => emp.ID === selectionID), "[0].Value", "?")
}

export function getGraftingReasonByID(reasonID) {
    const {dictionary: {graftingReason}, language: {locale}} = store.getState();
    return _.get(_.get(graftingReason, `WData[${locale}]`, []).filter((emp) => emp.ID === reasonID), "[0].Value", "?")
}

export function getMedicineNameByMedicineID(medicineID) {
    const {dictionary: {medicine}} = store.getState();
    console.log(medicine);
    return _.get(medicine.filter((emp) => emp.WordID === medicineID), "[0].WData.Name", "?")
}

export function getTreatmentTypeByIndex(index) {
    const treatmentTypes = i18next.t("grid.treatmentTypes", {returnObjects: true});
    let treatment = "?";
    switch (+index) {
        case 1: {
            treatment = treatmentTypes.grafting || treatment;
            break;
        }
        case 2: {
            treatment = treatmentTypes.medicatedFeed || treatment;
            break;
        }
        case 3: {
            treatment = treatmentTypes.dosatron || treatment;
            break;
        }
        case 4: {
            treatment = treatmentTypes.stimulator || treatment;
            break;
        }
        default: {
            treatment = "?"//params.value
        }
    }
    return treatment[0].toUpperCase() + treatment.slice(1);
}

export function getMedicineDoseByMedicineID({dose, unit}) {
//    console.log("dose:", dose, "unit:", unit);
    if (dose && unit) {
        return `${dose} ${unit}`.trim();
    }
    return "-"
}

export function getLocationPathByPlcmntID(plcmntID) {
    let locations = animalsDB.getAnimalLocationsByPlcmntID(plcmntID);
    return locations.length ? locations.map(loc => _.get(loc, "name", "")).join(", ") || "?" : "-";
}

export function getOperatorName(OperID) {
    const {user: {employees}} = store.getState();
    return _.get((employees || []).find((o) => o.LocalUserID === OperID), "userName", OperID.startsWith("ServiceWesstron") ? OperID : "?");
}

export function getPinnedRowSum(data = [], key = "", formatter = (value) => {
    return value
}) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += formatter(+_.get(data[i], key, 0));
    }
    return sum;
}

export function getPinnedRowAvg(data = [], key = "", formatter = (value) => {
    return value
}) {
    let sum = 0;
    let counter = 0;
    for (let i = 0; i < data.length; i++) {
        sum += formatter(+_.get(data[i], key, 0));
        counter += _.isNil(_.get(data[i], key)) ? 0 : 1;
    }
    return counter ? sum / counter : 0;
}