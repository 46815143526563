import store from "../store/store";
import moment from "moment";
import {SettingTypes} from "../constans/settingTypes";
import General from "../beans/settings/General";
import Curve from "../beans/settings/Curve";
import Forage from "../beans/settings/Forage";
import Schedule from "../beans/settings/Schedule";
import Layout from "../beans/settings/Layout";
import ARNEndpoint from "../beans/settings/ARNEndpoint";
import Economy from "../beans/settings/Economy";
import Plans from "../beans/settings/Plans";
import FarmMap from "../beans/settings/FarmMap";
import Setting from "../beans/Setting";
import Notification from "../beans/Notification";
import ClimateCurve from "../beans/settings/ClimateCurve";
import {UnitTypes} from "../constans/unitTypes";
import _ from "lodash";
import DeviceSetting from "../beans/settings/DeviceSetting";
import SystemScheduleWorktype from "../beans/settings/SystemScheduleWorktype";
import SystemSchedule from "../beans/settings/SystemSchedule";
import ClimateCurveSK3 from "../beans/settings/ClimateCurveSK3";

//system - units
export function getUnitSystem() {
    let defaultValue = "metric";
    try {
        return store.getState().settings.general.SetData.Settings.System.UnitSystem || defaultValue
    } catch (e) {
        return defaultValue;
    }
}

export function getFeedingUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return _.get(store.getState(), "settings.general.SetData.Settings.System.FeedingUnit", defaultValue)
    } catch (e) {
        return defaultValue;
    }
}

export function getAnimalUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return _.get(store.getState(), "settings.general.SetData.Settings.System.AnimalUnit", defaultValue)
    } catch (e) {
        return defaultValue;
    }
}

export function getScaleUnit() {
    let defaultValue = UnitTypes.BIG;
    try {
        return _.get(store.getState(), "settings.general.SetData.Settings.System.ScaleUnit", defaultValue)
    } catch (e) {
        return defaultValue;
    }
}


export function getCageUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return _.get(store.getState(), "settings.general.SetData.Settings.System.CageUnit", defaultValue)
    } catch (e) {
        return defaultValue;
    }
}

export function getTechnologyGroupWeeks() {
    let defaultValue = 1;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.NumberOfWeeksInCycle || defaultValue
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeFromInseminationToPregnancy() {
    let defaultValue = 28;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeFromInseminationToPregnancy || defaultValue
    } catch (e) {
        return defaultValue;
    }
}

export function getFarmStart() {
    try {
        let state = store.getState();
        let general = state.settings.general;
        return moment(general.SetData.Settings.Result.Time).startOf("isoWeek")
    } catch (e) {
        return moment().startOf("year");
    }
}

export function getResultsWeeks() {
    try {
        let state = store.getState();
        let general = state.settings.general;
        return general.SetData.Settings.Result.Days;
    } catch (e) {
        return 1;
    }
}

export function getTreatmentHour() {
    try {
        let state = store.getState();
        let notifications = state.settings.notifications;
        return notifications.SetData.TreatmentHour || "08:00";
    } catch (e) {
        return "08:00";
    }
}

export function getDaysForRepeatedInsemination() {
    let defaultValue = 5;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeBetweenInseminations || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeFromInseminationToPartuition() {
    let defaultValue = 114;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeFromInseminationToPartuition || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMaxDelayForBirth() {
    let defaultValue = 5;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.MaxPartuitionDelay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnMatingRoom() {
    let defaultValue = 15;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnMatingRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnSowsRoom() {
    let defaultValue = 100;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnSowsRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnBirthRoom() {
    let defaultValue = 28;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnBirthRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnBirthRoomMommy() {
    let defaultValue = 35;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnBirthRoomMommy || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPassageAccuracy() {
    let defaultValue = 0.5;
    try {
        return store.getState().settings.general.SetData.Settings.Cage.Passage || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getShowAliases() {
    let defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.Devices.ShowAliases || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getOffline() {
    let defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.Devices.Offline || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getRFIDTagReader() {
    let defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.Devices.RFIDReader || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMinAgeForFirstMating() {
    const defaultValue = 240;//dni
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FirstMatingMinAge || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMinWeightForFirstMating() {
    const defaultValue = 140 * 1000;//g
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FirstMatingMinWeight || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPreparationPeriodForMating() {
    const defaultValue = 3;//tyg
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.MatingPreparationPeriod || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function isUsingFakeData() {
    if (document.location.href.split("/")[3] === "CypressFarm1") {
        console.log("Fake!!!!!!!!!");
        return true;
    }
    return process.env.REACT_APP_STAGE === "demo";
}

export function getNumberOfDaysToSale() {
    const defaultValue = 100;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.NumberOfDaysToSale || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * funkcja zwracająca rozmiar grupy technologicznej (ilosc kojcow na porodowce)
 * @returns {number}
 */
export function getTechnologyGroupSize() {
    const defaultValue = 200;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TechnologyGroupSize || defaultValue;
    } catch (e) {
        return defaultValue;
    }

}

export function getFirstTechnologyGroupStart() {
    // pierwszy poniedzialek roku
    const defaultValue = moment.utc().startOf("year").isoWeekday(1);
    if (defaultValue.year() !== moment().year()) {
        defaultValue.isoWeekday(8);
    }
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FirstTechnologyGroup || defaultValue.toDate().getTime();
    } catch (e) {
        return defaultValue;
    }

}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultInseminationCurveNumber(type = "WST") {
    const defaultValue = 0;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultInseminationCurveNumber || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultParturitionCurveNumber(type = "WST") {
    const defaultValue = 0;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultParturitionCurveNumber || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultInseminationCurveDay(type = "WST") {
    const defaultValue = 1;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultInseminationCurveDay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultParturitionCurveDay(type = "WST") {
    const defaultValue = 1;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultParturitionCurveDay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDefaultCurvesForSectors() {
    const defaultValue = {};
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.DefaultCurves || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDefaultCurve(sector) {
    const defaultValue = null;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.DefaultCurves[sector] || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @return {array}
 */
export function getFeedingPunishmentSettings() {
    const defaultValue = [
        {
            Time: 1,
            Trigger: 1,
            Percentage: 95
        },
        {
            Time: 1,
            Trigger: 2,
            Percentage: 90

        },
        {
            Time: 2,
            Trigger: 2,
            Percentage: 85

        }
    ];
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.Punishment || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFeedingLevels() {
    const defaultValue = {
        FeedingMinimumLevel: .1,
        FeedingMaximumLevel: .75
    };
    try {
        return store.getState().settings.general.SetData.Settings.FeedingLevels || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getSettingClass(setting) {
    try {
        switch (setting.SetType) {
            case SettingTypes.GENERAL:
                return new General(setting);
            case SettingTypes.FEEDING_CURVE:
                return new Curve(setting);
            case SettingTypes.FEEDING_FORAGE:
                return new Forage(setting);
            case SettingTypes.FEEDING_SCHEDULE:
                return new Schedule(setting);
            case SettingTypes.LAYOUT:
                return new Layout(setting);
            case SettingTypes.ENDPOINT_ARN:
                return new ARNEndpoint(setting);
            case SettingTypes.NOTIFICATIONS:
                return new Notification(setting);
            case SettingTypes.ECONOMY:
                return new Economy(setting);
            case SettingTypes.YEARLY_PLANS:
                return new Plans(setting);
            case SettingTypes.FARM_MAP:
                return new FarmMap(setting);
            case SettingTypes.CLIMATE_CURVE:
                return new ClimateCurve(setting);
            case SettingTypes.CLIMATE_SK3_CURVE:
                return new ClimateCurveSK3(setting);
            case SettingTypes.DEVICE_SETTINGS:
                return new DeviceSetting(setting);
            case SettingTypes.SYSTEM_SCHEDULE_WORKTYPE:
                return new SystemScheduleWorktype(setting);
            case SettingTypes.SYSTEM_SCHEDULE:
                return new SystemSchedule(setting);
            default:
                return new Setting(setting);
        }
    } catch (e) {
        console.error(e);
        return setting;
    }
}
