import {cloneDeep, get, groupBy, isEmpty, minBy} from "lodash";
import * as EventTypes from "validators-schema/Api/constants/eventTypes";
import moment from "moment";
import {
    getDaysForRepeatedInsemination,
    getMaxDelayForBirth,
    getMinAgeForFirstMating,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnBirthRoomMommy,
} from "./SettingsUtils";
import {getPigBalance} from "./EventUtils";
import {CycleActions} from "../constans/cycleActions";

/**
 * Funkcja wyliczająca cykle na podstawie ZDARZEN jakie wykonaliśmy na maciorze
 * @param animalEvents
 * @param animal
 * @returns {{cycleTable: *[], events: *}}
 */
export function preEvents(animalEvents, animal) {
    let eventz = cloneDeep(animalEvents);
    let daysFromInseminationToBeingMommy = getTimeFromInseminationToPartuition() + getTimeOnBirthRoomMommy();
    eventz = eventz ? eventz : [];
    let sowCyclesEvent = eventz.find(ev => ev.EvCode === EventTypes.SOW_CYCLES);
    //bierzemy pod uwage tylko wazne eventy
    eventz = eventz.filter(ev => ev.DtaDelTime === undefined && [
        EventTypes.INSEMINATION,
        EventTypes.NO_PREGNANCY,
        EventTypes.PARTURITION,
        EventTypes.SEPARATION,
        EventTypes.FALL_PIGLETS,
        EventTypes.WEIGHTING,
        EventTypes.SEPARATION_TO_MOMMY,
        EventTypes.USG,
        EventTypes.MOMMY,
        EventTypes.ACTIVE_NIPPLES,
        EventTypes.SOW_CYCLES
    ].includes(ev.EvCode));
    eventz.sort((a, b) => (a.EvTime !== undefined ? a.EvTime : 0) - (b.EvTime !== undefined ? b.EvTime : 0));

    let eventsBeforeSowInsertion = [];
    let eventsAfterSowInsertion = [];
    let timeBetweenInseminations = getDaysForRepeatedInsemination();

    const cycleTableBefore = [];
    const cycleTableAfter = [];

    let actualCycleBefore = 0;
    let actualCycleAfter = 0;

    let startTimeBefore;
    let endTimeBefore;
    let startTimeAfter;
    let endTimeAfter;

    //jesli nie ma sowCycles event oznacza to stara swinie
    if (!isEmpty(sowCyclesEvent)) {
        // wyciagniecie wszystkich waznych eventow przed data ostatniego odsadu podanego w evencie sow cycles oraz samo sow cycles
        eventsBeforeSowInsertion = eventz.filter((event) => event.EvTime <= sowCyclesEvent.EvData.LastSeparation || event.EvCode === EventTypes.SOW_CYCLES);
        // pobranie eventow po ostatnim odsadzeniu ktory podalismy na wprowadzieniu swini
        eventsAfterSowInsertion = eventz.filter((event) => event.EvTime > sowCyclesEvent.EvData.LastSeparation && event.EvCode !== EventTypes.SOW_CYCLES);
        // wyliczenie cyklu w ktorym zostala podana data ostatniego odsadu czyli jesli podamy 2, to data ostatniego odsadu zaliczy sie do 1 cyklu
        actualCycleBefore = get(sowCyclesEvent, "EvData.Cycles", 1) - 1;
        // wyliczeniu nastepnego cyklu do ktorego beda zaliczone eventy po dacie ostatniego odsadzenia
        actualCycleAfter = get(sowCyclesEvent, "EvData.Cycles", 1);
        // pobranie daty ostatniego odsadu
        let lastSeparationTime = get(sowCyclesEvent, "EvData.LastSeparation");

        startTimeBefore = moment(lastSeparationTime).subtract(daysFromInseminationToBeingMommy, "days").startOf("day").toDate().getTime();
        endTimeBefore = moment(lastSeparationTime).endOf("day").toDate().getTime();

        startTimeAfter = moment(lastSeparationTime).add(1, "day").startOf("day").toDate().getTime();
        endTimeAfter = moment(lastSeparationTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime();
    } else {
        actualCycleAfter = 0;
        eventsAfterSowInsertion = eventz.slice(0);
    }

    let rowBefore = {
        cycle: actualCycleBefore,
        [EventTypes.INSEMINATION]: [],
        [EventTypes.USG]: [],
        [EventTypes.NO_PREGNANCY]: [],
        [EventTypes.PARTURITION]: [],
        [EventTypes.SEPARATION_TO_MOMMY]: [],
        [EventTypes.MOMMY]: [],
        [EventTypes.FALL_PIGLETS]: [],
        [EventTypes.WEIGHTING]: [],
        [EventTypes.ACTIVE_NIPPLES]: [],
        [EventTypes.SEPARATION]: [],
        [EventTypes.SOW_CYCLES]: [],
        StartTime: startTimeBefore,
        EndTime: endTimeBefore
    };

    let row = {
        cycle: actualCycleAfter,
        [EventTypes.INSEMINATION]: [],
        [EventTypes.USG]: [],
        [EventTypes.NO_PREGNANCY]: [],
        [EventTypes.PARTURITION]: [],
        [EventTypes.SEPARATION_TO_MOMMY]: [],
        [EventTypes.MOMMY]: [],
        [EventTypes.FALL_PIGLETS]: [],
        [EventTypes.WEIGHTING]: [],
        [EventTypes.ACTIVE_NIPPLES]: [],
        [EventTypes.SEPARATION]: [],
        [EventTypes.SOW_CYCLES]: [],
        StartTime: startTimeAfter,
        EndTime: endTimeAfter
    };

    if (!isEmpty(eventsBeforeSowInsertion)) {
        eventsBeforeSowInsertion.forEach((event, index) => {
            const {EvCode, EvTime} = event;
            if (!isEmpty(rowBefore[EventTypes.NO_PREGNANCY])) {
                rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                cycleTableBefore.push(rowBefore);
                rowBefore = {
                    cycle: actualCycleBefore,
                    [EventTypes.INSEMINATION]: [],
                    [EventTypes.USG]: [],
                    [EventTypes.NO_PREGNANCY]: [],
                    [EventTypes.PARTURITION]: [],
                    [EventTypes.SEPARATION_TO_MOMMY]: [],
                    [EventTypes.MOMMY]: [],
                    [EventTypes.FALL_PIGLETS]: [],
                    [EventTypes.WEIGHTING]: [],
                    [EventTypes.ACTIVE_NIPPLES]: [],
                    [EventTypes.SEPARATION]: [],
                    [EventTypes.SOW_CYCLES]: [],
                    StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                    EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                };
            }
            if (!isEmpty(rowBefore[EventTypes.SEPARATION_TO_MOMMY])) {
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.MOMMY])) {
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.FALL_PIGLETS])) {
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.PARTURITION, EventTypes.NO_PREGNANCY].includes(EvCode)) {
                    rowBefore.EndTime = rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            //WAŻENIE - może być wiele więc nie kończą one nam w żadnym wypadku cyklu

            if (!isEmpty(rowBefore[EventTypes.SEPARATION])) {
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION, EventTypes.SEPARATION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }

            //AKTYWNYCH SUTKÓW MOŻE BYC WIELE
            if (!isEmpty(rowBefore[EventTypes.PARTURITION])) {
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.USG])) {
                const isPregant = !isEmpty(rowBefore[EventTypes.USG]) ? rowBefore[EventTypes.USG][rowBefore[EventTypes.USG].length - 1].EvData.Pregnant : true;
                if (isPregant && [EventTypes.INSEMINATION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
                //jesli stwierdzony zostal brak ciazy traktujemy z gory jako powtorke
                if (!isPregant) {
                    rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableBefore.push(rowBefore);
                    rowBefore = {
                        cycle: actualCycleBefore,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.INSEMINATION])) {
                if ([EventTypes.INSEMINATION].includes(EvCode)) {
                    let lastInsemination = rowBefore[EventTypes.INSEMINATION][rowBefore[EventTypes.INSEMINATION].length - 1];
                    if ((moment(+lastInsemination.EvTime).startOf('day').diff(moment(+event.EvTime).startOf('day'), 'days')) > timeBetweenInseminations) {
                        rowBefore.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                        cycleTableBefore.push(rowBefore);
                        rowBefore = {
                            cycle: actualCycleBefore,
                            [EventTypes.INSEMINATION]: [],
                            [EventTypes.USG]: [],
                            [EventTypes.NO_PREGNANCY]: [],
                            [EventTypes.PARTURITION]: [],
                            [EventTypes.SEPARATION_TO_MOMMY]: [],
                            [EventTypes.MOMMY]: [],
                            [EventTypes.FALL_PIGLETS]: [],
                            [EventTypes.WEIGHTING]: [],
                            [EventTypes.ACTIVE_NIPPLES]: [],
                            [EventTypes.SEPARATION]: [],
                            [EventTypes.SOW_CYCLES]: [],
                            StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                            EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                        };
                    }
                }
            }
            if ((eventsBeforeSowInsertion.length - 1) === index) {
                rowBefore[EvCode].push(event);
                cycleTableBefore.push(rowBefore);
            } else {
                rowBefore[EvCode].push(event);
            }
        });
    }

    if (!isEmpty(eventsAfterSowInsertion)) {
        eventsAfterSowInsertion.forEach((event, index) => {
            const {EvCode, EvTime} = event;
            if (!isEmpty(row[EventTypes.NO_PREGNANCY])) {
                row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                cycleTableAfter.push(row);
                row = {
                    cycle: actualCycleAfter,
                    [EventTypes.INSEMINATION]: [],
                    [EventTypes.USG]: [],
                    [EventTypes.NO_PREGNANCY]: [],
                    [EventTypes.PARTURITION]: [],
                    [EventTypes.SEPARATION_TO_MOMMY]: [],
                    [EventTypes.MOMMY]: [],
                    [EventTypes.FALL_PIGLETS]: [],
                    [EventTypes.WEIGHTING]: [],
                    [EventTypes.ACTIVE_NIPPLES]: [],
                    [EventTypes.SEPARATION]: [],
                    [EventTypes.SOW_CYCLES]: [],
                    StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                    EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                };
            }
            if (!isEmpty(row[EventTypes.SEPARATION_TO_MOMMY])) {
                //odsadzeń od mamki moze byc kilka, ale jesli przyjdzie inseminacja, usg, porod, brak ciazy ucinamy wiersz i nowy cykl
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(row[EventTypes.MOMMY])) {
                //mamka moze byc pare razy, ale jesli przyjdzie inseminacja, usg, brak ciazy lub porod to ucinamy wiersz, i nowy cykl. Traktujemy event ten jak wyproszenie!
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(row[EventTypes.FALL_PIGLETS])) {
                //upadek prosiat moze byc kilka wiec jesli przyjdzie inseminacja, usg, porod, brak ciazy ucinamy wiersz i nowy cykl
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.PARTURITION, EventTypes.NO_PREGNANCY].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }

            //WAŻENIE - może być wiele więc nie kończą one nam w żadnym wypadku cyklu

            if (!isEmpty(row[EventTypes.SEPARATION])) {
                //jesli po odsadzie przyjdzie inseminacja, usg, brak ciazy, porod lub kolejny odsad z gory zakladamy NOWY CYKL
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION, EventTypes.SEPARATION].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }

            //AKTYWNE SUTKI - po aktywnych sutkach moga przychodzic inne eventy, wyswietlamy w wierszu tylko ostatni

            if (!isEmpty(row[EventTypes.PARTURITION])) {
                //jesli po porodzie przyjdzie inseminacja, usg, brak ciazy, porod oznacza to NOWY CYKL
                if ([EventTypes.INSEMINATION, EventTypes.USG, EventTypes.NO_PREGNANCY, EventTypes.PARTURITION].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(row[EventTypes.USG])) {
                const isPregant = !isEmpty(row[EventTypes.USG]) ? row[EventTypes.USG][row[EventTypes.USG].length - 1].EvData.Pregnant : true;
                //jesli nastepnym eventem jest inseminacja sprawdzam czy stwierdzona byla ciaza
                //jesli stwierdzona zostala ciaza a zrobilismy inseminacje oznacza to rowniez powtorke bo nie otrzymalismy prosiakow
                if (isPregant && [EventTypes.INSEMINATION].includes(EvCode)) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
                //jesli stwierdzony zostal brak ciazy i zrobilismy inseminacje POWTORKA
                if (!isPregant) {
                    row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                    cycleTableAfter.push(row);
                    row = {
                        cycle: actualCycleAfter,
                        [EventTypes.INSEMINATION]: [],
                        [EventTypes.USG]: [],
                        [EventTypes.NO_PREGNANCY]: [],
                        [EventTypes.PARTURITION]: [],
                        [EventTypes.SEPARATION_TO_MOMMY]: [],
                        [EventTypes.MOMMY]: [],
                        [EventTypes.FALL_PIGLETS]: [],
                        [EventTypes.WEIGHTING]: [],
                        [EventTypes.ACTIVE_NIPPLES]: [],
                        [EventTypes.SEPARATION]: [],
                        [EventTypes.SOW_CYCLES]: [],
                        StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                        EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                    };
                }
            }
            if (!isEmpty(row[EventTypes.INSEMINATION])) {
                //jesli po inseminacji przyjdzie inseminacja sprawdzamy jaki czas zdefiniowal sobie uzytkownik pomiedzy powtórkami
                //1. Jesli kolejna inseminacja jest wieksza od tego czasu, zaliczamy to do powtórki - nowy wiersz
                //2. Inaczej jest w tym samym wierszu
                if ([EventTypes.INSEMINATION].includes(EvCode)) {
                    let lastInsemination = row[EventTypes.INSEMINATION][row[EventTypes.INSEMINATION].length - 1];
                    if ((moment(+event.EvTime).startOf('day').diff(moment(+lastInsemination.EvTime).startOf('day'), 'days')) > timeBetweenInseminations) {
                        row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                        cycleTableAfter.push(row);
                        row = {
                            cycle: actualCycleAfter,
                            [EventTypes.INSEMINATION]: [],
                            [EventTypes.USG]: [],
                            [EventTypes.NO_PREGNANCY]: [],
                            [EventTypes.PARTURITION]: [],
                            [EventTypes.SEPARATION_TO_MOMMY]: [],
                            [EventTypes.MOMMY]: [],
                            [EventTypes.FALL_PIGLETS]: [],
                            [EventTypes.WEIGHTING]: [],
                            [EventTypes.ACTIVE_NIPPLES]: [],
                            [EventTypes.SEPARATION]: [],
                            [EventTypes.SOW_CYCLES]: [],
                            StartTime: moment(EvTime).add(1, "day").startOf("day").toDate().getTime(),
                            EndTime: moment(EvTime).add(daysFromInseminationToBeingMommy + 1, "days").endOf("day").toDate().getTime()
                        };
                    }
                }
            }
            //jesli jest to ostatni event wpychamy go do wiersza, a sam wiersz do tablicy
            if ((eventsAfterSowInsertion.length - 1) === index) {
                if (isRowCycleEmpty(row)) {
                    row.StartTime = moment(EvTime).startOf("day").toDate().getTime();
                    row.EndTime = moment(EvTime).add(daysFromInseminationToBeingMommy, "days").endOf("day").toDate().getTime();
                    if (cycleTableBefore.length > 0) {
                        cycleTableBefore[cycleTableBefore.length - 1].EndTime = moment(EvTime).subtract(1, "day").endOf("day").toDate().getTime();
                    }
                }
                row[EvCode].push(event);
                cycleTableAfter.push(row);
            } else {
                if (isRowCycleEmpty(row)) {
                    row.StartTime = moment(EvTime).startOf("day").toDate().getTime();
                    row.EndTime = moment(EvTime).add(daysFromInseminationToBeingMommy, "days").endOf("day").toDate().getTime();
                    if (cycleTableBefore.length > 0) {
                        cycleTableBefore[cycleTableBefore.length - 1].EndTime = moment(EvTime).subtract(1, "day").endOf("day").toDate().getTime();
                    }
                }
                row[EvCode].push(event);
            }
        });
    }
    const replacedEventsBefore = cycleTableBefore.map((row, index) => ({
        cycle: cycleTableBefore[(cycleTableBefore.length - 1) - index].cycle,
        [EventTypes.INSEMINATION]: row[EventTypes.INSEMINATION],
        [EventTypes.USG]: row[EventTypes.USG],
        [EventTypes.NO_PREGNANCY]: row[EventTypes.NO_PREGNANCY],
        [EventTypes.PARTURITION]: row[EventTypes.PARTURITION],
        [EventTypes.SEPARATION_TO_MOMMY]: row[EventTypes.SEPARATION_TO_MOMMY],
        [EventTypes.MOMMY]: row[EventTypes.MOMMY],
        [EventTypes.FALL_PIGLETS]: row[EventTypes.FALL_PIGLETS],
        [EventTypes.WEIGHTING]: row[EventTypes.WEIGHTING],
        [EventTypes.ACTIVE_NIPPLES]: row[EventTypes.ACTIVE_NIPPLES],
        [EventTypes.SEPARATION]: row[EventTypes.SEPARATION],
        [EventTypes.SOW_CYCLES]: row[EventTypes.SOW_CYCLES],
        StartTime: row.StartTime,
        EndTime: row.EndTime
    }));
    const finalTable = [];
    const cycleTable = [...replacedEventsBefore, ...cycleTableAfter];
    const lessCycle = !isEmpty(cycleTable) ? minBy(cycleTable, 'cycle').cycle : 0;
    let idx = 0;
    for (idx; idx < lessCycle; idx++) {
        finalTable.push({
            cycle: idx,
            [EventTypes.INSEMINATION]: [],
            [EventTypes.USG]: [],
            [EventTypes.NO_PREGNANCY]: [],
            [EventTypes.PARTURITION]: [],
            [EventTypes.SEPARATION_TO_MOMMY]: [],
            [EventTypes.MOMMY]: [],
            [EventTypes.FALL_PIGLETS]: [],
            [EventTypes.WEIGHTING]: [],
            [EventTypes.ACTIVE_NIPPLES]: [],
            [EventTypes.SEPARATION]: [],
            [EventTypes.SOW_CYCLES]: [],
        });
    }
    let showData = [...finalTable, ...cycleTable];
    for (let tempIdx = showData.length - 1; tempIdx >= 0; tempIdx--) {
        if (!isEmpty(showData[tempIdx - 1]) && isRowCycleEmpty(showData[tempIdx - 1])) {
            showData[tempIdx - 1].EndTime = moment(showData[tempIdx].StartTime).subtract(1, "day").endOf("day").toDate().getTime();
            showData[tempIdx - 1].StartTime = moment(showData[tempIdx].StartTime).subtract(daysFromInseminationToBeingMommy + 1, "day").startOf("day").toDate().getTime();
        }
    }
    return {
        events: eventz,
        cycleTable: showData,
        resultTable: cycleTable //nie dla reducera to, dla wyników to
    };
}

/**
 * funkcja sprawdzająca czy wiersz cyklu jest pusty
 * @param row
 * @returns {boolean}
 */
function isRowCycleEmpty(row) {
    let empty = true;
    for (const property in row) {
        if (Array.isArray(row[property]) && !isEmpty(row[property])) {
            empty = false;
        }
    }
    return empty;
}

/**
 * Funkcja flatująca powtórki, i cykle rozróżniane jako jeden obiekt
 * @param data
 * @returns {Array}
 */
export function convertRowsToCycles(data) {
    const result = [];
    let clone = cloneDeep(data);
    clone = groupBy(clone, 'cycle');
    for (const value of Object.values(clone)) {
        if (Array.isArray(value)) {
            let obj = {
                [EventTypes.INSEMINATION]: [],
                [EventTypes.USG]: [],
                [EventTypes.NO_PREGNANCY]: [],
                [EventTypes.PARTURITION]: [],
                [EventTypes.SEPARATION_TO_MOMMY]: [],
                [EventTypes.MOMMY]: [],
                [EventTypes.FALL_PIGLETS]: [],
                [EventTypes.WEIGHTING]: [],
                [EventTypes.ACTIVE_NIPPLES]: [],
                [EventTypes.SEPARATION]: [],
                [EventTypes.SOW_CYCLES]: [],
            };
            for (const element of value) {
                obj.cycle = element.cycle;
                obj.StartTime = element.StartTime;
                obj.EndTime = element.EndTime;
                for (let [key, value] of Object.entries(element)) {
                    if (Array.isArray(value)) {
                        obj[key] = [...obj[key], ...value];
                    }
                }
            }
            result.push(obj);
        }
    }
    return result;
}

/**
 * Funkcja walidująca wyliczone cykle pod kątem ich zgodności zwracająca taką samą tablice powiększoną
 * w każdym obiekcie o tablice invalidEvents.
 * @param cycles
 * @param animal
 * @returns {Array}
 */
export function getCorrectValidatedEventsData(cycles, animal) {
    const arr = [];
    cycles.map(el => ({...el, invalidEvents: []})).forEach((row, index) => {
        const rowEvents = getEventsFromRow(row);
        const firstInsemination = row[EventTypes.INSEMINATION][0];
        const firstUSG = row[EventTypes.USG][0];
        const firstParturition = row[EventTypes.PARTURITION][0];
        const separation = row[EventTypes.SEPARATION][0];
        const separationToMommy = row[EventTypes.SEPARATION_TO_MOMMY][0];
        const activeNipples = row[EventTypes.ACTIVE_NIPPLES][0];
        const sowCycle = row[EventTypes.SOW_CYCLES][0];
        //jesli byla inseminacja
        if (!!firstInsemination) {
            const birthDate = animal.DtaBrthTime ? +animal.DtaBrthTime : +animal.DtaInTime;
            const timeBetweenBirthToFirstInsemination = moment(+firstInsemination.EvTime).startOf('day').diff(moment(birthDate).startOf('day'), 'days');
            const minAgeForFirstMatingInDays = getMinAgeForFirstMating();
            //jesli ilosc dni pomiedzy urodzeniem, a inseminacja jest mniejszy niz zdefiniowany czas w ustawieniach wyrzuc blad
            if (timeBetweenBirthToFirstInsemination < minAgeForFirstMatingInDays) {
                row.invalidEvents.push({
                    EvCode: EventTypes.INSEMINATION,
                    Reason: CycleActions.FIRST_INSEMINATION_FROM_BIRTH,
                    Cycle: row.cycle
                });
            }
            //jesli badanie usg zostalo zrobione przed uplywem x dni wyrzuc blad
            if (!!firstUSG) {
                const timeBetweenBothEvents = moment(+firstUSG.EvTime).startOf('day').diff(moment(+firstInsemination.EvTime).startOf('day'), 'days');
                const timeFromInseminationToPregnancy = getTimeFromInseminationToPregnancy();
                if (timeBetweenBothEvents < timeFromInseminationToPregnancy) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.USG,
                        Reason: CycleActions.USG_BEFORE_X_DAYS,
                        Cycle: row.cycle
                    });
                }
            }
            //jesli jest poród
            if (!!firstParturition) {
                const timeBetweenBothEvents = moment(+firstParturition.EvTime).startOf('day').diff(moment(+firstInsemination.EvTime).startOf('day'), 'days');
                const timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
                const maxDelayForBirth = getMaxDelayForBirth();
                //wyproszenie przed uplywem x dni
                if ((timeBetweenBothEvents + maxDelayForBirth) < timeFromInseminationToParturition) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.PARTURITION,
                        Reason: CycleActions.PARTURITION_BEFORE_X_DAYS,
                        Cycle: row.cycle
                    });
                }
                //porod zgloszony za pozno
                else if (timeBetweenBothEvents > (timeFromInseminationToParturition + maxDelayForBirth)) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.PARTURITION,
                        Reason: CycleActions.PARTURITION_AFTER_X_DAYS,
                        Cycle: row.cycle
                    });
                }
            }
        }
        //Brak zgloszonej inseminacji a wystapi event w danym cyklu np. USG, Wyproszenie upadek prosiaka, mamka, odsad, aktywne sutki
        else if (!firstInsemination && rowEvents.filter(ev => [EventTypes.USG, EventTypes.PARTURITION, EventTypes.FALL_PIGLETS, EventTypes.MOMMY, EventTypes.SEPARATION, EventTypes.ACTIVE_NIPPLES].includes(ev.EvCode)).length) {
            row.invalidEvents.push({
                EvCode: EventTypes.INSEMINATION,
                Reason: CycleActions.NO_INSEMINATION_BEFORE_EV,
                Cycle: row.cycle
            });
        }
        //Brak zgloszonego badania usg - zgloszone w danym cyklu wyproszenie, updaek prosiaka odsad, lub aktywne sutki
        if (!firstUSG && rowEvents.filter(ev => [EventTypes.PARTURITION, EventTypes.FALL_PIGLETS, EventTypes.SEPARATION, EventTypes.ACTIVE_NIPPLES].includes(ev.EvCode)).length) {
            row.invalidEvents.push({EvCode: EventTypes.USG, Reason: CycleActions.NO_USG_BEFORE_EV, Cycle: row.cycle});
        }
        //brak zgloszonego wyproszenia - zgloszony odsad lub upadek prosiaka
        if (!firstParturition && rowEvents.filter(ev => [EventTypes.SEPARATION, EventTypes.FALL_PIGLETS].includes(ev.EvCode)).length) {
            row.invalidEvents.push({
                EvCode: EventTypes.PARTURITION,
                Reason: CycleActions.NO_PARTURITION_AFTER_EV,
                Cycle: row.cycle
            });
        }
        //brak zgloszonego odasdu - po zgloszonym wczesniej wyproszeniu
        if (!separation) {
            const parturition = rowEvents.find(ev => [EventTypes.PARTURITION].includes(ev.EvCode));
            //const timeOnBirthRoom = getTimeOnBirthRoom(); // czas na porodówce
            if (parturition) {
                if (index !== (cycles.length - 1)) { // jesli nie jest to ostatni wiersz jaki rozpatrujemy
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION,
                        Reason: CycleActions.NO_SEPARATION_AFTER_EV,
                        Cycle: row.cycle
                    })
                }
            }
        }
        //Brak zgloszonego porodu po uplywie x dni od a po zgloszonym qwczesniej inseminacji i nie zgloszonych eventrach, ktore zmienily by cykl lub swiad czyly o powtorce
        if (!!firstInsemination && !firstParturition) {
            //brak zgloszonego porodu musi byc sprawdzany dopiero po x dniach
            let timeAfterInsemination = +moment(+firstInsemination.EvTime).startOf('day').add(getTimeFromInseminationToPartuition(), 'days');
            if (rowEvents.filter(ev => (+ev.EvTime >= timeAfterInsemination) && ![EventTypes.SEPARATION].includes(ev.EvCode)).length) {
                row.invalidEvents.push({
                    EvCode: EventTypes.PARTURITION,
                    Reason: CycleActions.NO_PARTURITION_AFTER_X_DAYS,
                    Cycle: row.cycle
                });
            }
        }
        //ilosc odsadzonych prosiat wieksza niz porod - upadki + mamka
        if (firstParturition && separation) {
            let sowPigletsBalance = 0;
            let separatedPiglets = separation.EvData.PiCnt;
            [...row[EventTypes.FALL_PIGLETS], ...row[EventTypes.SEPARATION_TO_MOMMY]].forEach(ev => {
                if (ev.EvCode === EventTypes.FALL_PIGLETS) sowPigletsBalance -= +ev.EvData.Piglets;
                if (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY) sowPigletsBalance -= +ev.EvData.PiCnt;
            });
            [...row[EventTypes.PARTURITION], ...row[EventTypes.MOMMY]].forEach(ev => {
                if (ev.EvCode === EventTypes.PARTURITION) sowPigletsBalance += +ev.EvData.HealthyCnt;
                if (ev.EvCode === EventTypes.MOMMY) sowPigletsBalance += +ev.EvData.PiCnt;
            });

            if (separatedPiglets > sowPigletsBalance) {
                row.invalidEvents.push({
                    EvCode: EventTypes.SEPARATION,
                    Reason: CycleActions.SEPARATION_CNT_BIGGER_THAN_PARTURITION_CNT,
                    Cycle: row.cycle
                });
            }
        }
        //ilosc odsadzonych prosiat do mamki wieksza niz ilosc urodzonych - upadki do momentu zgloszenia
        if (firstParturition && separationToMommy) {
            const lastSeparationToMommy = row[EventTypes.SEPARATION_TO_MOMMY][row[EventTypes.SEPARATION_TO_MOMMY].length - 1];
            const parturitionBalance = getPigletsCountFromParturitions(row[EventTypes.PARTURITION]);
            const fallPigletsBalance = !isEmpty(row[EventTypes.FALL_PIGLETS]) ? row[EventTypes.FALL_PIGLETS]
                .filter(fall => +fall.EvTime <= +lastSeparationToMommy.EvTime)
                .reduce((sum, ev) => {
                    return sum + +ev.EvData.Piglets
                }, 0) : 0;
            const separationToMommyBalance = !isEmpty(row[EventTypes.SEPARATION_TO_MOMMY]) ? row[EventTypes.SEPARATION_TO_MOMMY]
                .reduce((sum, ev) => {
                    return sum + +ev.EvData.PiCnt
                }, 0) : 0;

            if (separationToMommyBalance > (parturitionBalance - fallPigletsBalance)) {
                row.invalidEvents.push({
                    EvCode: EventTypes.SEPARATION_TO_MOMMY,
                    Reason: CycleActions.SEPARATION_CNT_BIGGER_THAN_BIRTH_CNT,
                    Cycle: row.cycle
                });
            }
        }
        //ilosc prosiakow wieksza niz aktywne sutki (bierzemy pod uwage tylko ostatnie)
        if (activeNipples && firstParturition) {
            const lastActiveNipplesCnt = row[EventTypes.ACTIVE_NIPPLES][row[EventTypes.ACTIVE_NIPPLES].length - 1].Nipples;
            const events = rowEvents.filter(ev => [EventTypes.PARTURITION, EventTypes.FALL_PIGLETS, EventTypes.SEPARATION_TO_MOMMY, EventTypes.SEPARATION, EventTypes.MOMMY, EventTypes.INSEMINATION].includes(ev.EvCode))
                .filter(ev => +ev.EvTime >= +firstParturition.EvTime);
            const pigBalance = getPigBalance(events);
            if (pigBalance > lastActiveNipplesCnt) {
                row.invalidEvents.push({
                    EvCode: EventTypes.ACTIVE_NIPPLES,
                    Reason: CycleActions.PIGLET_CNT_BIGGER_THAN_NIPPLES,
                    Cycle: row.cycle
                });
            }
        }
        if (sowCycle) {
            const lastSeparationDay = moment(get(sowCycle, "EvData.LastSeparation")).startOf("day");
            if (!separation) {
                row.invalidEvents.push({
                    EvCode: EventTypes.SEPARATION,
                    Reason: CycleActions.NO_SEPARATION_BUT_SOW_CYCLES,
                    Cycle: row.cycle,
                    type: "success",
                    AdditionalData: {
                        lastSeparation: lastSeparationDay.format("DD.MM.YYYY")
                    }
                });
            } else {
                const isSame = moment(get(separation, "EvTime")).startOf("day").isSame(lastSeparationDay);
                if (!isSame) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION,
                        Reason: CycleActions.LAST_SEPARATION_DIFF_THAN_IN_REALITY,
                        Cycle: row.cycle,
                        type: "warning"
                    })
                }
            }
        }
        arr.push(row);
    });
    return arr;
}

/**
 * Funkcja zwracjąca ilość otrzymanych prosiąt z porodów.
 * @param parturitions
 * @returns {*}
 */
function getPigletsCountFromParturitions(parturitions) {
    return parturitions.reduce((sum, ev) => {
        return sum + +ev.EvData.HealthyCnt;
    }, 0);
}

/**
 * Funkcja filtrująca wiersz z niepotrzebnych kluczy zostawiająca tylko te zawierające eventy.
 * @param row
 * @returns {Array}
 */
function getEventsFromRow(row) {
    const events = [];
    for (let [key, value] of Object.entries(row)) {
        if (!['cycle', 'invalidEvents'].includes(key)) {
            if (Array.isArray(value) && !isEmpty(value)) value.forEach(el => events.push(el));
        }
    }
    return events;
}

/**
 * Metoda wylicza srednia ilosc dnia jalowych dla cyklow z preEvents
 * @param cycles
 * @return {number}
 */
export function getAverageBreakForAnimal(cycles) {
    let array = [];
    for (let i = 0; i < cycles.length; i++) {
        let cycle = cycles[i];
        let separation = cycle[EventTypes.SEPARATION][0]
        if (separation) {
            let nextTime = null;
            let nextCycle = cycles[i + 1];
            if (nextCycle) {
                let insemination = nextCycle[EventTypes.INSEMINATION][0];
                if (insemination) {
                    nextTime = insemination.EvTime;
                } else {
                    continue;
                }
            } else if (i === cycles.length - 1) {
                nextTime = new Date().getTime();
            } else {
                continue;
            }
            let diff = moment(nextTime).diff(separation.EvTime, "days");
            array.push(diff);
        }
    }
    console.log(array);
    if (array.length === 0) return 0;
    return array.reduce((a, b) => a + b, 0) / array.length;
}
