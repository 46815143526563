import {isArray, isObject, memoize} from "lodash";
import {compareTwoStrings} from "string-similarity";
import {Roles} from "../constans/roles";

export const filterFarms = memoize((farmObj, searchString) => {
    if (isArray(farmObj)) return farmObj; //na nie-serwisowych nie sortujemy
    const farms = new Map(farmObj.entries);
    const search = searchString.trim().toLowerCase();
    if (search === "") return farms;
    const _filter = ((_farms = []) => {
        return _farms.filter(farm => {
            return `${farm.FarmName || ""}${farm.Adr || ""}${farm.FarmID || ""}`.toLowerCase().includes(search)
        })
    });
    const sortFarms = (farmList) => {
        return farmList.slice(0).sort((f1, f2) => {
            const score = (f) => compareTwoStrings((f.FarmID || "").toLowerCase(), search) * 3 + compareTwoStrings((f.FarmName || "").toLowerCase(), search) * 2 + compareTwoStrings((f.Adr || "").toLowerCase(), search);
            return score(f2) - score(f1)
        });
    }
    if (isObject(farms)) {
        let farmMap = new Map();
        for (let [clientId, {name, farms: farmList}] of farms.entries()) {
            const newFarms = _filter(farmList);
            if (clientId.toLowerCase().includes(search) || name.toLowerCase().includes(search)) {
                farmMap.set(clientId, {name, farms: sortFarms(farmList)})
            } else if (newFarms.length) {
                farmMap.set(clientId, {name, farms: sortFarms(newFarms)})
            } else {
                farmMap.delete(clientId);
            }
        }
        return farmMap;
    }
    return farms;
}, (...args) => JSON.stringify(args));


export const groupFarms = (farms, user) => {
    let farmsMap = new Map();
    let serviceRole = user.Roles.find(item => item.Role === Roles._SERVICE);
    if (!serviceRole) return farms;
    for (let farm of farms) {
        let clientID = "";
        let farmInRole = serviceRole.LocalRights.find(item => item.FarmID === farm.FarmID);
        if (farmInRole) {
            clientID = farmInRole.ClientID;
        } else {
            clientID = user.ClientID;
        }
        const data = farmsMap.get(clientID) || {name: farm.Alias || clientID, farms: []};
        data.farms.push(farm);
        farmsMap.set(clientID, data);
    }
    const remoteFarmsMap = new Map();
    for (let [key, {name, farms: value}] of farmsMap) {
        const filtered = value.filter(farm => farm.RemoteServicing);
        if (filtered.length > 0) {
            remoteFarmsMap.set(key, {name, farms: filtered});
        }
    }
    const sortedArr = [...remoteFarmsMap].sort();
    try {
        if (user.ClientID) {
            const i = sortedArr.findIndex(o => o[0] === user.ClientID);
            const element = sortedArr[i];
            if (i === 0 || i === -1) return {entries: sortedArr.slice(0)};
            if (i > 0) {
                sortedArr.splice(i, 1);
            }
            sortedArr.unshift(element);
        }
    } catch (e) {
        console.error(e)
    }
    //zwracamy objekt bo mapy maja problem z cachowaniem
    return {entries: sortedArr.slice(0)};
}
