import _ from "lodash";

export function getTreeSelectValueByLocationID(options = [], locID = null) {
    if (_.isArray(options)) {
        for (let opt of options) {
            let tmp = getTreeSelectValueByLocationID(opt, locID);
            if (tmp) {
                return tmp;
            }
        }
    } else if (_.isObject(options)) {
        if (options.object[options.key] === locID) {
            return options;
        } else if (options.children) {
            return getTreeSelectValueByLocationID(options.children, locID);
        }
    }
}