import store from "../store/store";
import {setGlobalLoading} from "../actions/viewActions";
import {unsubscribeSNSTopic} from "../actions/snsActions";
import {logoutUser} from "../actions/userActions";
import NewIOT from "../IOT/NewIOT";

export function logout() {
    let state = store.getState();
    const {dispatch} = store;
    try {
        let snsEndpoint = state.sns.endpointArn;
        let endpoint = state.settings.endpointArn;
        dispatch(setGlobalLoading(true));
        if (snsEndpoint) {
            dispatch(unsubscribeSNSTopic(snsEndpoint))
        } else if (endpoint && endpoint[0]) {
            dispatch(unsubscribeSNSTopic(endpoint[0].SetData.EndpointArn))
        } else {
            dispatch(logoutUser());
        }
        NewIOT.endConnection();
        localStorage.removeItem("offlineLogin");
        localStorage.removeItem("offlinePass");
    } catch (e) {
        console.error(e);
        dispatch(setGlobalLoading(false));
    }
}

export const getUserApiData = ({FarmID, ClientID, LocalUserID} = {}) => {
    const state = store.getState();
    const {user: {user: {LocalUserID: _luid, ClientID: _cid}}, location: {farm: _fid}} = state;
    return {
        _FarmID: FarmID || _fid,
        _LocalUserID: LocalUserID || _luid,
        _ClientID: ClientID || _cid
    }
};
