import {invokeApig} from "../libs/awsLib";
import buildingsDB from "../database/buildingsDB";
import {checkIfUserHasRole} from "./NewRolesUtils";
import {Roles} from "../constans/roles";
import devicesDB from "../database/devicesDB";
import settingsDB from "../database/settingsDB";
import notificationsDB from "../database/notificationsDB";
import technologyGroupsDB from "../database/technologyGroupsDB";
import dictionariesDB from "../database/dictionariesDB";
import groupsDB from "../database/groupsDB";
import Paths from "../api/paths";
import reportsDB from "../database/reportsDB";
import lokiDB from "../database/lokiDB";

export async function getDictionaries(ClientID, signal) {
    let defaultDictionaries = await invokeApig({
        ...Paths.listDictionaries({clientID: "Default"}),
        method: 'GET',
        queryParams: {DtaModTime: dictionariesDB.getModificationTime().DtaModTime},
        signal
    });
    let clientDictionaries = await invokeApig({
        ...Paths.listDictionaries({clientID: ClientID}),
        method: 'GET',
        queryParams: {DtaModTime: dictionariesDB.getModificationTime().DtaModTime},
        signal
    });

    let array = [...defaultDictionaries, ...clientDictionaries];

    if (array.length > 0) {
        await dictionariesDB.insertIntoDictionaries(array);
    }
}

export async function getBuildings(FarmID, signal) {
    try {
        let buildings = await invokeApig({
            ...Paths.listBuilding({farmID: FarmID}),
            queryParams: {
                FarmID: FarmID,
                DtaModTime: parseInt(buildingsDB.getModificationTime(FarmID).DtaModTime)
            },
            signal
        });
        if (buildings.length > 0) {
            await buildingsDB.insertIntoBuildings(buildings);
        }
    } catch (e) {
        console.error(e);
    }
}

export async function getDevices(FarmID, user, signal) {
    try {
        if (checkIfUserHasRole(Roles._GENERAL_READ, FarmID, user.Roles)) {
            let devices = await invokeApig({
                ...Paths.listDevices({farmID: FarmID}),
                queryParams: {
                    DtaModTime: parseInt(devicesDB.getModificationTime(FarmID).DtaModTime),
                    table: "Devices"
                },
                signal
            });
            if (devices.length > 0) {
                await devicesDB.insertIntoDevices(devices);
            } else {
                await lokiDB.asyncSaveDB();
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export async function getSettings(ClientID, FarmID, signal) {
    const array = await invokeApig({
        ...Paths.listSettings({
            clientID: ClientID
        }),
        queryParams: {DtaModTime: settingsDB.getModificationTime(FarmID).DtaModTime},
        signal
    });
    if (array.length > 0) {
        await settingsDB.insertIntoSettings(array);
    }
}

export async function getTechnologyGroups(FarmID, signal) {
    try {
        let technologyGroups = await invokeApig({
            ...Paths.listTechnologyGroups({farmID: FarmID}),
            method: "GET",
            queryParams: {DtaModTime: technologyGroupsDB.getModificationTime(FarmID).DtaModTime},
            signal
        });
        if (technologyGroups.length > 0) {
            await technologyGroupsDB.insertIntoTechnologyGroup(technologyGroups);
        }
    } catch (e) {
        console.error(e);
    }
}

export async function loadNotifications(FarmID, signal) {
    try {
        let notifs = await invokeApig({
            ...Paths.listNotifications({farmID: FarmID}),
            queryParams: {DtaModTime: notificationsDB.getModificationTime(FarmID).DtaModTime},
            signal
        });
        if (notifs.length > 0) {
            await notificationsDB.insertIntoNotifications(notifs);
        }
    } catch (e) {
        console.error(e);
    }
}

export async function loadGroups(FarmID, signal) {
    try {
        let groups = await invokeApig({
            ...Paths.listGroups({farmID: FarmID}),
            queryParams: {DtaModTime: groupsDB.getModificationTime(FarmID).DtaModTime},
            signal
        });
        if (groups.length > 0) {
            await groupsDB.insertIntoGroups(groups);
        }
    } catch (e) {
        console.error(e);
    }
}

export async function loadReports(ClientID, LocalUserID, signal) {
    try {
        let reports = await invokeApig({
            ...Paths.getReports({clientID: ClientID, localUserID: LocalUserID}),
            queryParams: {DtaModTime: reportsDB.getModificationTime().DtaModTime},
            signal
        });
        if (reports.items.length > 0) {
            await reportsDB.insertAthenaReports(reports.items);
        }
    } catch (e) {
        console.error(e);
    }
}
