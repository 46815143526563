import {NotificationStatus, NotificationTypes} from "../constans/notifications";
import React from "react";
import animalsDB from "../database/animalsDB";
import devicesDB from "../database/devicesDB";
import store from "../store/store"
import {DevType} from "../constans/devices";
import {setAlert} from "../IOT/device-functions/DispenserNRFFunctions";
import {setClearFeedProblem, setClearMotorProblem} from "../IOT/device-functions/DispenserDriverFunctions";
import _ from "lodash";
import moment from "moment";
import {getLocationName} from "./BuildingUtils";
import i18n from "i18next";


export function getReadStatus(notification) {
    let user = store.getState().user.user;
    let readIDs = notification.ReadIDs;
    return _.isArray(readIDs.values) ? readIDs.values.includes(user.LocalUserID) : readIDs.includes(user.LocalUserID);
}

export function getTitle(notification) {


    switch (notification.NData.Type) {
        case NotificationTypes.PLACE:
            return notification.NData.Title;
        case NotificationTypes.NO_INSEMINATION:
            return i18n.t("notificationsCenter.birthNoInsemination");
        case NotificationTypes.TOO_FAST_BIRTH:
            return i18n.t("notificationsCenter.birthTooEarly");
        case NotificationTypes.SEPARATION_WRONG_AMOUNT:
            return i18n.t('notificationsCenter.tooMuchPiglets');
        case NotificationTypes.TREATMENT:
            return i18n.t("scheduleOfTreatment");
        case NotificationTypes.DEVICE_ALARM: {
            let device = devicesDB.getDeviceByID(notification.NData.DeviceID);
            //console.log("device: ", device);
            let text = "";
            if (device) {
                try {
                    let locations = device.getLocation();
                    let names = locations.map(loc => getLocationName(loc));
                    let sliced = false;
                    if (names.length > 3) {
                        names = names.slice(0, 3);
                        sliced = true;
                    }
                    if (names.length > 0) {
                        switch (notification.NData.Status) {
                            case NotificationStatus.INFO:
                                text = i18n.t("notificationsCenter.deviceInfoLocation");
                                break;
                            case NotificationStatus.ALERT:
                                text = i18n.t("notificationsCenter.deviceAlarmLocation");
                                break;
                            default:
                                text = "Nie rozpoznano ";
                                break;
                        }
                        return text + names.join(", ") + (sliced ? ", ..." : "");
                    }
                    switch (notification.NData.Status) {
                        case NotificationStatus.INFO:
                            text = i18n.t("notificationsCenter.deviceInfoText");
                            break;
                        case NotificationStatus.ALERT:
                            text = i18n.t("notificationsCenter.deviceAlarmText");
                            break;
                        default:
                            text = "Nie rozpoznano ";
                            break;
                    }
                    return text + device.Name;
                } catch (e) {
                    console.error(e);
                    return text + device.Name;
                }
            }
            return text;
        }
        case NotificationTypes.USG_NO_INSEMINATION:
            return i18n.t("notificationsCenter.usgNoInsemination");
        case NotificationTypes.BIRTH_NO_PREGNANCY:
            return i18n.t("notificationsCenter.birthNoPregnancy");
        case NotificationTypes.FALL_PIGLETS_WRONG_AMOUNT:
            return i18n.t("notificationsCenter.fallPigletsWrongAmount");
        case NotificationTypes.AUTO_CREATE_ANIMAL:
            return i18n.t("notificationsCenter.autoCreateAnimal");
        case NotificationTypes.BIRTH_NEGATIVE_USG:
            return i18n.t("notificationsCenter.birthNegativeUSG");
        case NotificationTypes.NO_PREGNANCY_NO_INSEMINATION:
            return i18n.t("notificationsCenter.noInseminationNoPregnancy");
        case NotificationTypes.MOMMY_WRONG_AMOUNT:
            return i18n.t("notificationsCenter.mommyWrongAmount");
        case NotificationTypes.TOO_FAST_INSEMINATION:
            return i18n.t("notificationsCenter.tooFastInsemination");
        default:
            return i18n.t("notRecognized") + " " + i18n.t("notificationsCenter.title");
    }
}

export function getMessage(notification) {
    let state = store.getState();

    switch (notification.NData.Type) {
        case NotificationTypes.PLACE:
            return notification.NData.Message;
        case NotificationTypes.NO_INSEMINATION: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t('notificationsCenter.animal'), animal.AnmNo1, i18n.t("notificationsCenter.birthNoInseminationText")];
            return i18n.t("notificationsCenter.birthNoInseminationTextNoAnimal");
        }
        case NotificationTypes.TOO_FAST_BIRTH: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.birthTooEarlyText")];
            return i18n.t("notificationsCenter.birthTooEarlyTextNoAnimal");
        }
        case NotificationTypes.SEPARATION_WRONG_AMOUNT: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.tooMuchPigletsText")];
            return i18n.t("notificationsCenter.tooMuchPigletsNoAnimal");
        }
        case NotificationTypes.TREATMENT: {
            let medicines = state.dictionary.medicine;
            console.log(medicines);
            let animalsAndMedicines = notification.NData.Treatment.Medicine.map(medicine => {
                let med = medicines.find(item => item.WordID === medicine);
                console.log(med);
                if (med) {
                    let dose = "";
                    if (med.WData.Dose && med.WData.Unit) {
                        dose = ` (${med.WData.Dose}${med.WData.Unit})`;
                    }
                    return [`${med.WData.Name}${dose}:`, "\r\n", notification.NData.Treatment.Animals.map(animal => {
                        let anm = animalsDB.getAnimalById(animal);
                        if (anm) {
                            return anm.AnmNo1
                        }
                    }).filter(item => item).join(", ")].join(" ");
                }
            });

            console.log(animalsAndMedicines);
            return [i18n.t("notificationsCenter.treatmentText1"), "\r\n", ...animalsAndMedicines];
        }
        case NotificationTypes.DEVICE_ALARM: {
            let text = [];
            if (notification.NData.Codes) {
                for (let code of notification.NData.Codes) {
                    let errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`);
                    if (errorCode) {
                        switch (code) {
                            case 5108:
                            case 5001:
                                let time = "-";
                                if (notification.NData.LastSeenTime) {
                                    time = moment(notification.NData.LastSeenTime).format("DD.MM.YYYY HH:mm");
                                }
                                let loc = "-";
                                if (notification.NData.Plcmnt) {
                                    let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.Plcmnt);
                                    if (location.length > 0) {
                                        loc = location[0].name;
                                    }
                                }
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {
                                    number: notification.NData.RFID || "-",
                                    location: loc,
                                    time: time
                                });
                                break;
                            case 5002:
                                let expectedLocation = "-";
                                if (notification.NData.ExpectedLocation) {
                                    let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.ExpectedLocation);
                                    if (location.length > 0) {
                                        expectedLocation = location[0].name;
                                    }
                                }
                                let currentLocation = "-";
                                if (notification.NData.CurrentLocation) {
                                    let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.CurrentLocation);
                                    if (location.length > 0) {
                                        currentLocation = location[0].name;
                                    }
                                }
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {
                                    number: notification.NData.RFID || "-",
                                    expectedLoc: expectedLocation,
                                    currentLoc: currentLocation
                                });
                                break;
                            case 5101:
                            case 5102:
                            case 5103:
                            case 5104:
                            case 5202:
                            case 5204:
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {number: notification.NData.Number || "-"});
                                break;
                            case 5107: {
                                let loc = "-";
                                if (notification.NData.PlcmntID) {
                                    let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.PlcmntID);
                                    if (location.length > 0) {
                                        loc = location[0].name;
                                    }
                                }
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {
                                    animal: notification.NData.RFID || "-",
                                    loc: loc
                                });
                                break;
                            }
                            case 1430: {
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`,{time:(notification.NData.Time?moment(notification.NData.Time).format("HH:mm"):"-")});
                                break;
                            }
                            case 5109: {
                                let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.PlcmntID);
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {location: location ? getLocationName(location) : "-"});
                                break;
                            }
                            case 3020:
                            case 3021:
                            case 3022:
                            case 3023:
                            case 3024:
                            case 3025:
                            case 3026:
                            case 3027:
                            case 3028:
                            case 3029:
                            case 3030:
                            case 3031:
                            case 3032:
                            case 3033:
                            case 3034:
                            case 3035:
                            case 3036:
                            case 3037:
                            case 3038:
                            case 3039:
                            case 3040:
                            case 3042:
                            case 3043:
                            case 3044:
                            case 3045:
                            case 3047:
                                errorCode = i18n.t(`notificationsCenter.errorCodes.${code}`, {value: notification.NData.Param});
                                break;
                            default:
                                break;
                        }
                        if (text.length > 0) {
                            errorCode = errorCode.charAt(0).toLowerCase() + errorCode.slice(1);
                        }
                        text.push(errorCode)
                    }
                }
            }
            return text.join(", ");
        }
        case NotificationTypes.USG_NO_INSEMINATION: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.usgNoInseminationText")];
            return i18n.t("notificationsCenter.usgNoInseminationTextNoAnimal");
        }
        case NotificationTypes.NO_PREGNANCY_NO_INSEMINATION: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.noInseminationNoPregnancyText")];
            return i18n.t("notificationsCenter.noInseminationNoPregnancyNoAnimal");
        }
        case NotificationTypes.BIRTH_NO_PREGNANCY: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.birthNoPregnancyText")].join('');
            return i18n.t("notificationsCenter.birthNoPregnancy");
        }
        case NotificationTypes.FALL_PIGLETS_WRONG_AMOUNT: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.fallPigletsWrongAmountText")].join('');
            return i18n.t("notificationsCenter.fallPigletsWrongAmount");
        }
        case NotificationTypes.AUTO_CREATE_ANIMAL: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            let location = animalsDB.getAnimalLocationsByPlcmntID(notification.NData.PlcmntID);
            if (animal && location.length > 0) {
                return i18n.t("notificationsCenter.autoCreateAnimalText", {
                    animal: animal.AnmNo1,
                    location: location.map(loc => loc.name).join(", ")
                });
            }
            if (animal) {
                return i18n.t("notificationsCenter.autoCreateAnimalText", {animal: animal.AnmNo1, location: "-"});
            }
            return i18n.t("notificationsCenter.autoCreateAnimalText", {animal: "-", location: "-"});
        }
        case NotificationTypes.BIRTH_NEGATIVE_USG: {
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t("notificationsCenter.birthNegativeUSGText")];
            return i18n.t("notificationsCenter.birthNegativeUSGTextNoAnimal");
        }
        case NotificationTypes.MOMMY_WRONG_AMOUNT:
            return i18n.t("notificationsCenter.mommyWrongAmountText");
        case NotificationTypes.TOO_FAST_INSEMINATION:
            let animal = animalsDB.getAnimalById(notification.NData.AnmID);
            if (animal)
                return [i18n.t("notificationsCenter.animal"), animal.AnmNo1, i18n.t('notificationsCenter.tooFastInseminationText')].join('');
            return i18n.t("notificationsCenter.tooFastInseminationNoAnimal");
        default:
            return i18n.t("notRecognized") + " " + i18n.t("notificationsCenter.message");
    }
}

export function getIcon(notification) {
    switch (notification.NData.Status) {
        case NotificationStatus.INFO:
            return <i className="fas fa-info-circle"/>;
        case NotificationStatus.ALERT:
            return <i className="fas fa-exclamation-circle"/>;
        case NotificationStatus.WARN:
            return <i className="fas fa-exclamation-triangle"/>;
        default:
            return <i className="fas fa-info"/>;
    }
}

export function getNotificationType(notification) {
    switch (notification.NData.Status) {
        case NotificationStatus.INFO:
            return "info";
        case NotificationStatus.ALERT:
            return "error";
        case NotificationStatus.WARN:
            return "warn";
        default:
            return "";
    }
}

export function getBigIcon(notification) {
    switch (notification.NData.Type) {
        case NotificationTypes.DEVICE_ALARM:
            let device = devicesDB.getDeviceByID(notification.NData.DeviceID);
            if (device) {
                switch (device.DevType) {
                    case DevType.DISPENSER_NRF:
                        return require("../resources/images/devices/dispenserNRF.svg").default;
                    case DevType.CAGE:
                        return require("../resources/images/devices/cage.svg").default;
                    case DevType.CLIMATE:
                        return require("../resources/images/devices/climateWST.svg").default;
                    case DevType.BRIDGE:
                        return require("../resources/images/devices/bridge.svg").default;
                    case DevType.SCALE:
                        return require("../resources/images/devices/weightWST.svg").default;
                    case DevType.DISPENSER:
                        return require("../resources/images/devices/dispenserWST.svg").default;
                    default:
                        return require("../resources/images/placeholder.svg").default;
                }
            }
            return require("../resources/images/placeholder.svg").default;
        case NotificationTypes.FALL_PIGLETS_WRONG_AMOUNT:
            return require("../resources/images/events/fall-piglets.svg").default;
        case NotificationTypes.SEPARATION_WRONG_AMOUNT:
            return require("../resources/images/events/separation-porkhouse.svg").default;
        case NotificationTypes.BIRTH_NEGATIVE_USG:
        case NotificationTypes.BIRTH_NO_PREGNANCY:
        case NotificationTypes.TOO_FAST_BIRTH:
            return require("../resources/images/events/parturition.svg").default;
        case NotificationTypes.TOO_FAST_INSEMINATION:
        case NotificationTypes.USG_NO_INSEMINATION:
        case NotificationTypes.NO_INSEMINATION:
        case NotificationTypes.NO_PREGNANCY_NO_INSEMINATION:
            console.log(JSON.stringify(require("../resources/images/events/insemination.svg")));
            return require("../resources/images/events/insemination.svg").default;
        case NotificationTypes.MOMMY_WRONG_AMOUNT:
            return require("../resources/images/events/mommy.svg").default;
        case NotificationTypes.TREATMENT:
            return require("../resources/images/events/treatment.svg").default;
        case NotificationTypes.AUTO_CREATE_ANIMAL:
            return require("../resources/images/events/automatic_add_animal.svg").default;
        default:
            return require("../resources/images/placeholder.svg").default;
    }
}

export function executeOnRead(notification) {
    if (notification.NData.Type === NotificationTypes.DEVICE_ALARM) {
        let device = devicesDB.getDeviceByID(notification.NData.DeviceID);
        if (device) {
            switch (device.DevType) {
                case DevType.DISPENSER_NRF:
                    setAlert(device, true);
                    break;
                case DevType.DISPENSER:
                    let setClearMotorProblemDispensers = [];
                    let setClearFeedProblemDispensers = [];
                    for (let code of notification.NData.Codes) {
                        // błedy silników
                        if (code >= 1100 && code <= 1220) {
                            let dispenser = code < 1200 ? code - 1100 : code - 1200;
                            if (!setClearMotorProblemDispensers.includes(dispenser))
                                setClearMotorProblemDispensers.push(dispenser);
                        }
                        // błedy karmienia
                        if (code >= 1300 && code < 1400) {
                            let dispenser = code - 1300;
                            if (!setClearFeedProblemDispensers.includes(dispenser))
                                setClearFeedProblemDispensers.push(dispenser);
                        }
                    }
                    for (let dispenser of setClearMotorProblemDispensers) {
                        setClearMotorProblem(device, dispenser);
                    }
                    for (let dispenser of setClearFeedProblemDispensers) {
                        setClearFeedProblem(device, dispenser);
                    }
                    break;
                default:
                    console.log("brak akcji dla urzadzenia");
                    break;
            }
        }
    }
}
