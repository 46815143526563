import firebase from "@firebase/app";
import config from "../conf/config";
import store from "../store/store"
import {registerSNSEndpoint} from "../actions/snsActions";
import {detect} from "detect-browser";
import '@firebase/messaging'

export const initializeFirebase = () => {
    let config = {
        apiKey: "AIzaSyC3MdMEF0T689-dCFBubO9gHFTK7GQzV5M",
        authDomain: "feturasns.firebaseapp.com",
        databaseURL: "https://feturasns.firebaseio.com",
        projectId: "feturasns",
        storageBucket: "feturasns.appspot.com",
        messagingSenderId: "1008420419627",
        appId: "1:1008420419627:android:1685a2929049113f"
    };
    firebase.initializeApp(config);
};

// TODO IOS Safari notifications
// https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/NotificationProgrammingGuideForWebsites/PushNotifications/PushNotifications.html#//apple_ref/doc/uid/TP40013225-CH3-SW1

export const askForPermisstionToReceiveNotifications = async (user) => {
    try {
        let status = await Notification.requestPermission();
        if (status === "granted") {
            const messaging = firebase.messaging();
            const token = await messaging.getToken();
            console.log("TOKEN", token);
            let browser = detect();
            let name = `${user.LocalUserID}_${browser.name}_${browser.os}`;
            store.dispatch(registerSNSEndpoint(config.sns.platformApplicationArn, token, name, user.ClientID, user.LocalUserID, user.FarmData, user.Roles));
            return token;
        }
    } catch (e) {
        console.error(e);
    }
};