import store from "../store/store";

export function getCreateDay() {
    try {
        let state = store.getState();
        return state.technologyGroup.createDay[0].createDay
    } catch (e) {
        return 0;
    }

}