import {cloneDeep, get, isArray, isObject, isString} from "lodash";
import store from "../store/store";
import {getBugsnagClient} from "../views/fallback-error-view/FallbackErrorView";

const _DEBUG = true;

/**
 * Funkcja przyjmująca cokolwiek* związanego z urządzeniem/ami i zwracająca odpowiednio sformatowane dane potrzebne przy wysyłaniu wiadomośći po MQTT
 * @param device: (Array<Object>, Array<String>, Object, String) - Lista/pojedyncze urządzenie lub jego ID
 * @returns {{LocalUserID: *, isValid: boolean, ClientID: *, GatewayID: *, DevID: Array, Name: string}}
 */
export function getIOTAttributes(device) {
    const state = store.getState();
    const LocalUserID = get(state, "user.user.LocalUserID");
    const ClientID = get(state, "user.user.ClientID");
    const devices = get(state, "farmDevices.devices");
    const getDeviceByID = (_id) => devices.find(d => d.DevID === _id);
    let DevID;
    let GatewayID;
    let name = '?';
    let isValid = false;
    _DEBUG && console.log("getIOTAttributes", device);
    try {
        //first check if our object is an instance of array
        if (isArray(device)) {
            _DEBUG && console.log("isArray", device);
            DevID = [];
            for (let element of device) {
                //check if object in array is a device or an ID
                if (isObject(element)) {
                    _DEBUG && console.log("isObject", element);
                    DevID.push(element.DevID);
                    if (!GatewayID) {
                        GatewayID = element.GatewayID
                    }
                    checkGateway(GatewayID, element.GatewayID);
                } else if (isString(element)) {
                    _DEBUG && console.log("isString", element);
                    let dev = getDeviceByID(element);
                    DevID.push(element);
                    if (dev) {
                        if (!GatewayID) {
                            GatewayID = dev.GatewayID;
                        }
                        checkGateway(GatewayID, dev.GatewayID);
                    }
                }
            }
        } else if (isObject(device)) {
            _DEBUG && console.log("isObject", device);
            DevID = device.DevID;
            GatewayID = device.GatewayID;
        } else if (isString(device)) {
            _DEBUG && console.log("isString", device);
            let dev = getDeviceByID(device);
            if (dev) {
                DevID = dev.DevID;
                GatewayID = dev.GatewayID;
            }
        }

        let tmp = getDeviceByID(isArray(DevID) && DevID.length > 1 ? GatewayID : isArray(DevID) ? DevID[0] : DevID);
        if (tmp && tmp.Name) {
            name = tmp.Name;
        }
        if (isArray(DevID) && DevID.length === 1) {
            DevID = DevID[0];
        }
        if (isArray(DevID)) {
            name = `${name} (${DevID.length})`
        }
        isValid = name && GatewayID && DevID && ClientID && LocalUserID;
    } catch (e) {
        console.error("getIOTAttributes EXCEPTION OCCURED: ", e);
        isValid = false;
    }
    const result = {
        LocalUserID: LocalUserID,
        ClientID: ClientID,
        DevID: DevID,
        GatewayID: GatewayID,
        Name: name,
        isValid: !!isValid
    };
    if (!isValid) {
        const missingKeys = Object.keys(result).filter(key => !result[key]);
        console.error(`getIOTAttributes MISSING KEYS: ${missingKeys.join(", ")}`);
        try {
            sendBugsnagMissingAttributes(missingKeys, result);
            console.log("sending report");
        } catch (e) {
            console.error(e)
        }
    }
    return result;
}

function sendBugsnagMissingAttributes(missingKeys, ids) {
    const {user: {user: {ClientID}}} = store.getState();
    if (ClientID !== 'TestNewRole') {
        let client = getBugsnagClient();
        client.notify("missing IOT attributes", {
            severity: 'error',
            metaData: {
                'feedback': {
                    user: ClientID,
                    missing: missingKeys,
                    ids: ids
                }
            }
        })
    }
}

/**
 * Funkcja sprawdza czy kolejny Gateway przypisany do urzadzenie nie rozni sie od wczesniejszego a jesli sie rozni to zwraca blad
 * @param GatewayID
 * @param NextGatewayID
 */
export function checkGateway(GatewayID, NextGatewayID) {
    if (GatewayID && GatewayID !== NextGatewayID) {
        throw Error(`Device with different GatewayID passed! Expected: ${GatewayID} found: ${NextGatewayID}`);
    }
}

/**
 * Funkcja zamiania tablice/pojedynczy DevID na fejkowe DevID bez ktorego backend nam nie odpowie
 * @param DevID
 * @param type
 * @returns {*}
 */
export function transformDevID(DevID, type = "") {
    if (isArray(DevID)) {
        return DevID.map(devID => `${devID}${type}`);
    } else {
        return `${DevID}${type}`;
    }
}


export function removeTrash(obj) {
    let tmp = cloneDeep(obj);
    const trash = ["device", "SetTime", "loadedDataFrom", "changed"];
    trash.forEach(key => {
        delete tmp[key];
    });
    return tmp;


}
