import moment from "moment";
import {convertLengthUnitTo, convertTemperatureUnitTo} from "./UnitUtils";
import {UnitTypes} from "../constans/unitTypes";

function getData(data, start, stop) {
    if (!stop) {
        return data.filter(item => item.AggTi >= start).sort((a, b) => +a.AggTi - +b.AggTi);
    }
    return data.filter(item => +item.AggTi >= start && +item.AggTi <= stop).sort((a, b) => +a.AggTi - +b.AggTi);
}

export function calculateDataForGainChart(settlement, cageData, shadowSettlement = null, sliderValue = null) {
    let insertionData = getData(cageData, settlement.DtaStartTime, settlement.DtaEndTime);
    let gain = [], weightInGrams = [];
    let data = {};
    for (let item of insertionData) {
        let dateStr = moment(parseInt(item.AggTi));
        try {
            if (weightInGrams.filter(data => ((moment(data.name, "DD.MM.YYYY").format("MMDDYYYY")) === moment(dateStr).format("MMDDYYYY"))).length === 0) {
                weightInGrams.push({
                    "name": dateStr.format("DD.MM.YYYY"),
                    "all": item.AggDt[0].WeightExAll || 0,
                    "left": item.AggDt[0].WeightExLeft,
                    "right": item.AggDt[0].WeightExRight,
                    "middle": item.AggDt[0].WeightExMid,
                });
            }
        } catch (e) {
            console.error(e);
            weightInGrams.push({
                "name": dateStr.format("DD.MM.YYYY"),
                "all": 0,
                "left": 0,
                "right": 0,
                "middle": 0
            });
        }
    }
    gain.push({
        "name": weightInGrams[0].name,
        "all": 0,
        "left": 0,
        "right": 0,
        "middle": 0
    });
    for (let i = 1; i < weightInGrams.length; i++) {
        let diffDays = moment(weightInGrams[i].name, "DD.MM.YYYY").startOf('day').diff(moment(weightInGrams[i - 1].name, "DD.MM.YYYY").startOf('day'), 'days');
        gain.push({
            "name": weightInGrams[i].name,
            "all": (weightInGrams[i].all === 0 || weightInGrams[i - 1].all === 0) ? 0 : ((weightInGrams[i].all - weightInGrams[i - 1].all) / diffDays),
            "left": (weightInGrams[i].left === 0 || weightInGrams[i - 1].left === 0) ? 0 : ((weightInGrams[i].left - weightInGrams[i - 1].left) / diffDays),
            "right": (weightInGrams[i].right === 0 || weightInGrams[i - 1].right === 0) ? 0 : ((weightInGrams[i].right - weightInGrams[i - 1].right) / diffDays),
            "middle": (weightInGrams[i].middle === 0 || weightInGrams[i - 1].middle === 0) ? 0 : ((weightInGrams[i].middle - weightInGrams[i - 1].middle) / diffDays)
        })
    }

    if (shadowSettlement) {
        let shadowInsertionData = getData(cageData, shadowSettlement.DtaStartTime, shadowSettlement.DtaEndTime);
        let firstValueAndIndex = findIndexWithFirstAverageWeight(insertionData);
        if (sliderValue === null) {
            data.closestIndex = firstValueAndIndex.index;
            sliderValue = firstValueAndIndex.index;
        }
        let closestIndex = findClosestValueForShadowData(shadowInsertionData, firstValueAndIndex.value);
        let firstIndexShadow = closestIndex - sliderValue;
        let before = calculateDataForGainChart(shadowSettlement, cageData).gain;
        for (let i = 0; i < gain.length; i++) {
            try {
                let item = before[firstIndexShadow];
                gain[i].allShadow = item.all;
                gain[i].leftShadow = item.left;
                gain[i].middleShadow = item.middle;
                gain[i].rightShadow = item.right;
            } catch (e) {
                gain[i].allShadow = 0;
                gain[i].leftShadow = 0;
                gain[i].middleShadow = 0;
                gain[i].rightShadow = 0;
            }
            firstIndexShadow++;
        }
    }
    data.gain = gain;
    return data;
}

export function calculateDataForCo2Chart(currentDay) {
    let co2 = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.CO2 !== undefined) {
            co2.push({
                name: row.T,
                co2: row.CO2
            });
        }
    }
    return {
        co2, lastTime
    };
}

export function calculateDataForH2sChart(currentDay) {
    let h2s = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.H2S !== undefined) {
            h2s.push({
                name: row.T,
                h2s: row.H2S
            });
        }
    }
    return {
        h2s, lastTime
    };
}

export function calculateDataForHourlyChart(cageData, date) {
    let data = cageData.filter(item => +item.AggTi === date)[0];
    let hourly = [];
    for (let i = 0; i < 24; i++) {
        hourly.push({
            "name": i,
            "left": data.AggDt[0].VisLeft[i],
            "right": data.AggDt[0].VisRight[i],
            "all": data.AggDt[0].VisAll ? data.AggDt[0].VisAll[i] : 0,
            "middle": data.AggDt[0].VisMid[i]
        })
    }
    return hourly;
}

export function calculateDataForHumidityChart(currentDay) {
    let humidity = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.Humid !== undefined) {
            humidity.push({
                name: row.T,
                humidity: row.Humid,
                temperature: row.Temp
            });
        }
    }
    return {
        humidity, lastTime
    };
}

export function calculateDataForNh3Chart(currentDay) {
    let nh3 = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.NH3 !== undefined) {
            nh3.push({
                name: row.T,
                nh3: row.NH3
            });
        }
    }
    return {
        nh3, lastTime
    };
}

export function calculateDataForPassageChart(cageData, date, accuracy, applyShadow = false, shadowDate = null) {
    let d = {};
    let data = cageData.filter(item => +item.AggTi === date)[0];
    d.data = data;
    let counter = 10;
    let passage = [];
    if (accuracy === 0.5) {
        passage = data.AggDt[0].VisWeight.map(item => {
            let tmp = {"name": counter, "amount": item};
            counter += data.AggDt[0].VisWeight.length === 171 ? 1 : 0.5;
            return tmp;
        });
    } else if (accuracy === 1) {
        for (let i = 0; i < data.AggDt[0].VisWeight.length; data.AggDt[0].VisWeight.length === 171 ? i++ : i += 2) {
            if (data.AggDt[0].VisWeight.length === 171) {
                let item = data.AggDt[0].VisWeight[i];
                passage.push({
                    name: i,
                    amount: item
                })
            } else {
                let item1 = data.AggDt[0].VisWeight[i];
                let item2 = data.AggDt[0].VisWeight[i + 1];
                passage.push({
                    name: counter,
                    amount: item1 + item2
                });
                counter++;
            }
        }
    }

    if (applyShadow) {
        let dataShadow = calculateDataForPassageChart(cageData, shadowDate, accuracy);
        d.dataShadow = dataShadow.data;
        let passageShadow = dataShadow.passage;
        if (passage.length !== passageShadow.length) {
            if (passage.length < passageShadow.length) {
                let iterator = 0;
                for (let i = 0; i < passage.length; i++) {
                    passage[i].amountShadow = passageShadow[iterator].amount + passageShadow[iterator].amount;
                    iterator += 2;
                }
            } else {
                let iterator = 0;
                let pass = [];
                for (let i = 0; i < passageShadow.length; i++) {
                    pass.push({
                        name: passage[iterator].name,
                        amount: passage[iterator].amount + passage[iterator].amount,
                        amountShadow: passageShadow[i].amount
                    });
                    iterator += 2;
                }
                passage = pass;
            }
        } else {
            for (let i = 0; i < passage.length; i++) {
                passage[i].amountShadow = passageShadow[i].amount;
            }
        }
    }
    d.passage = passage;
    return d;
}

export function calculateDataForPassageInDaysChart(settlement, cageData, shadowSettlement = null, sliderValue = null) {
    let insertionData = [];
    if (settlement.DtaEndTime) {
        insertionData = cageData.filter(item => +item.AggTi >= settlement.DtaStartTime && +item.AggTi <= settlement.DtaEndTime);
    } else {
        insertionData = cageData.filter(item => +item.AggTi >= settlement.DtaStartTime);
    }
    insertionData.sort((a, b) => +a.AggTi - +b.AggTi);
    let passage = [];
    let data = {};
    for (let data of insertionData) {
        try {
            passage.push({
                name: moment(data.AggTi).format("DD.MM.YYYY"),
                all: data.AggDt[0].VisAll ? data.AggDt[0].VisAll.reduce((a, b) => a + b, 0) : 0,
                left: data.AggDt[0].VisLeft.reduce((a, b) => a + b, 0),
                middle: data.AggDt[0].VisMid.reduce((a, b) => a + b, 0),
                right: data.AggDt[0].VisRight.reduce((a, b) => a + b, 0)
            })
        } catch (e) {
            console.error(e);
        }
    }
    if (shadowSettlement) {
        let shadowInsertionData = getData(cageData, shadowSettlement.DtaStartTime, shadowSettlement.DtaEndTime);
        let firstValueAndIndex = findIndexWithFirstAverageWeight(insertionData);
        if (sliderValue === null) {
            data.closestIndex = firstValueAndIndex.index;
            sliderValue = firstValueAndIndex.index;
        }
        let closestIndex = findClosestValueForShadowData(shadowInsertionData, firstValueAndIndex.value);
        let firstIndexShadow = closestIndex - sliderValue;
        for (let i = 0; i < passage.length; i++) {
            try {
                let data = shadowInsertionData[firstIndexShadow];
                passage[i].allShadow = data.AggDt[0].VisLeft ? data.AggDt[0].VisLeft.reduce((a, b) => a + b, 0) : 0;
                passage[i].leftShadow = data.AggDt[0].VisLeft.reduce((a, b) => a + b, 0);
                passage[i].middleShadow = data.AggDt[0].VisMid.reduce((a, b) => a + b, 0);
                passage[i].rightShadow = data.AggDt[0].VisRight.reduce((a, b) => a + b, 0);
            } catch (e) {
                passage[i].allShadow = 0;
                passage[i].leftShadow = 0;
                passage[i].middleShadow = 0;
                passage[i].rightShadow = 0;
            }
            firstIndexShadow++;
        }
    }
    data.passage = passage;
    return data;
}

export function calculateDataForPressureChart(currentDay) {
    let pressure = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.CoolPress !== undefined) {
            pressure.push({
                name: row.T,
                pressure: row.CoolPress
            });
        }
    }
    return {
        pressure, lastTime
    };
}

export function calculateDataForScaleChart(scaleData, date) {
    let currentDay = scaleData.filter(item => item.AggTi === date)[0];
    let data = [];
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            if (row.W !== undefined) {
                data.push({
                    name: moment(row.T).format("HH:mm"),
                    weight: row.W
                })
            }
        }
    }
    return data;
}

export function calculateDataForScaleDurationChart(scaleData, start, end) {
    console.log(scaleData);
    let durationData = scaleData.filter(item => item.AggTi >= start && item.AggTi <= end).sort((o1, o2) => o1.AggTi - o2.AggTi);
    console.log(durationData);
    let data = [];
    for (const day of durationData) {
        let averageWeight = 0;
        let counter = 0;
        for (let row of day.AggDt) {
            if (row.W !== undefined) {
                averageWeight += row.W;
                counter++;
            }
        }
        // _.get(day, "AggData.Weights", []).forEach(hour => {
        //     let status = _.get(hour, `Statuses[${index}]`, -1);
        //     if (status === 1) {
        //         averageWeight += _.get(hour, `Weights[${index}]`, 0);
        //         counter++;
        //     }
        // });
        if (counter) {
            data.push({
                name: moment.utc(day.AggTi).format("DD.MM"),
                weight: Math.round(averageWeight / counter)
            })
        }

    }
    return data;
}

export function calculateDataForSlurryChart(currentDay) {
    let slurry = [];
    let lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    for (let row of currentDay.AggDt) {
        if (row.Slur !== undefined) {
            slurry.push({
                name: row.T,
                slurry: row.Slur
            });
        }
    }
    return {
        slurry, lastTime
    };
}

export function calculateDataForTemperatureChart(climateData, date) {
    let temps = new Map();
    let lastTime = 0;
    let currentDay = climateData.filter(item => item.AggTi === date)[0];
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            temps.set(row.T, {
                name: row.T,
                temperature: row.Temp,
                temperatureDesired: row.TempR,
                minTemp: row.MinT,
                maxTemp: row.MaxT
            })
        }
        lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    }
    return {
        temps,
        lastTime
    };
}

export function calculateDataForVentilationChart(climateData, date) {
    let vents = new Map();
    let lastTime = 0;
    let currentDay = climateData.filter(item => item.AggTi === date)[0];
    if (currentDay) {
        for (let row of currentDay.AggDt) {
            vents.set(row.T, {
                name: row.T,
                temperature: row.Temp,
                ventilation: row.V
            })
        }
        lastTime = moment(currentDay.AggDt[currentDay.AggDt.length - 1].T).hour();
    }
    return {
        vents, lastTime
    };
}

function findClosestValueForShadowData(data, value) {
    let closestIndex;
    let closestValue;
    for (let [index, item] of data.entries()) {
        console.log(item);
        if (closestValue === undefined) {
            closestValue = item.AggDt[0].WeightExAll || 0;
        } else {
            if (Math.abs((item.AggDt[0].WeightExAll || 0) - value) < Math.abs(closestValue - value)) {
                closestValue = item.AggDt[0].WeightExAll;
                closestIndex = index;
            }
        }
    }
    return closestIndex;
}

function findIndexWithFirstAverageWeight(data) {
    try {
        let firstValueIndex = 0;
        let firstValue;
        let failedData = 0;
        try {
            firstValue = data[0].AggData.WeightsOnExits[0];
        } catch (e) {
            firstValue = 0;
        }
        for (let i = 0; i < data.length - 1; i++) {
            if (data[i].AggData.WeightsOnExits && data[i].AggData.WeightsOnExits.length === 4) {
                if (data[i].AggData.WeightsOnExits[0] === 0 && data[i + 1].AggData.WeightsOnExits[0] !== 0) {
                    firstValue = data[i + 1].AggData.WeightsOnExits[0];
                    firstValueIndex = i + 1;
                    break;
                }
            } else {
                failedData++;
            }
        }
        return {
            index: firstValueIndex - failedData,
            value: firstValue
        }
    } catch (e) {
        return {
            index: 0,
            value: 0
        }
    }
}

export function calculateDataForWeightChart(settlement, cageData, shadowSettlement = null, sliderValue = null) {
    let insertionData = getData(cageData, settlement.DtaStartTime, settlement.DtaEndTime);
    let data = {};
    let weight = [];
    for (let item of insertionData) {
        const dateStr = moment(item.AggTi);
        try {
            if (weight.filter(data => ((moment(data.name, "DD.MM.YYYY").format("MMDDYYYY")) === moment(dateStr).format("MMDDYYYY"))).length === 0) {
                weight.push({
                    "name": dateStr.format("DD.MM.YYYY"),
                    "all": item.AggDt[0].WeightExAll || 0,
                    "left": item.AggDt[0].WeightExLeft,
                    "right": item.AggDt[0].WeightExRight,
                    "middle": item.AggDt[0].WeightExMid,
                });
            }
        } catch (e) {
            console.error(e);
            weight.push({
                "name": dateStr.format("DD.MM.YYYY"),
                "all": 0,
                "left": 0,
                "right": 0,
                "middle": 0,
            })
        }
    }
    if (shadowSettlement) {
        let shadowInsertionData = getData(cageData, shadowSettlement.DtaStartTime, shadowSettlement.DtaEndTime);
        let firstValueAndIndex = findIndexWithFirstAverageWeight(insertionData);
        if (sliderValue === null) {
            data.closestIndex = firstValueAndIndex.index;
            sliderValue = firstValueAndIndex.index;
        }
        let closestIndex = findClosestValueForShadowData(shadowInsertionData, firstValueAndIndex.value);
        let firstIndexShadow = closestIndex - sliderValue;
        for (let i = 0; i < weight.length; i++) {
            let item = shadowInsertionData[firstIndexShadow];
            try {
                weight[i].allShadow = item.AggDt[0].WeightExAll || 0;
                weight[i].leftShadow = item.AggDt[0].WeightExLeft;
                weight[i].rightShadow = item.AggDt[0].WeightExRight;
                weight[i].middleShadow = item.AggDt[0].WeightExMid;
            } catch (e) {
                weight[i].allShadow = 0;
                weight[i].leftShadow = 0;
                weight[i].rightShadow = 0;
                weight[i].middleShadow = 0;
            }
            firstIndexShadow++;
        }
    }
    data.weight = weight;
    return data;
}

export function calculateRFIDChart(cageData, rfid, settlement) {
    let data = settlement.DtaEndTime ? cageData.filter(item => item.AggTi >= settlement.DtaStartTime && item.AggTi <= settlement.DtaEndTime) : cageData.filter(item => item.AggTi >= settlement.DtaStartTime);
    data.sort((a, b) => a.AggTi - b.AggTi);
    let passes = [];
    for (let d of data) {
        let passesForRFID = d.AggDt[0].RFIDPass.find(item => item.RFID === rfid);
        if (passesForRFID) {
            for (let pass of passesForRFID.P) {
                if (pass.W > 0) {
                    passes.push({
                        name: moment(pass.T).format("HH:mm"),
                        weight: pass.W
                    })
                }
            }
        }
    }
    return passes;
}

export function calculateDataForClimateSettingsChart(climateData) {
    console.log(climateData);
    let lastTime;
    let data;
    try {
        data = climateData.AggDt.map(item => ({
            name: item.T,
            curveActive: item.CAct,
            worktype: item.WType
        }));
        let lastItem = data[data.length - 1];
        lastTime = moment(lastItem.name).hour();
    } catch (e) {
        console.error(e)
        lastTime = 0;
        data = [];
    }
    return {
        data,
        lastTime: lastTime
    }

}

export function temperatureConverter(temperature) {
    return convertTemperatureUnitTo(parseFloat(temperature), {
        showUnit: false,
        rawValue: true,
        acceptNil: true,
        fixed: 1,
        unit: UnitTypes.SMALL
    })
}

export function lengthConverter(length) {
    return convertLengthUnitTo(parseFloat(length), {
        showUnit: false,
        rawValue: true,
        acceptNil: true,
        fixed: 1,
        unit: UnitTypes.MEDIUM
    })

}

export function tickFormatter(value) {
    return moment(value).format("HH:mm");
}
