import {get, isArray, isNil, isNumber, isObject, set} from "lodash";
import store from "../store/store";

export const ReactLocalStorage = {
    /**
     * Zapisuje klucz wartość do localStorage
     * @param key
     * @param value
     * @return {boolean}
     */
    set: (key, value) => {
        const valueAsString = JSON.stringify(value);
        localStorage.setItem(key, valueAsString);
        return true;
    },
    /**
     * Pobiera wraz z zamianią na odpowiedni typ wartość z localStorage
     * @param key
     * @param defaultValue
     * @return {number|boolean|any}
     */
    get: (key, defaultValue) => {
        const value = JSON.parse(localStorage.getItem(key));
        if (isNil(value)) return defaultValue; //jesli nie znajdziemy
        if (isArray(value) || isObject(value)) return value;
        if (["true", "false"].includes(value)) return value === "true"; //jesli jest booleanem
        if (isNumber(+value)) return +value; //numer
        return value; //string
    },
    /**
     * Zapisuje klucz-wartość do localStorage dla aktualnego użytkownika
     * @param key
     * @param value
     * @return {boolean}
     */
    setPrivate: (key, value) => {
        const LocalUserID = get(store.getState(), "user.user.LocalUserID");
        if (!LocalUserID) return false;
        let userObject = ReactLocalStorage.get(`$_${LocalUserID}`, {});
        if (!isObject(userObject)) {
            userObject = {};
        }
        set(userObject, key, value);
        ReactLocalStorage.set(`$_${LocalUserID}`, userObject);
        return true;

    },
    /**
     * Odcytuje wartość dla podanego klucza dla aktualnego użytkownika
     * @param key
     * @param defaultValue
     * @return {Exclude<object[keyof object], undefined>|*}
     */
    getPrivate: (key, defaultValue) => {
        const LocalUserID = get(store.getState(), "user.user.LocalUserID");
        if (!LocalUserID) return defaultValue;
        let userObject = ReactLocalStorage.get(`$_${LocalUserID}`, {});
        if (!isObject(userObject)) {
            userObject = {};
        }
        return get(userObject, key, defaultValue);
    },
    /**
     * Czysci 'prywatny' localStorage dla każdego z użytkowników
     */
    clearPrivate: () => {
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith("$_")) {
                localStorage.removeItem(key);
            }
        })
    },
    /**
     * Czyści cały localStorage
     */
    clear: () => localStorage.clear()
}
