import {CycleActions} from "../constans/cycleActions";
import {
    getMaxDelayForBirth,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy
} from "./SettingsUtils";
import i18next from "i18next";

/**
 * Funkcja tlumacząca powód błędu, który wystąpił w danym cyklu (wierszu).
 * @param reason
 * @param additionalData
 * @returns {string|*}
 */
export function getCycleReason(reason, additionalData) {
    switch (reason) {
        case CycleActions.USG_BEFORE_X_DAYS:
            return i18next.t("cycleReasons.usgBeforeXDays", {count: getTimeFromInseminationToPregnancy()});
        case CycleActions.PARTURITION_BEFORE_X_DAYS:
            return i18next.t("cycleReasons.parturitionBeforeXDays", {count: getTimeFromInseminationToPartuition()});
        case CycleActions.PARTURITION_AFTER_X_DAYS:
            return i18next.t("cycleReasons.parturitionAfterXDays", {count: getTimeFromInseminationToPartuition() + getMaxDelayForBirth()});
        case CycleActions.FIRST_INSEMINATION_FROM_BIRTH:
            return i18next.t("cycleReasons.firstInseminationFromBirth");
        case CycleActions.NO_INSEMINATION_BEFORE_EV:
            return i18next.t("cycleReasons.noInseminationFromBirth");
        case CycleActions.NO_USG_BEFORE_EV:
            return i18next.t("cycleReasons.noUsgBeforeEv");
        case CycleActions.NO_PARTURITION_AFTER_EV:
            return i18next.t("cycleReasons.noParturitionAfterEv");
        case CycleActions.NO_SEPARATION_AFTER_EV:
            return i18next.t("cycleReasons.noSeparationAfterEv");
        case CycleActions.NO_PARTURITION_AFTER_X_DAYS:
            return i18next.t("cycleReasons.noParturitionAfterXDays", {count: getTimeFromInseminationToPartuition()});
        case CycleActions.SEPARATION_CNT_BIGGER_THAN_PARTURITION_CNT:
            return i18next.t("cycleReasons.separationCntBiggerThanParturitionCnt");
        case CycleActions.SEPARATION_CNT_BIGGER_THAN_BIRTH_CNT:
            return i18next.t("cycleReasons.separationCntBiggerThanBirthCnt");
        case CycleActions.PIGLET_CNT_BIGGER_THAN_NIPPLES:
            return i18next.t("cycleReasons.pigletCntBiggerThanNipples");
        case CycleActions.NO_SEPARATION_BUT_SOW_CYCLES:
            return i18next.t("cycleReasons.noSeparationButSowCycles", {lastSeparation: additionalData.lastSeparation});
        case CycleActions.LAST_SEPARATION_DIFF_THAN_IN_REALITY:
            return i18next.t("cycleReasons.lastSeparationDiffrentThanInReality");
        default:
            return '';
    }
}
