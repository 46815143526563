import {cloneDeep, get, isArray, isEqual, isFinite, isNil, isString} from "lodash";
import {DevType} from "../constans/devices";
import {setChamberDevices} from "../IOT/device-functions/GatewayFunctions";
import store from "../store/store";
import {myID} from "../libs/generateID";
import {groupDevicesByGatewayID} from "./DevicesUtils";
import {SectorType} from "../constans/sectorTypes";
import {Level} from "../constans/levelTypes";
import buildingsDB from "../database/buildingsDB";

export function updateDispenserRFIDListOnGateways(updatedLocation) {
    try {
        if (updatedLocation.CID) {
            let devices = get(updatedLocation, "Devices", []).map(d => d.DevID);
            if (devices.length) {
                devices = groupDevicesByGatewayID(devices);
                if (devices) {
                    for (let [gatewatID, devList] of devices.entries()) {
                        setChamberDevices(gatewatID, {
                            [getLocationID(updatedLocation)]: devList[DevType.DISPENSER_NRF].map(d => d.DevID)
                        })
                    }
                }
            }
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 *
 * @param name - Nazwa komory
 * @param individualFeeding - Indiwidualne karmienie
 * @param createStandings - Czy ma stworzyć stanowiska
 * @param chamberSize - Rozmiar komory
 * @param standingsInRow - Ilość stanowisk w rzędzie
 * @param standingsOrder - Rodzaj wyświetlania stanowiska 0-7
 * @param devices - Lista urządzeń w formacie [{DevID: "sdadada", Adr: 1}
 * @param standingsName - Nazwa początkowa stanowisk
 * @param standingsFrom - Liczba początkowa stanowisk
 * @param standingsAmount - Ilość stanowisk
 * @param standings - Lista stanowisk
 * @param id
 * @returns {{CName: *, CID: (*|string)}}
 */
export function generateChamber({
                                    name,
                                    individualFeeding,
                                    createStandings,
                                    chamberSize,
                                    standingsInRow,
                                    standingsOrder,
                                    devices,
                                    standingsName,
                                    standingsFrom,
                                    standingsAmount,
                                    standings,
                                    id
                                }) {
    const chamber = {
        CName: name,
        CID: id || myID()
    };
    //jesli jest to karmienie indywidualne
    if (individualFeeding) {
        chamber.IndividualFeeding = true;
        if (!(isFinite(standingsInRow) && standingsInRow > 0 && isFinite(standingsOrder) && standingsOrder >= 0 && standingsOrder <= 7)) {
            throw new Error(`ValidationError expected: standingsInRow number > 0, standingsOrder number <0...7> but got ${standingsInRow} ${standingsOrder}`)
        }
        chamber.StandsInRow = +standingsInRow;
        chamber.StandsOrder = +standingsOrder;
        chamber.Boxes = standings || [];
        if (devices) {
            if (devices.filter(dev => !dev.DevID).length) {
                throw new Error('ValidationError expected: devices [{DevID: string, Adr: number opt}]')
            }
            chamber.Devices = devices;
        } else {
            delete chamber.Devices;
        }
        if (createStandings) {
            chamber.Boxes = [...chamber.Boxes, ...generateStandings({
                name: standingsName,
                startFrom: +standingsFrom,
                amount: +standingsAmount
            })]
        }

    } else {
        if (typeof standingsAmount == 'number' && chamberSize > 0) {
            chamber.CSize = +chamberSize;
        } else {
            throw Error('ValidationError chamberSize (should be number & > 0')
        }
    }
    return chamber;
}


/**
 * Create standings with given paremeters
 * @param name
 * @param amount
 * @param startFrom
 * @returns {Array}
 */
export function generateStandings({name, amount, startFrom}) {
    let boxes = [];
    if (isFinite(amount) && isFinite(startFrom) && amount > 0) {
        for (let i = +startFrom; i < +startFrom + +amount; i++) {
            let box = {
                BoxesName: `${name ? name : ''} ${i}`,
                BID: myID()
            };
            boxes.push(box);
        }
    } else {
        throw new Error(`ValidationError expected: amount - number > 0, startFrom number got ${amount} ${startFrom}`)
    }
    return boxes;
}

/**
 *
 * @param name
 * @param type
 * @param chambers
 * @returns {{SType: *, SName: *, SID: string, Chambers: Array}}
 */
export function generateSector({name, type, chambers}) {
    if (name && isFinite(type)) {
        return {
            SID: myID(),
            SName: name,
            SType: type,
            Chambers: chambers || []
        };
    } else {
        throw new Error(`ValidationError expected name - string, type - number but got ${name} ${type}`)
    }

}

export function generateBuilding({FarmID, name, sectors}) {
    const timestamp = +new Date();
    if (FarmID && name) {
        return {
            FarmID: FarmID,
            BgID: myID(),
            BName: name,
            Sectors: sectors || [],
            DtaCrtTime: timestamp,
            DtaModTime: timestamp
        };
    } else {
        throw new Error(`ValidationError expected name - string, FarmID - string but got ${name} ${FarmID}`)
    }

}


export function getLocationName(location = {}) {
    return location.CName || location.BName || location.SName || location.FarmName || location.BoxesName
}

export function getLocationID(location = {}) {
    return location.CID || location.SID || location.BID || location.BgID || location.FarmID
}


export function flatLocations(location, initialLocations = []) {
    // console.time("getFlatLocations");
    if (!location) {
        // console.timeEnd("getFlatLocations");
        return initialLocations;
    }
    let locations = initialLocations;
    if (getLocationID(location)) {
        locations.push(location);
    }
    let possibleChildKeys = ["Boxes", "Sectors", "Chambers", "Buildings"];
    possibleChildKeys.forEach(key => {
        if (location[key]) {
            location[key].forEach(child => {
                locations = flatLocations(child, locations)
            })
        }
    });
    console.timeEnd("getFlatLocations");
    return locations;
}

export function getFlatLocations(location) {
    return flatLocations(cloneDeep(location));
}

export function getActiveFarmID() {
    try {
        return store.getState().location.farm;
    } catch (err) {
        console.error(err);
    }
}

export function checkIfPlmntIDIncludesLocationID(plcmntID, locationID) {
    console.log("checkIfPlmntIDIncludesLocationID", arguments);
    if (isString(plcmntID)) {
        return plcmntID === locationID;
    } else if (isArray(plcmntID)) {
        return plcmntID.map(p => p.PlcmntID).includes(locationID)
    }
    return false;
}

export function addForageIDonChambers(buildings, newForageID, chamberIDList) {
    return locationsAttributeSwapper(buildings, "forageID", (location, oldForageID, level) => {
        if (location && level === Level.CHAMBER && chamberIDList.includes(location.CID)) {
            return true;
        }
        return false;
    }, newForageID)
}

export function swapForageIDonChambers(buildings, newForageID, oldForageID) {
    return locationsAttributeSwapper(buildings, "forageID", (location, forageID, level) => {
        if (location && level === Level.CHAMBER && forageID === oldForageID) {
            return true
        }
        return false;

    }, newForageID)
}

function locationsAttributeSwapper(buildings = [], attributeName, checkFunction = () => true, swapTo = null) {
    const buildingsOrg = cloneDeep(store.getState().farms.buildings);
    const buildingsCopy = cloneDeep(buildings);

    const check = (location, level) => {
        return !!checkFunction(location, location[attributeName], level)
    };

    for (let buildingIndex = 0; buildingIndex < buildingsCopy.length; buildingIndex++) {
        if (check(buildingsCopy[buildingIndex], Level.BUILDING)) {
            if (swapTo === null) {
                delete buildingsCopy[buildingIndex][attributeName];
            } else {
                buildingsCopy[buildingIndex][attributeName] = swapTo;
            }
        }
        if (buildingsCopy[buildingIndex].Sectors) {
            for (let sectorIndex = 0; sectorIndex < buildingsCopy[buildingIndex].Sectors.length; sectorIndex++) {
                if (check(buildingsCopy[buildingIndex].Sectors[sectorIndex], Level.SECTOR)) {
                    if (swapTo === null) {
                        delete buildingsCopy[buildingIndex].Sectors[sectorIndex][attributeName];
                    } else {
                        buildingsCopy[buildingIndex].Sectors[sectorIndex][attributeName] = swapTo;
                    }
                }
                if (buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers) {
                    for (let chamberIndex = 0; chamberIndex < buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers.length; chamberIndex++) {
                        if (check(buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex], Level.CHAMBER)) {
                            if (swapTo === null) {
                                delete buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex][attributeName];
                            } else {
                                buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex][attributeName] = swapTo;
                            }
                        }
                        if (buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex].Boxes) {
                            for (let boxIndex = 0; boxIndex < buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex].Boxes.length; boxIndex++) {
                                if (check(buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex].Boxes[boxIndex], Level.BOX)) {
                                    if (swapTo === null) {
                                        delete buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex].Boxes[boxIndex][attributeName];
                                    } else {
                                        buildingsCopy[buildingIndex].Sectors[sectorIndex].Chambers[chamberIndex].Boxes[boxIndex][attributeName] = swapTo;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return buildingsCopy.filter(cpy => !buildingsOrg.find(org => isEqual(org, cpy)));

}

/**
 * Zwraca ilość miejsc/stanowisk pogrupowane dla każdego rodzaju sektora
 * @param buildings - lista budynkow z stora (store.farms.buildings)
 * @return {{all: number}}
 */
export function getMaxAnimalCapacity(buildings = []) {
    const result = {all: 0};
    for (let SType of Object.values(SectorType)) {
        result[SType] = 0;
    }
    buildings.forEach(building => {
        get(building, "Sectors", []).forEach(sector => {
            get(sector, "Chambers", []).forEach(chamber => {
                const SType = sector.SType;
                if (SType) {
                    if (chamber.IndividualFeeding) {
                        get(chamber, "Boxes", []).forEach(box => {
                            if (box) {
                                result[SType] += 1;
                            }
                        })
                    } else {
                        result[SType] += (chamber.CSize || 0);
                    }
                }
            })
        })
    });
    const total = Object.values(result).reduce((a, b) => a + b, 0);
    return {
        ...result,
        all: total
    }
}

export const getLocationNameByBuildingMapSelector = (map, id) => {
    const object = map.get(id);
    if (!object) return null;
    return object.name.join(" - ") || "?";
}


export const getPlcmntIDsByDevice = ({device, index = null}) => {
    try {
        console.log(device, index, " E A E")
        return get(device, "PlcmntID", []).filter(item => {
            return !isNil(index) ? (item.Adr === index) : true
        }).map(item => item.PlcmntID)
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const getAllChildrenForLocationID = (locationID) => {
    try {
        let childrenIDs = [];
        const location = buildingsDB.getLocationByID(locationID);
        if (!!location) {
            const locationTree = buildingsDB.getTreeByLocationID(locationID);
            if (!!get(locationTree, "building") && !!get(location, "BgID")) {
                get(locationTree, "building.Sectors", []).forEach((sector) => {
                    childrenIDs.push({PlcmntID: sector.SID});
                    get(sector, "Chambers", []).forEach((chamber) => {
                        childrenIDs.push({PlcmntID: chamber.CID});
                        get(chamber, "Boxes", []).forEach((box) => {
                            childrenIDs.push({PlcmntID: box.BID});
                        });
                    });
                });
            } else if (!!get(locationTree, "sector") && !!get(location, "SID")) {
                get(locationTree, "sector.Chambers", []).forEach((chamber) => {
                    childrenIDs.push({PlcmntID: chamber.CID});
                    get(chamber, "Boxes", []).forEach((box) => {
                        childrenIDs.push({PlcmntID: box.BID});
                    })
                });
            } else if (!!get(locationTree, "chamber") && !!get(location, "CID")) {
                if (!get(locationTree, "Boxes")) { // karmienie grupowe
                    childrenIDs.push({PlcmntID: location.CID})
                }
                get(locationTree, "chamber.Boxes", []).forEach((box) => {
                    childrenIDs.push({PlcmntID: box.BID});
                });
            } else {
                childrenIDs.push({PlcmntID: location.BID});
            }
        }
        return childrenIDs;
    } catch (e) {
        console.error(e);
        return [];
    }
};
